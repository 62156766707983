/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface PatientHoldingViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string;
  guarantorHoldingId?: string;
  accountNumber?: number;
  externalAccountNumber?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  suffix?: string | null;
  birthDate?: string | null;
  sex?: string | null;
  ssn?: string | null;
  primaryProviderId?: string | null;
  primaryProviderLinkId?: string | null;
  attendingProviderId?: string | null;
  attendingProviderLinkId?: string | null;
  referringProviderId?: string | null;
  referringProviderLinkId?: string | null;
  placeOfServiceCodeId?: string | null;
  locationId?: string | null;
  locationLinkId?: string | null;
  medicalRecordNumber?: string | null;
  defaultAdmissionDate?: string | null;
  defaultDischargeDate?: string | null;
  deathDate?: string | null;
  defaultInjuryDate?: string | null;
  defaultInjuryEmploymentCode?: string | null;
  defaultAccidentType?: string | null;
  defaultAutoAccidentState?: string | null;
  printFirstReport?: boolean;
  militaryBranch?: string | null;
  militaryDutyStatus?: string | null;
  maritalStatus?: string | null;
  employmentStudentStatus?: string | null;
  primaryDiagnosisCodeId?: string | null;
  secondaryDiagnosisCodeId?: string | null;
  tertiaryDiagnosisCodeId?: string | null;
  quarternaryDiagnosisCodeId?: string | null;
  primaryInsuranceHoldingId?: string | null;
  secondaryInsuranceHoldingId?: string | null;
  tertiaryInsuranceHoldingId?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  homePhone?: string | null;
  workPhone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  useAlternateMailingAddress?: boolean;
  alternateName?: string | null;
  alternateAddress1?: string | null;
  alternateAddress2?: string | null;
  alternateCity?: string | null;
  alternateStateCode?: string | null;
  alternateZip?: string | null;
  alternateCountryCode?: string | null;
  employerName?: string | null;
  employerAddress1?: string | null;
  employerAddress2?: string | null;
  employerCity?: string | null;
  employerStateCode?: string | null;
  employerZip?: string | null;
  employerCountryCode?: string | null;
  employerPhone?: string | null;
  issues?: Array<HoldingIssue> | null;
}
