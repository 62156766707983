import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent,
  FilterType
} from 'components';
import { map, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { FeeSchedulesDialogComponent } from './fee-schedules-dialog/fee-schedules-dialog.component';
import { FeeSchedulesService } from './fee-schedules.service';
import { FeeScheduleWrapperService } from 'src/app/core/services/service-wrappers/fee-schedule-wrapper.service';
import { compare } from 'fast-json-patch/module/duplex';
import { NotificationService } from 'src/app/core/services/notification.service';
import { UploadComponent } from './upload/upload.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';

@Component({
  selector: 'app-fee-schedules',
  templateUrl: './fee-schedules.component.html',
  styleUrls: ['./fee-schedules.component.scss'],
  providers: [FeeSchedulesService]
})
export class FeeSchedulesComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  private dateFormat = new Intl.DateTimeFormat('en-us');
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      headerText: '',
      displayOrder: 0,
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.fixedTiny,
      bodyClasses: [BodyClasses.alignRight],
      overflowMenuOptions: [
        {
          id: 'edit',
          label: 'Edit'
        },
        {
          id: 'delete',
          label: 'Delete'
        },
      ],
      overflowMenuClicked: (value, data) => {
        if (value === 'edit') {
          this.openFeeScheduleModal(data.id);
        } else if (value === 'delete') {
          this.deleteEntry(data);
        } 
      }
    },
    {
      id: 'name',
      displayKey: 'name',
      headerText: 'Fee Schedule Name',
      displayOrder: 1,
      columnSize: ColumnSizes.normal,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'effectiveFrom',
      displayKey: 'effectiveFrom',
      headerText: 'Effective From',
      displayOrder: 2,
      columnSize: ColumnSizes.date,
      sortable: true,
      filterable: true,
      filterConfig: {
        filterType: FilterType.date,
        filterKey: 'effectiveFrom'
      },
      formatter: (effectiveDate, feeSchedule) => {
        return `${this.dateFormat.format(feeSchedule.effectiveFrom)} `;
      },
    },
    {
      id: 'effectiveTo',
      displayKey: 'effectiveTo',
      headerText: 'Effective To',
      displayOrder: 3,
      columnSize: ColumnSizes.date,
      sortable: true,
      filterable: true,
      filterConfig: {
        filterType: FilterType.date,
        filterKey: 'effectiveTo'
      },
      formatter: (effectiveDate, feeSchedule) => {
        return `${this.dateFormat.format(feeSchedule.effectiveTo)}`;
      },
    },
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: FeeSchedulesService;
  constructor(
    private feeSchedulesService: FeeSchedulesService,
    public dialog: MatDialog,
    private service: FeeScheduleWrapperService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>
  ) {
    this.dataSource = feeSchedulesService;
  }

  addFeeSchedule() {
    this.openFeeScheduleModal(null);
  }

  uploadFeeSchedule() {
    this.openFeeScheduleUploadModal();
  }

  openFeeScheduleModal(feeScheduleId: string) {
    const dialog = this.dialog.open(FeeSchedulesDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { feeScheduleId },
      height: '1000px',
      width:'1500px'
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  openFeeScheduleUploadModal() {
    const dialog = this.dialog.open(UploadComponent, {
      disableClose: true,
      autoFocus: false,
      height: '600px',
      width:'600px'
    });

    dialog.afterClosed().subscribe((result) => {
        this.grid.loadData();
    });
  }

  /*duplicateEntry(data) {
    const modalContent: DialogContent = {
      header: 'Duplicate Fee Schedule',
      body: `Are you want to duplicate "${data.name}"?`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Duplicate'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.service
          .apiV1FeeScheduleAddPost(data)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.grid.loadData();
          });
      }
    });
  }*/

  deleteEntry(data) {
    const modalContent: DialogContent = {
      header: 'Delete Fee Schedule',
      body: `Are you want to delete "${data.name}"? This cannot be undone.`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.service
          .apiV1FeeScheduleIdDelete(data.id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.grid.loadData();
            this.notificationService.notifyNow("Fee Schedule Deleted");
          });
      }
    });
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private itemCountSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;  
  private STEP_CODE = 'fee-schedules'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.itemCountSub = this.feeSchedulesService.itemCountSubject.subscribe((c) => this.itemCount = c);
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }
  
  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.itemCountSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => { 
          this.gonext = false;  
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnInit(): void {
    this.implementationSubscribe();
  }
  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }
}
