import { Injectable } from '@angular/core';
import { BaseGridSource, PagedModel } from 'components';
import { of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { ProvidersWrapperService } from 'src/app/core/services/service-wrappers/providers-wrapper.service';

@Injectable()
export class ProvidersService extends BaseGridSource<any> {
  constructor(private service: ProvidersWrapperService) {
    super();
  }
  load(pagedModel: PagedModel) {
    this.loadingSubject.next(true);

    this.service
      .apiV1ProviderAllPost(pagedModel)
      .pipe(
        map((response) => {
          this.itemCountSubject.next(response.metaData.totalItemCount);

          const name = response.data.map((provider) => {
            return {
              ...provider,
              firstName: provider.firstName,
              lastName: provider.lastName
            };
          });
          return { ...response, name };
        }),
        catchError(() => of({ data: [] })),
        finalize(() => {
          this.loadingSubject.next(false);
        }),
        take(1)
      )
      .subscribe((data) => this.dataSourceSubject.next(data.data));
  }
}
