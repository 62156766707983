import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    FeeScheduleDetailsViewModel,
    FeeScheduleEntryService,
  FeeScheduleEntryViewModel,
  FeeScheduleService,
  FeeScheduleViewModel,
  FeeScheduleViewModelPagedModelResponse,
  PagedModelRequest,
  PopulateFeeScheduleViewModel
} from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FeeScheduleWrapperService extends BaseWrapperService {
  constructor(private feeService: FeeScheduleService,
    private feeEntryService: FeeScheduleEntryService,
    private store: Store<any>) {
    super(store);
  }

  apiV1FeeScheduleAllPost(pagedModelRequest?: PagedModelRequest): Observable<FeeScheduleViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.feeService.apiV1FeeScheduleAllPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1FeeScheduleAllDetailPost(pagedModelRequest?: PagedModelRequest): Observable<FeeScheduleViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.feeService.apiV1FeeScheduleAllDetailPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1FeeScheduleIdGet(id: string): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeService.apiV1FeeScheduleIdGet(id, dataSetId)));
  }
  apiV1FeeScheduleDetailsIdGet(id: string): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeService.apiV1FeeScheduleDetailsIdGet(id, dataSetId)));
  }

  apiV1FeeScheduleAddPost(requestBody): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeService.apiV1FeeScheduleAddPost(dataSetId, requestBody)));
  }

  apiV1FeeSchedulePatchIdPatch(id: string, operation): Observable<FeeScheduleDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.feeService.apiV1FeeSchedulePatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1FeeScheduleIdDelete(id: string): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeService.apiV1FeeScheduleIdDelete(id, dataSetId)));
  }

  apiV1FeeScheduleEntryAddPost(requestBody): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeEntryService.apiV1FeeScheduleEntryAddPost(dataSetId, requestBody)));
  }

  apiV1FeeScheduleEntryPatchIdPatch(id: string, operation): Observable<FeeScheduleEntryViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.feeEntryService.apiV1FeeScheduleEntryPatchIdPatch(id, dataSetId, operation))
    );
  }
  apiV1FeeScheduleEntryDetailsIdGet(id: string): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeEntryService.apiV1FeeScheduleEntryDetailsIdGet(id, dataSetId)));
  }


  apiV1FeeSchedulePopulatePost(populateFeeSchedule: PopulateFeeScheduleViewModel): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeService.apiV1FeeSchedulePopulatePost(dataSetId, populateFeeSchedule)));
  }

  apiV1FeeScheduleEntryIdDelete(id: string): Observable<FeeScheduleViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.feeEntryService.apiV1FeeScheduleEntryIdDelete(id, dataSetId)));
  }
}
