<h3>Fee Schedule Entry</h3>
<lib-form-group [formGroup]="entryForm" class="entry" stackedTitle="true" hideTitle="true">
  <lib-form-field [parent]="entryForm" label="Name" name="name" type="text" class="form-span-12"> </lib-form-field>
  <lib-form-field
    [parent]="entryForm"
    label="Procedure"
    name="procedureId"
    type="autocomplete"
    [apiService]="procedureLookup"
    class="form-span-12"
  >
  </lib-form-field>
  <lib-form-field
    [parent]="entryForm"
    label="Modifier"
    name="modifierId"
    type="autocomplete"
    [apiService]="modifierLookup"
    class="form-span-6"
  >
  </lib-form-field>
  <lib-form-field [parent]="entryForm" label="Amount" name="amount" type="number" class="form-span-6"> </lib-form-field>
</lib-form-group>
<div class="button-div">
  <button class="cancel" mat-stroked-button (click)="cancel()">Cancel</button>

  <button class="save" mat-flat-button color="primary" (click)="save()" [disabled]="entryForm.invalid">Save</button>
</div>
