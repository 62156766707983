import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AddReminderViewModel,
  PagedModelRequest,
  ReminderDetailsViewModelPagedModelResponse,
  ReminderService
} from 'data';
import { BaseWrapperService } from './base-wrapper.service';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReminderWrapperService extends BaseWrapperService {
  constructor(private service: ReminderService, private store: Store<any>) {
    super(store);
  }
  apiV1ReminderAllDetailPost(
    pagedModelRequest: PagedModelRequest
  ): Observable<ReminderDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1ReminderAllDetailPost(dataSetId, pagedModelRequest);
      })
    );
  }
  apiV1ReminderAllPost(pagedModelRequest: PagedModelRequest) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1ReminderAllPost(dataSetId, pagedModelRequest);
      })
    );
  }

  apiV1ReminderAddPost(addNoteViewModel: AddReminderViewModel) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1ReminderAddPost(dataSetId, addNoteViewModel);
      })
    );
  }

  apiV1ReminderIdDelete(id: string) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1ReminderIdDelete(id, dataSetId);
      })
    );
  }
}
