import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcedureTransactionDetailsViewModel } from 'data';

@Component({
  selector: 'lib-procedure-details',
  templateUrl: './procedure-details.component.html',
  styleUrls: ['./procedure-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcedureDetailsComponent implements OnInit {
  @Input()
  procedure: ProcedureTransactionDetailsViewModel;

  @Input()
  procedureNumber: number;

  @Input()
  diagnoses: any[];


  @Output() editProcedure: EventEmitter<string> = new EventEmitter<string>();
  @Output() reverseProcedure: EventEmitter<string> = new EventEmitter<string>();
  @Output() billProcedureChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {

  }

  billProcedureChanged($event) {
    this.billProcedureChange.emit({ procedureNumber: this.procedureNumber, bill: $event.checked});
  }

  formatDrugCode(code: string) {
    if (code.length == 11) {
      return code.substring(0, 5)+'-'+code.substring(5, 9)+'-' + code.substring(9);
    }
    else if (code.length == 10) {
      return code.substring(0, 5)+'-0'+code.substring(5, 8)+'-' + code.substring(8);
    }
    else {
      return code;
    }
  }

}
