import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProvidersComponent } from './providers.component';
import { ProvidersClassRoutingModule } from './providers-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { GridModule, SharedModule } from 'components';
import { ProvidersDialogModule } from './providers-dialog/providers-dialog.module';

@NgModule({
  declarations: [ProvidersComponent],
  imports: [
    CommonModule,
    ProvidersClassRoutingModule,
    MatButtonModule,
    MatIconModule,
    GridModule,
    SharedModule,
    ProvidersDialogModule
  ]
})
export class ProvidersModule {}
