import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BodyClasses,
  ColumnDefinition,
  ColumnDisplayType,
  ColumnSizes,
  GridComponent,
  GridConfiguration,
  OrderByPipe,
  DialogContent
} from 'components';
import { map, take } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog.service';
import { ProvidersDialogComponent } from './providers-dialog/providers-dialog.component';
import { ProvidersService } from './providers.service';
import { ProvidersWrapperService } from 'src/app/core/services/service-wrappers/providers-wrapper.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { compare } from 'fast-json-patch';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { selectImplementation } from '../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../implementation/state/implementation.actions';
import { ImplementationDetailViewModel } from 'projects/data/src/lib/model/implementationDetailViewModel';
import { ImplementationViewModel } from 'projects/data/src/lib/model/implementationViewModel';
import { UploadCodesComponent } from '../upload-codes/upload-codes.component';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.scss'],
  providers: [ProvidersService]
})
export class ProvidersComponent implements OnInit, OnDestroy {
  @ViewChild(GridComponent) grid: GridComponent;
  orderPipe: OrderByPipe = new OrderByPipe();
  private columnDefinitions: ColumnDefinition[] = [
    {
      id: 'actions',
      displayKey: 'actions',
      displayType: ColumnDisplayType.actionList,
      columnSize: ColumnSizes.tiny,
      bodyClasses: [BodyClasses.alignRight],
      displayOrder: 0,
      headerText: '',
      overflowMenuOptions: [
        {
          id: 'edit',
          label: 'Edit'
        },
        {
          id: 'activate',
          label: 'Activate'
        },
        {
          id: 'inactivate',
          label: 'Inactivate'
        }
      ],
      overflowMenuClicked: (value, data) => {
        if (value === 'edit') {
          this.openProviderModal(data.id);
        } else {
          this.setInactive(data, (value === 'inactivate'));
        };
      }
    },
    {
      id: 'providerNumber',
      displayKey: 'providerNumber',
      headerText: 'Provider Number',
      displayOrder: 1,
      columnSize: ColumnSizes.midwidth,
      filterable: true,
      sortable: true,
      sticky: true
    },
    {
      id: 'lastName',
      displayKey: 'lastName',
      headerText: 'Last Name',
      displayOrder: 1,
      columnSize: ColumnSizes.normal,
      sortable: true,
      filterable: true
    },
    {
      id: 'firstName',
      displayKey: 'firstName',
      headerText: 'First Name',
      displayOrder: 2,
      columnSize: ColumnSizes.normal,
      sortable: true,
      filterable: true
    },
    {
      id: 'title',
      displayKey: 'title',
      headerText: 'Provider Title',
      displayOrder: 3,
      columnSize: ColumnSizes.narrow,
      bodyClasses: [BodyClasses.alignLeft],
      sortable: true,
      filterable: true
    },
    {
      id: 'npi',
      displayKey: 'npi',
      headerText: 'NPI',
      displayOrder: 4,
      columnSize: ColumnSizes.normal,
      sortable: true,
      filterable: true
    }
  ];
  gridConfig: GridConfiguration = {
    columnDefinitions: this.columnDefinitions,
    displayColumns: this.orderPipe.transform(this.columnDefinitions, 'displayOrder').map((x) => x.id)
  };

  dataSource: ProvidersService;
  constructor(
    private providersService: ProvidersService,
    public dialog: MatDialog,
    private wrapperService: ProvidersWrapperService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>
  ) {
    this.dataSource = providersService;
  }

  addProvider() {
    this.openProviderModal(null);
  }

  openProviderModal(providerId: string) {
    const dialog = this.dialog.open(ProvidersDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { providerId }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.grid.loadData();
      }
    });
  }

  deleteEntry(data) {
    const modalContent: DialogContent = {
      header: 'Delete Provider',
      body: `Are you sure you want to delete "${data.lastName}, ${data.firstName}"? This cannot be undone.`,
      cancelButtonText: 'Cancel',
      OKButtonText: 'Delete'
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        this.wrapperService
          .apiV1ProviderIdDelete(data.id)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.grid.loadData();
            this.notificationService.notifyNow("Provider Deleted");
          });
      }
    });
  }

  setInactive(data, setInactive = true) {

    const sTerminology: string = (setInactive ? 'Inactivate' : 'Activate');
    var sProviderName: string = ""

    if (data !== null) {
      sProviderName = `"${data?.lastName}, ${data?.firstName}"`
    }

    const modalContent: DialogContent = {
      header: sTerminology + ' Provider',
      body: `Are you sure you want to ` + sTerminology + ` ` + sProviderName + `?`,
      cancelButtonText: 'Cancel',
      OKButtonText: sTerminology
    };
    this.dialogService.showConfirm(modalContent).subscribe((result) => {
      if (result) {
        if (data.inactive && setInactive || data.inactive === false && setInactive === false) {
          this.notificationService.notifyNow(sProviderName + " is already " + sTerminology + "d");
        }
        else {
          const formData = {
            inactive: setInactive
          }
          this.wrapperService
            .apiV1ProviderPatchIdPatch(data.id, compare({}, formData))
            .pipe(
              map((x: any) => x),
              take(1)
            )
            .subscribe((x) => {
              this.grid.loadData();
            });
        }
      }
    });
  }
  uploadCodes() {
    const dialog = this.dialog.open(UploadCodesComponent, {
      disableClose: true,
      autoFocus: false,
      height: '600px',
      width:'600px',
      data: {code: 'provider'}
    });

    dialog.afterClosed().subscribe((result) => {
        this.grid.loadData();
    });
  }
  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private itemCountSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;
  private STEP_CODE = 'providers'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data?.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.itemCountSub = this.providersService.itemCountSubject?.subscribe((c) => this.itemCount = c);
        this.implementationSub =
          this.implementation$?.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }

  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.itemCountSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => {
          this.gonext = false;
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnInit(): void {
    this.implementationSubscribe();
  }
  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }
}
