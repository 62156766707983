import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
    InsuranceCarrierDetailsViewModel,
  InsuranceCarrierDetailsViewModelPagedModelResponse,
  InsuranceCarrierService,
  InsuranceCarrierViewModel,
  InsuranceViewModel,
  InsuranceViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCarrierWrapperService extends BaseWrapperService {
  constructor(private service: InsuranceCarrierService, private store: Store<any>) {
    super(store);
  }

  apiV1InsuranceCarrierAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<InsuranceCarrierDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierAllPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1InsuranceCarrierAllDetailPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<InsuranceCarrierDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierAllDetailPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1InsuranceCarrierIdGet(id: string): Observable<InsuranceCarrierDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1InsuranceCarrierIdGet(id, dataSetId)));
  }

  apiV1InsuranceCarrierAddPost(requestBody): Observable<InsuranceViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierAddPost(dataSetId, requestBody))
    );
  }

  apiV1InsuranceCarrierPatchIdPatch(id: string, operation): Observable<InsuranceViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierPatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1InsuranceCarrierIdDelete(id: string): Observable<InsuranceViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1InsuranceCarrierIdDelete(id, dataSetId)));
  }

  apiV1InsuranceCarrierValidateCarrierCodePost(carrierCode: string): Observable<InsuranceCarrierViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1InsuranceCarrierValidateCarrierCodePost(dataSetId, carrierCode)));
  }
}
