import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as ImplementationActions from '../state/implementation.actions';
import { ImplementationState } from '../state/implementation.state';
import { ImplementationViewModel } from 'data';
import { Store } from '@ngrx/store';
import { DataSetState } from '@core/state/data-set/data-set.state';
import * as DataSetActions from '@core/state/data-set/data-set.actions';

@Injectable({
  providedIn: 'root'
})
export class NotImplementationGuard  {
  constructor(private service: ImplementationWrapperService, private router: Router, private store: Store<ImplementationState>, private datasetStore: Store<DataSetState>) 
  {
  }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {
      const urlTree : UrlTree = this.router.parseUrl('/dashboard');
      
      return this.service.apiV1ImplementationGet().pipe(
        map(i =>
        {
          this.store.dispatch(ImplementationActions.loadImplementationSuccess({ implementation: i }));
          this.store.dispatch(DataSetActions.selectDataSet({dataSetId: i.datasetId}));
          if (i.status != null && i.status !== "Complete") return true; 
          else { return urlTree; } 
        }));
  }
  
}
