import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';

export class BaseWrapperService {
  constructor(private baseStore: Store<any>) {}

  dataSetId$ = this.baseStore.select(getSelectedDataSetId).pipe(filter((cId) => !!cId ));
}
