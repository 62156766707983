<div class="frequency-dialog">
  <form class="procedure-data" [formGroup]="formGroup">
    <input type="hidden" formControlName="frequencyDate" />
    <mat-calendar
      #frequencyCalendar
      (selectedChange)="setDate($event)"
      [minDate]="minDate"
      [selected]="formGroup.get('frequencyDate').value"
    ></mat-calendar>
    <mat-divider></mat-divider>
    <div class="frequency-dialog__time-repeat">
      <div class="frequency-dialog__time-select">
        <ngx-material-timepicker-toggle [for]="toggleIcon" class="frequency-dialog__time-icon">
          <mat-icon matSuffix color="primary" ngxMaterialTimepickerToggleIcon>schedule</mat-icon>
        </ngx-material-timepicker-toggle>
        <input
          [ngxTimepicker]="toggleIcon"
          [disableClick]="true"
          matInput
          formControlName="frequencyTime"
          readonly
          placeholder="Time"
        />
        <ngx-material-timepicker #toggleIcon [timepickerClass]="'light-theme'"></ngx-material-timepicker>
      </div>
      <input type="hidden" formControlName="doesRepeat" />
      <mat-button-toggle
        #toggle
        (change)="repeatChange($event, toggle)"
        [checked]="formGroup.get('doesRepeat').value === true"
        class="mat-button-toggle--primary-alt"
      >
        <mat-icon>repeat</mat-icon>

        Repeat
      </mat-button-toggle>
    </div>

    <ng-container *ngIf="formGroup.get('doesRepeat').value === true">
      <div class="frequency-dialog__repeat">
        <div class="frequency-dialog__repeat-desc">Every</div>
        <mat-form-field appearance="outline" class="frequency-dialog__repeat-interval">
          <input matInput type="text" formControlName="frequencyInterval" />
        </mat-form-field>
        <mat-form-field appearance="outline" class="frequency-dialog__repeat-type">
          <mat-select formControlName="frequencyType" (selectionChange)="frequencyChange($event)">
            <mat-option [value]="Hourly">Hour</mat-option>
            <mat-option [value]="Daily">Day</mat-option>
            <mat-option [value]="Weekly">Week</mat-option>
            <mat-option [value]="Monthly">Month</mat-option>
            <mat-option [value]="Yearly">Year</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container [ngSwitch]="formGroup.get('frequencyType').value">
        <ng-container *ngSwitchCase="'Weekly'">
          <mat-button-toggle-group
            multiple="true"
            formControlName="weekDays"
            class="mat-button-toggle--primary mat-button-toggle--spaced frequency-dialog__weekly"
          >
            <mat-button-toggle value="Monday"> M </mat-button-toggle>
            <mat-button-toggle value="Tuesday"> T </mat-button-toggle>
            <mat-button-toggle value="Wednesday"> W </mat-button-toggle>
            <mat-button-toggle value="Thursday"> T </mat-button-toggle>
            <mat-button-toggle value="Friday"> F </mat-button-toggle>
            <mat-button-toggle value="Saturday"> S </mat-button-toggle>
            <mat-button-toggle value="Sunday"> S </mat-button-toggle>
          </mat-button-toggle-group>
        </ng-container>
        <ng-container *ngSwitchCase="'Monthly'">
          <mat-form-field appearance="outline" class="frequency-dialog__monthly">
            <mat-select formControlName="monthDays" multiple>
              <mat-option *ngFor="let item of [].constructor(31); let i = index; trackByIndex" value="{{ i + 1 }}">{{
                i + 1
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </ng-container>
      </ng-container>
    </ng-container>
  </form>
  <mat-divider></mat-divider>
  <div class="frequency-dialog__controls">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="formGroup.invalid" (click)="save()">Save</button>
  </div>
</div>
