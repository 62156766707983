import { Pipe, PipeTransform } from '@angular/core';
import { ProcedureTransactionDetailsViewModel } from 'data';

@Pipe({
  name: 'procedureDescription'
})
export class ProcedureDescriptionPipe implements PipeTransform {
  transform(value: ProcedureTransactionDetailsViewModel): string {
    const displayValue = [];
    if (value?.procedure?.code) {
      displayValue.push(value?.procedure?.code);
    }

    if (value?.procedure?.description) {
      displayValue.push(value?.procedure?.description);
    }

    return displayValue.join(', ');
  }
}
