import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../shared/modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GroupComponent } from './group/group.component';
import { FieldComponent } from './field/field.component';
import * as CustomValidators from './utils/validators';
import { SteppedFormComponent } from './stepped-form/stepped-form.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { FieldErrorsDefinition } from './models/field-errors-definition';
import { FieldSelectOption } from './models/field-select-option';
import { SteppedFormGroupDefinition } from './models/stepped-form-group-definition';
import { DirectivesModule } from '../../shared/directives';
import { FormContainerComponent } from './form-container/form-container.component';
import { CollapsibleFormComponent } from './collapsible-form/collapsible-form.component';
import { CollapsibleFormGroupComponent } from './collapsible-form-group/collapsible-form-group.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

const components = [
  GroupComponent,
  FieldComponent,
  SteppedFormComponent,
  DynamicFormComponent,
  FormContainerComponent,
  CollapsibleFormComponent,
  CollapsibleFormGroupComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    MatIconModule,
    MatButtonModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatInputModule,
  ]
})
export class FormModule {}

export { FieldErrorsDefinition, FieldSelectOption, CustomValidators, SteppedFormGroupDefinition };
