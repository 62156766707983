<div class="dialog-form report-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Provider</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions"> </lib-dynamic-form>
      </ng-container>

      <h3>Link Provider to Locations</h3>

      <div class="addLoc">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!--- Note that these columns can be defined in any order.
      The actual rendered columns are set as a property on the row definition" -->
          <!-- Code Column -->
          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Code</th>
            <td mat-cell *matCellDef="let element">{{ element.code }}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let row">
              <button mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="delete(row)">Delete</button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <button *ngIf="existingProvider" (click)="addEntry()" mat-button color="primary">
          <mat-icon>add_circle_outline</mat-icon> Add Location(s)
        </button>
      </div>
    </div>
  </main>
  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading
      *ngIf="!providerLocationSaved && !existingProvider"
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="save()"
      >Continue</lib-button-loading
    >
    <lib-button-loading
      *ngIf="providerLocationSaved || existingProvider"
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="close()"
      >Save
    </lib-button-loading>
  </div>
</div>
