import { Observable } from 'rxjs';
import { FieldSelectOption } from '../models';

export class ApiAutoCompleteService {
  search(value: string): Observable<FieldSelectOption[]> {
    throw new Error('Search method not implemented.');
  }
  load(id: string | number): Observable<FieldSelectOption> {
    throw new Error('Load method not implemented.');
  }

  sortCategories(categories: FieldSelectOption[]): FieldSelectOption[] {
    return categories.sort((a, b) => (a.label < b.label ? -1 : 1));
  }

  filteredSearch(value: string, filterProperty:string, filterObject:any): Observable<FieldSelectOption[]> {
    throw new Error('Load method not implemented.');
  }

}
