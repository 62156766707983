<div class="dialog-form report-form container">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>HCFA Template</h2>
  </header>

  <div class="body">
    <div class="background">
      <form [formGroup]="formGroup">
        <!-- insuredName -->
        <select formControlName="x04" class="x04">
          <option value=""></option>
          <option value="Patient Name">Patient Name</option>
          <option value="Insured Name">Insured Name</option>
          <option value="Resp. Party Name">Responsible Name</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- insuredAddress -->
        <select formControlName="x07" class="x07">
          <option value=""></option>
          <option value="Patient Address">Patient Address</option>
          <option value="Insured Address">Insured Address</option>
          <option value="Employer Address">Employer Address</option>
          <option value="Suppress Value">Suppress Value</option>
        </select>
        <!-- insuredPolicyNumber -->
        <select formControlName="x11" class="x11" placeholder="Print None">
          <option value=""></option>
          <option value="NONE">Print NONE</option>
          <option value="Policy Num.">Policy Number</option>
          <option value="Group Num.">Group Number</option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- insuredDOB -->
        <select formControlName="x11a" class="x11a">
          <option value=""></option>
          <option value="Insured Date Of Birth">Current Insured DOB</option>
          <option value="Primary Insured Date Of Birth">Primary Insured DOB</option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- otherClaimId -->
        <select formControlName="x11b1" class="x11b1">
          <option value=""></option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- customClaimId -->
        <input formControlName="x11b2" class="x11b2" type="text" />
        <!-- currentInsurancePlan -->
        <select formControlName="x11c" class="x11c">
          <option value=""></option>
          <option value="Insurance Plan Name">Current Insurance Plan Name</option>
          <option value="Primary Ins. Plan Name">Plan Name From Primary Insurance</option>
          <option value="Carrier Code">Carrier Code</option>
          <option value="Custom Text">Custom Text (Rules)</option>
          <option value="Suppress Value">Suppress Value</option>
          "
        </select>
        <!-- insuredPlanName -->
        <select formControlName="x09d" class="x09d">
          <option value=""></option>
          <option value="Insurance Plan Name">Insurance Plan Name</option>
          <option value="Carrier Code">Carrier Code</option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- claimCode -->
        <select formControlName="x10d" class="x10d">
          <option value=""></option>
          <option value="AA">AA</option>
          <option value="AB">AB</option>
          <option value="AC">AC</option>
          <option value="AD">AD</option>
          <option value="AE">AE</option>
          <option value="AF">AF</option>
          <option value="AG">AG</option>
          <option value="AH">AH</option>
          <option value="AI">AI</option>
          <!--<option value="SterilizationW2">Sterilization W2</option>
          <option value="WorkersCompW3Level1">Workers Comp W3 - Level 1 Appeal</option>
          <option value="WorkersCompW3Level2">Workers Comp W3 - Level 2 Appeal</option>
          <option value="WorkersCompW4">Workers Comp W4</option>
          <option value="InjuryDate">Injury Date</option>-->
          <option value="MGP">MGP</option>
          <option value="MCD">MCD</option>
          <option value="MSP">MSP</option>
          <option value="ES">ES</option>
          <option value="Custom Text">Custom Text (Rules)</option>
          <!--<option value="MedicaidPolicyNumber">Medicaid Policy Number</option>-->
        </select>
        <!-- patientSignature -->
        <select formControlName="x121" class="x121">
          <option value=""></option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Custom Text">Custom Text (Rules)</option>
          <!--<option value="OnlyOnAccept">Only Print On Accept</option>
          <option value="AssignmentClaims">Assignment Claims</option>-->
        </select>
        <!-- authorizedSignature -->
        <select formControlName="x13" class="x13">
          <option value=""></option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Custom Text">Custom Text (Rules)</option>
          <!--<option value="OnlyOnAccept">Only Print On Accept</option>
          <option value="AssignmentClaims">Assignment Claims</option>-->
        </select>
        <!-- dateOfIllnessCode -->
        <select formControlName="x142" class="x142">
          <option value=""></option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="431">431</option>
          <option value="484">484</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- otherDate -->
        <select formControlName="x151" class="x151">
          <option value=""></option>
          <option value="454">454</option>
          <option value="304">304</option>
          <option value="453">453</option>
          <option value="439">439</option>
          <option value="455">455</option>
          <option value="471">471</option>
          <option value="090">090</option>
          <option value="091">091</option>
          <option value="444">444</option>
        </select>
        <!-- referringProvider -->
        <select formControlName="x17" class="x17">
          <option value=""></option>
          <option value="Referring Provider">Referring</option>
          <option value="Ordering Provider">Ordering</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- npi -->
        <select formControlName="x17a" class="x17a">
          <option value=""></option>
          <!--<option value="Box17">Use Box 17 NPI</option>-->
          <option value="Suppress Value">Suppress NPI</option>
          <option value="Attending NPI">Provider NPI</option>
          <option value="Location NPI">Location NPI</option>
          <option value="Ordering NPI">Ordering NPI</option>
          <option value="Referring NPI">Referring NPI</option>
          <option value="Provider Taxonomy">Print Taxonomy Code</option>
        </select>
        <!-- npiB -->
        <select formControlName="x17b" class="x17b">
          <option value=""></option>
          <option value="Referring NPI">Referring</option>
          <option value="Ordering NPI">Ordering</option>
          <option value="Location NPI">Locations Of Service</option>
        </select>
        <!-- additionalClaimText -->
        <input formControlName="x19" class="x19" type="text" />
        <!-- additionalClaimInfo -->
        <select formControlName="x191" class="x191">
          <option value=""></option>
          <!--<option value="PrintProviderNumber">Print Provider Number</option>-->
          <option value="Custom Text">Custom Text (Rules)</option>
          <option value="Suppress Value">Never Print</option>
        </select>
        <!-- originalRefNo -->
        <select formControlName="x222" class="x222">
          <option value=""></option>
          <option value="Custom Text">Custom Text (Rules)</option>
          <option value="Suppress Value">Never Print</option>
          <!--<option value="currentInsuranceICN">Current Insurance ICN</option>
          <option value="primaryInsuranceICN">Primary Insurance ICN</option>-->
        </select>
        <!-- modifier -->
        <select formControlName="x24e" class="x24e">
          <option value=""></option>
          <option value="Suppress Value">Print Nothing</option>
          <option value="Primary Diagnosis Code">Print The Primary Diagnosis Code</option>
          <option value="Reference Four Diagnosis Codes">
            Print The Reference To Up To Four Diagnosis Codes Listed In Box 21
          </option>
          <option value="Reference To Primary Diagnosis Code">
            Print The Reference To The Primary Diagnosis Code Listed In Box 21
          </option>
          <option value="Provider Taxonomy">Print Taxonomy Code</option>
        </select>
        <!-- idQual -->
        <select formControlName="x24i" class="x24i">
          <option value=""></option>
          <option value="0B">0B</option>
          <option value="1B">1B</option>
          <option value="1C">1C</option>
          <option value="1D">1D</option>
          <option value="1G">1G</option>
          <option value="1H">1H</option>
          <option value="E1">E1</option>
          <option value="G2">G2</option>
          <option value="LU">LU</option>
          <option value="N5">N5</option>
          <option value="SY">SY</option>
          <option value="X5">X5</option>
          <option value="ZZ">ZZ</option>
          <option value="Suppress Value">Suppress Value</option>
        </select>
        <!-- renderingProvider -->
        <select formControlName="x24j1" class="x24j1">
          <option value=""></option>
          <option value="Referring NPI">Referring</option>
          <option value="Ordering NPI">Ordering</option>
          <option value="Attending NPI">Primary Provider</option>
          <option value="Location NPI">Location Of Service</option>
          <option value="Provider Taxonomy">Print Taxonomy Code</option>
          <option value="Suppress Value">Suppress NPI</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- renderingProviderNPI -->
        <select formControlName="x24j2" class="x24j2">
          <option value=""></option>
          <option value="Provider NPI">Use Provider NPI</option>
          <option value="Suppress Value">Suppress NPI</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- federalTaxIdNumber -->
        <select formControlName="x251" class="x251">
          <option value=""></option>
          <option value="Suppress Value">Suppress NPI</option>
          <option value="Practice Tax Id">Practice Tax Id</option>
          <option value="Location Tax Id">Location Tax Id</option>
          <option value="Provider Tax Id">Provider Tax Id</option>
        </select>
        <!-- TODO: Box 29 claim values and rule cards -->
        <!-- amountPaid -->
        <!--<select formControlName="x29" class="x29">
          <option value=""></option>
          <option value="0.00">"0.00" - Boxes 28 and 30 Will Print The Amounts Charged and Due</option>
          <option value="primaryOnly">Primary Insurance Carrier Payments Only</option>
          <option value="patientCoPayOnly">Patient Co-Payments Only</option>
          <option value="bothInsuranceAndPatient">Both Insurance Payments and Patient Co-Payments</option>
          <option value="sumTotalOfCarrierAndAdjustments">
            The Sum Total Of The Insurance Carrier Payments and Adjustments
          </option>
          <option value="sumTotalOfCarrierAdjustmentAndPatient">
            The Sum Total Of The Insurance Carrier Payment, Adjustments, and The Patient Co-Payment
          </option>
          <option value="nothing">Nothing</option>
        </select>-->
        <!-- physicianSignature -->
        <select formControlName="x311" class="x311">
          <option value=""></option>
          <option value="Attending Provider">Attending Provider</option>
          <option value="Location">Location</option>
          <option value="Ordering Provider">Ordering</option>
          <option value="Primary Provider">Primary Provider</option>
          <option value="Print Signature">Print Signature On File</option>
          <option value="Supervising Provider">Supervising Provider</option>
        </select>
        <!-- facilityLocation -->
        <select formControlName="x321" class="x321">
          <option value=""></option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Provider Name and Address">Provider Name and Address</option>
          <option value="Location Name and Address">Location Name and Address</option>
          <option value="Patient Name and Address">Patient Name and Address</option>
          <option value="Mammo Cert Number">Mammo Cert Number</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- facilityLocationA -->
        <select formControlName="x32a" class="x32a">
          <option value=""></option>
          <option value="Suppress Value">Suppress NPI</option>
          <option value="Location NPI">Location NPI</option>
          <option value="Attending NPI">Attending NPI</option>
          <option value="Ordering NPI">Ordering NPI</option>
          <option value="Referring NPI">Referring NPI</option>
          <option value="Location Tax Id">Location Tax Id</option>
          <option value="Provider Tax Id">Provider Tax Id</option>
        </select>
        <!-- facilityLocationA -->
        <select formControlName="x32b" class="x32b">
          <option value=""></option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Clia Number">CLIA Number</option>
          <option value="Mammo Cert Number">Mammo Cert Number</option>
          <option value="Provider Taxonomy">Provider Taxonomy</option>
          <!--<option value="Location Taxonomy">Location Taxonomy</option>-->
        </select>
        <!-- billingProviderName -->
        <select formControlName="x331" class="x331">
          <option value=""></option>
          <option value="Provider Name and Address">Provider Name & Address</option>
          <option value="Referring Name and Address">Referring Name & Address</option>
          <option value="Location Name and Address">Location Name & Address</option>
          <option value="Practice Name and Address">Practice Name & Address</option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Custom Text">Custom Text (Rules)</option>
        </select>
        <!-- billingProviderAddress -->
        <!--<select formControlName="x332" class="x332">
          <option value=""></option>
          <option value="Practice Name and Address">Practice Name & Address</option>
        </select>-->
        <!-- billingProviderA -->
        <select formControlName="x33a" class="x33a">
          <option value=""></option>
          <option value="Suppress Value">Suppress NPI</option>
          <option value="Location NPI">Location NPI</option>
          <option value="Attending NPI">Attending NPI</option>
          <option value="Ordering NPI">Ordering NPI</option>
          <option value="Referring NPI">Referring NPI</option>
        </select>
        <!-- billingProviderB -->
        <select formControlName="x33b" class="x33b">
          <option value=""></option>
          <option value="Suppress Value">Suppress Value</option>
          <option value="Attending Provider">Attending Provider</option>
          <option value="Ordering Provider">Ordering Provider</option>
          <option value="Primary Provider">Primary Provider</option>
          <option value="Location">Location</option>
          <option value="Provider Taxonomy">Taxonomy Code</option>
        </select>
      </form>
    </div>
  </div>
</div>
<div mat-dialog-actions class="actions">
  <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
  <lib-button-loading
    color="primary"
    class="save-button"
    [loading]="saving"
    [invalid]="formGroup.invalid"
    (clickEvent)="save()"
  >
    Save
  </lib-button-loading>
</div>
