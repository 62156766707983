<header class="sub-page-layout__header providers__header">
  <div class="providers__header-text h2 h2--semi-bold">Providers</div>
  <button mat-flat-button color="primary" class="providers__add-item" (click)="uploadCodes()">
    <mat-icon>file_upload</mat-icon> Upload Providers
  </button>
  <button mat-flat-button color="primary" class="providers__add-item" (click)="addProvider()">
    <mat-icon>add_circle_outline</mat-icon> Add Provider
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No Providers Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="providers__footer">
  <lib-button-loading color="primary" class="providers__button" [invalid]="itemCount === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
