import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable } from '@angular/material/table';
import { compare } from 'fast-json-patch';
import { map, take } from 'rxjs/operators';
import { FeeScheduleEntryViewModel } from '../../../../../../../projects/data/src/public-api';
import { DialogService } from '../../../../../core/services/dialog.service';
import { ModifiersLookupService, ProcedureCodesLookupService } from '../../../../../core/services/lookup';
import { NotificationService } from '../../../../../core/services/notification.service';
import { FeeScheduleWrapperService } from '../../../../../core/services/service-wrappers/fee-schedule-wrapper.service';

@Component({
  selector: 'app-fee-schedules-entry-dialog',
  templateUrl: './fee-schedules-entry-dialog.component.html',
  styleUrls: ['./fee-schedules-entry-dialog.component.scss']
})
export class FeeSchedulesEntryDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<FeeScheduleEntryViewModel>;
  @ViewChild('paginator') paginator: MatPaginator;
  entryForm: UntypedFormGroup;
  feeScheduleEntryId;
  feeScheduleId;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<FeeSchedulesEntryDialogComponent>,
    private injector: Injector,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private fb: UntypedFormBuilder,
    public modifierLookup: ModifiersLookupService,
    public procedureLookup: ProcedureCodesLookupService,
    private service: FeeScheduleWrapperService,
    private notification: NotificationService
  ) { this.feeScheduleEntryId = this.data?.feeScheduleEntry; this.feeScheduleId = this.data?.feeSchedule}

  ngOnInit(): void {
    this.entryForm = this.fb.group({
      name: new UntypedFormControl('', Validators.required),
      procedureId: new UntypedFormControl('', Validators.required),
      modifierId: new UntypedFormControl(''),
      amount: new UntypedFormControl('', Validators.required)
    })
    if (this.feeScheduleEntryId !== null && this.feeScheduleEntryId !== undefined) {
      this.service.apiV1FeeScheduleEntryDetailsIdGet(this.feeScheduleEntryId).subscribe((result) => {
        this.entryForm.patchValue(result);
      })

    }
    
  }
  cancel() {
    this.dialogRef.close(false);
  }
  save() {
    const formData = {
      feeScheduleId: this.feeScheduleId,
      name: this.entryForm.get('name').value,
      procedureId: this.entryForm.get('procedureId').value,
      modifierId: this.entryForm.get('modifierId').value,
      amount: this.entryForm.get('amount').value
    }
    if (this.feeScheduleEntryId !== null && this.feeScheduleEntryId !== undefined) {
      this.service.apiV1FeeScheduleEntryPatchIdPatch(this.feeScheduleEntryId, compare({}, formData)).pipe(map((patch) => {
        return patch
      })).pipe(take(1)).subscribe((result) => {
        
        this.notification.success('Saved Fee Schedule Entry');
        this.dialogRef.close(true);
      })
    }
    else {
      this.service.apiV1FeeScheduleEntryAddPost(formData).pipe(map((add) => {
        return add
      })).pipe(take(1)).subscribe((result) => {
        this.notification.success('Saved Fee Schedule Entry');
        this.dialogRef.close(true);
      })
    }
  }
 

}
