import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute,  NavigationEnd, Router } from '@angular/router';
import { BreadcrumbsPath } from 'components';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-auto-breadcrumb',
  templateUrl: './auto-breadcrumb.component.html',
  styleUrls: ['./auto-breadcrumb.component.scss']
})
export class AutoBreadcrumbComponent implements OnInit, OnDestroy {
  protected subscriptions: Subscription[] = [];
  breadcrumbs;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged(),
          map((event) => this.buildBreadCrumb(this.activatedRoute.root))
        )
        .subscribe((x) => {
          this.breadcrumbs = x;
        })
    );
  }
  ngOnInit(): void {}

  breadcrumbClicked(data) {
    let batchNumber = sessionStorage.getItem('batchNumber');
    let accountNumber = sessionStorage.getItem('accountNumber');
    switch(data.id) {
      case 'Transactions': {
        this.router.navigateByUrl("/transactions");
        break;
      }
      case 'Batches': {
        this.router.navigateByUrl("/transactions/batches");
        break;
      }
      case ':batchNumber': {
        this.router.navigateByUrl(`/transactions/batches/${batchNumber}`);
        break;
      }
      case 'Documents': {
        break;
      }
      case 'Payment Entry': {
        this.router.navigateByUrl(`/transactions/batches/${batchNumber}/payment-entry`);
        break;
      }
      case ':accountNumber': {
        this.router.navigateByUrl(`/transactions/batches/${batchNumber}/payment-entry/${accountNumber}`);
        break;
      }
      case 'Reports': {
        this.router.navigateByUrl("/reports");
        break;
      }
      case 'Settings': {
        this.router.navigateByUrl("/settings");
        break;
      }
      case 'General Configurations': 
      case 'Practice Information':
      {
        this.router.navigateByUrl("/settings/general-configurations/practice-information");
        break;
      }
      case 'Datasets': {
        this.router.navigateByUrl("/settings/general-configurations/data-sets");
        break;
      }
      case 'Rules': {
        this.router.navigateByUrl("/settings/general-configurations/rules");
        break;
      }
      case 'HL7 Configurations': {
        this.router.navigateByUrl("");
        break;
      }
      case '/settings/general-configurations/hl7-configuration': {
        this.router.navigateByUrl("");
        break;
      }
      case 'Write Offs': {
        this.router.navigateByUrl("/settings/general-configurations/write-offs");
        break;
      }
      case 'Themes': {
        this.router.navigateByUrl("/settings/general-configurations/themes");
        break;
      }
      case 'Statement Setup': {
        this.router.navigateByUrl("/settings/general-configurations/statement-setup");
        break;
      }
      case 'Archive Accounts': {
        this.router.navigateByUrl("/settings/general-configurations/purge-accounts");
        break;
      }
      case 'Mass Rebill': {
        this.router.navigateByUrl("/settings/general-configurations/mass-rebill");
        break;
      }
      case 'Scheduled Jobs': {
        this.router.navigateByUrl("/settings/scheduled-jobs");
        break;
      }
      case 'User Maintenance & Security':
      case 'Roles': {
        this.router.navigateByUrl("/settings/user-maintenance-and-security/roles");
        break;
      }
      case 'Users': {
        this.router.navigateByUrl("/settings/user-maintenance-and-security/users");
        break;
      }
      case 'HL7 Import File': {
        this.router.navigateByUrl("/settings/hl7-import-form");
        break;
      }
      case ':reportGroup': 
      case ':reportName' :
      case 'Results' :
      {
        break;
      }
      default: {
        this.router.navigateByUrl('/');
        break;
      }
    }
  }

  buildBreadCrumb(route: ActivatedRoute, breadcrumbs: Array<BreadcrumbsPath> = []): Array<BreadcrumbsPath> {
    const label = route.routeConfig?.data?.breadcrumb;
    const multiLabel = route.routeConfig?.data?.breadcrumbs;

    if (multiLabel) {
      const newBreadCrumbs = [];
      multiLabel.forEach((x) => {
        const breadcrumb: BreadcrumbsPath = {
          id:x,
          param:x,
          title: this.getBreadCrumbText(x, route)
        };

        newBreadCrumbs.push(breadcrumb);
      });
      const allBreadCrumbs = [...breadcrumbs, ...newBreadCrumbs];

      if (route.firstChild) {
        return this.buildBreadCrumb(route.firstChild, allBreadCrumbs);
      }

      return allBreadCrumbs;
    }

    if (label) {
      const breadcrumb: BreadcrumbsPath = {
        id: label,
        title: this.getBreadCrumbText(label, route)
      };
      const newBreadcrumbs = [...breadcrumbs, breadcrumb];

      if (route.firstChild) {
        return this.buildBreadCrumb(route.firstChild, newBreadcrumbs);
      }
      return newBreadcrumbs;
    }

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, breadcrumbs);
    }
    return breadcrumbs;
  }

  getBreadCrumbText(value: string, route: ActivatedRoute) {
    if (value.indexOf(':') === 0) {
      const newValue = value.replace(':', '');
      return route.snapshot.params[newValue];
    }
    return value;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
