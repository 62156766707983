/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddFinancialClassViewModel {
  datasetId?: string;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  sendStatement?: boolean;
  statementMinimum?: number;
  statementCycle?: number;
  workersComp?: boolean;
  dlqDays?: number;
  paymentCodeId?: string | null;
  adjustmentCodeId?: string | null;
  interestCodeId?: string | null;
  holdBilling?: boolean;
  inactive?: boolean;
}
