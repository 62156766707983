<div class="dialog-form report-form">
  <div class="dialog-form__close">
    <a mat-dialog-close>
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <header mat-dialog-title class="dialog-form__title">
    <h2>Insurance Carrier</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form
          [dynamicFormGroup]="formGroup"
          [formGroupDefinitions]="formDefinitions"
          class="insurance-carriers-dialog"
        >
        </lib-dynamic-form>
        <a mat-flat-button color="primary" class="subOption" (click)="addHCFA()"> HCFA</a>
        <a mat-flat-button color="primary" class="subOption" (click)="addEClaim()"> ANSI</a>
        <a mat-flat-button color="primary" class="subOption" (click)="addCrosswalk()"> Place of Service Crosswalk</a>
      </ng-container>
    </div>
  </main>

  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button mat-dialog-close>Cancel</button>
    <lib-button-loading
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="preCheck()"
      >Save
    </lib-button-loading>
  </div>
</div>
