import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CodeService, CodeViewModel, CodeViewModelPagedModelResponse, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';
@Injectable({
  providedIn: 'root'
})
export class CodeWrapperService extends BaseWrapperService {
  constructor(private codeService: CodeService, private store: Store<any>) {
    super(store);
  }

  apiV1CodeCodetypePost(
    codetype: string,
    pagedModelRequest?: PagedModelRequest
  ): Observable<CodeViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.codeService.apiV1CodeCodetypePost(codetype, dataSetId, pagedModelRequest))
    );
  }

  apiV1CodeCodetypeIdGet(codetype: string, id: string): Observable<CodeViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.codeService.apiV1CodeCodetypeIdGet(codetype, id, dataSetId))
    );
  }
  apiV1CodeCodetypeAddPost(codetype: string, requestBody): Observable<CodeViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.codeService.apiV1CodeCodetypeAddPost(codetype, dataSetId, requestBody))
    );
  }
  apiV1CodeCodetypePatchIdPatch(codetype: string, id: string, operation): Observable<CodeViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.codeService.apiV1CodeCodetypePatchIdPatch(codetype, id, dataSetId, operation))
    );
  }
  apiV1CodeCodetypeDeleteIdDelete(codetype: string, id: string): Observable<CodeViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.codeService.apiV1CodeCodetypeDeleteIdDelete(codetype, id, dataSetId))
    );
  }
  apiV1CodeCodetypeGet(codetype: string): Observable<CodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.codeService.apiV1CodeCodetypeGet(codetype, dataSetId)));
  }
}
