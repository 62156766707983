export interface FieldSelectOption {
  value: string | number| boolean ;
  label: string;
  policy?: boolean;
  group?: boolean;
  toDate?: Date;
  fromDate?: Date;
  code?: string;
  inactive?: boolean;
}
