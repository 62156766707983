import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EOBCodeService, EOBCodeViewModel, EOBCodeViewModelPagedModelResponse, EobHistoryViewModel, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class EobWrapperService extends BaseWrapperService {
  constructor(private service: EOBCodeService, private store: Store<any>) {
    super(store);
  }

  apiV1EobcodeAllPost(pagedModelRequest?: PagedModelRequest): Observable<EOBCodeViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1EobcodeAllPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1EobcodeIdGet(id: string): Observable<EOBCodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1EobcodeIdGet(id, dataSetId)));
  }

  apiV1EobCodeAddPost(requestBody): Observable<EOBCodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1EobcodeAddPost(dataSetId, requestBody)));
  }

  apiV1EobCodePatchIdPath(id: string, operation): Observable<EOBCodeViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1EobcodePatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1EobCodeIdDelete(id: string): Observable<EOBCodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1EobcodeIdDelete(id, dataSetId)));
  }

  apiV1EobcodeHistoryIdGet(id: string): Observable<Array<EobHistoryViewModel>> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1EobcodeHistoryIdGet(id, dataSetId)));
  }
}
