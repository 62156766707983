/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NdcUnit } from './ndcUnit';
import { ProcedureDiagnosisCodeHoldingViewModel } from './procedureDiagnosisCodeHoldingViewModel';

export interface AddProcedureTransactionHoldingViewModel {
  datasetId?: string;
  sendingFacilityId?: string;
  patientHoldingId?: string | null;
  patientId?: string | null;
  visitId?: string | null;
  visitHoldingId?: string | null;
  procedureId?: string | null;
  procedureLinkId?: string | null;
  attendingProviderId?: string | null;
  attendingProviderLinkId?: string | null;
  referringProviderId?: string | null;
  referringProviderLinkId?: string | null;
  orderingProviderId?: string | null;
  orderingProviderLinkId?: string | null;
  primaryProviderId?: string | null;
  primaryProviderLinkId?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  placeOfServiceCodeId?: string | null;
  placeOfServiceCodeLinkId?: string | null;
  locationId?: string | null;
  locationLinkId?: string | null;
  modifier1Id?: string | null;
  modifier2Id?: string | null;
  modifier3Id?: string | null;
  modifier4Id?: string | null;
  procedureAmount?: number | null;
  quantity?: number;
  insuranceAuthNumber?: string | null;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  claimComment?: string | null;
  accessionNumber?: string | null;
  comment?: string | null;
  nationalDrugCodeId?: string | null;
  drugUnit?: NdcUnit;
  drugQuantity?: number | null;
  drugAmount?: number | null;
  drugCode?: string | null;
  prescriptionNumber?: string | null;
  holdPatientBilling?: boolean | null;
  holdInsuranceBilling?: boolean | null;
  nocProcedureDescription?: string | null;
  diagnoses?: Array<ProcedureDiagnosisCodeHoldingViewModel> | null;
}
