import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';
import { ConfigurationService, FeatureConfigurationViewModel, UploadCustomizationViewModel } from 'data';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationWrapperService extends BaseWrapperService {
  constructor(private service: ConfigurationService, private store: Store<any>) {
    super(store);
  }

  apiV1ConfigurationFeatureFeaturenameGet(featurename: string): Observable<FeatureConfigurationViewModel> {
    return this.dataSetId$.pipe(
        switchMap((dataSetId) => this.service.apiV1ConfigurationFeatureFeaturenameGet(featurename, dataSetId))
      );
  }

  apiV1ConfigurationUploadGet(): Observable<UploadCustomizationViewModel> {
    return this.dataSetId$.pipe(
        switchMap((dataSetId) => this.service.apiV1ConfigurationUploadGet(dataSetId))
      );
  }
}
