/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeViewModel } from './codeViewModel';
import { InsuranceViewModel } from './insuranceViewModel';
import { ARTransactionDetail } from './aRTransactionDetail';

export interface ARTransactionDetailsViewModel {
  id?: string;
  datasetId?: string;
  visitId?: string;
  patientId?: string;
  paymentCodeId?: string | null;
  adjustmentCodeId?: string | null;
  adjustmentCode2Id?: string | null;
  postingDate?: string | null;
  transactionDate?: string | null;
  allowed?: number;
  paid?: number;
  adjusted?: number;
  adjusted2?: number;
  copay?: number;
  coinsurance?: number;
  deductible?: number;
  checkNumber?: string | null;
  internalControlNumber?: string | null;
  enteredBy?: string | null;
  postedBy?: string | null;
  paymentTypeId?: string | null;
  batchId?: string;
  insuranceId?: string;
  insurance?: InsuranceViewModel;
  arTransactionDetails?: Array<ARTransactionDetail> | null;
  paymentCode?: CodeViewModel;
  adjustmentCode?: CodeViewModel;
  paymentType?: CodeViewModel;
}
