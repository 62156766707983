import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';
import { SearchRequestViewModel, SearchResultsViewModel, SearchService } from 'data';

@Injectable({
  providedIn: 'root'
})
export class SearchWrapperService extends BaseWrapperService {
  constructor(private service: SearchService, private store: Store<any>) {
    super(store);
  }

  apiV1SearchPost(searchRequestViewModel?: SearchRequestViewModel): Observable<SearchResultsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1SearchPost(dataSetId, searchRequestViewModel))
    );
  }
}
