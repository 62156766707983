/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface ProcedureImportViewModel {
  accountNumber?: number;
  quantity?: number;
  id?: string;
  datasetId?: string;
  batchId?: string;
  sendingFacilityId?: string;
  patientId?: string | null;
  patientHoldingId?: string | null;
  visitHoldingId?: string | null;
  accessionNumber?: string | null;
  visitId?: string | null;
  referenceNumber?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  procedureCode?: string | null;
  diagnosisCode?: string | null;
  procedureAmount?: number | null;
  procedureCount?: number | null;
  numberOfProcedures?: number | null;
  serviceDate?: string | null;
  locationCode?: string | null;
  providerNumber?: string | null;
  receiveDate?: string | null;
  issues?: Array<HoldingIssue> | null;
}
