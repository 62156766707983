import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ProcedureService,
  ProcedureDetailsViewModel,
  ProcedureDetailsViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ProcedureWrapperService extends BaseWrapperService {
  constructor(private service: ProcedureService, private store: Store<any>) {
    super(store);
  }

  apiV1ProcedureAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<ProcedureDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ProcedureAllPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1ProcedureIdGet(id: string): Observable<ProcedureDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProcedureIdGet(id, dataSetId)));
  }

  apiV1ProcedureInsuranceProcedurePost(requestBody): Observable<ProcedureDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProcedureInsuranceProcedurePost(dataSetId, requestBody)));
  }

  apiV1ProcedureAddPost(requestBody): Observable<ProcedureDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProcedureAddPost(dataSetId, requestBody)));
  }

  apiV1ProcedurePatchIdPatch(id: string, operation): Observable<ProcedureDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ProcedurePatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1ProcedureIdDelete(id: string): Observable<ProcedureDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProcedureIdDelete(id, dataSetId)));
  }
}
