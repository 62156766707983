<div class="spinner-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="grid-wrapper" *ngIf="initialized === true">
  <table
    mat-table
    [class.grid]="true"
    [class.has-filter]="hasFilters"
    [class.has-row-click]="this.gridConfiguration?.rowClickEvent"
    [dataSource]="dataSource"
    matSort
    #matTable
  >
    <ng-container
      *ngFor="let column of gridConfiguration?.columnDefinitions; trackBy: trackById"
      [matColumnDef]="column.id"
      [sticky]="column?.sticky"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="column | headerColumnClass"
        [mat-sort-header]="column.displayKey"
        [disabled]="!column.sortable"
      >
        <span class="label">{{ column.headerText }}</span>
      </th>

      <td
        mat-cell
        *matCellDef="let data; let i = index"
        [ngClass]="count > 0 ? 'lib-body-cell' : 'clear'"
        [id]="camelCaseRemoveSpaces(column.id)"
      >
        <ng-container *ngTemplateOutlet="cellContent"></ng-container>
        <ng-template #cellContent>
          <span *ngIf="(pagedModel?.itemCount > 0 || count > 0) && initialized">
            {{ column.formatter ? column.formatter(data[column.displayKey], data) : data[column.displayKey] }}
            <div *ngIf="column.status_icon" class="cell-status-icon">
              <mat-icon
                *ngIf="column.show_icon_condition ? column.show_icon_condition(data) : false"
                class="{{ column.icon_class(data) }}"
                matTooltip="{{ column.icon_tooltip(data) }}"
              >
                {{ column.status_icon(data) }}
              </mat-icon>
            </div>
          </span>
          <div *ngIf="column.cellActionButton" class="cell-action-button">
            <button
              mat-stroked-button
              (click)="cellActionButtonClick($event, column.displayKey, data)"
              matTooltip="{{ column.cellActionButton?.tooltip }}"
            >
              <span class="material-icons" *ngIf="column.cellActionButton?.icon">
                {{ column.cellActionButton?.icon }}
              </span>
              {{ column.cellActionButton?.text }}
            </button>
          </div>
        </ng-template>
      </td>
    </ng-container>

    <ng-container
      [matColumnDef]="column.id + '-filter'"
      *ngFor="let column of gridConfiguration?.columnDefinitions; trackBy: trackById"
      [sticky]="column?.sticky"
    >
      <th mat-header-cell *matHeaderCellDef [ngClass]="column.columnSize">
        <lib-grid-filter
          *ngIf="column.filterable"
          [formGroup]="filterFormGroup"
          [controlName]="column.displayKey"
          [filterConfig]="column?.filterConfig"
          class="lib-grid-filter"
          [id]="camelCaseRemoveSpaces(column.id) + '_filter'"
        >
        </lib-grid-filter>
      </th>
    </ng-container>

    <tr
      mat-header-row
      *matHeaderRowDef="gridConfiguration?.displayColumns; sticky: false"
      class="lib-grid__header-row"
    ></tr>
    <ng-container *ngIf="hasFilters">
      <tr
        mat-header-row
        *matHeaderRowDef="gridConfiguration?.displayColumns | filterColumns; sticky: false"
        class="lib-grid__filter-row"
      ></tr>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: gridConfiguration?.displayColumns"></tr>
  </table>
  <div class="empty-results" *ngIf="count === 0 && initialized && emptyMessage">
    <p *ngIf="count === 0"><b>No accounts were found within the specified range</b></p>
  </div>
</div>

<mat-paginator
  *ngIf="hasPaginator"
  [length]="pagedModel?.itemCount"
  [pageSize]="pagedModel?.pageSize"
  [pageIndex]="pagedModel?.page"
  [pageSizeOptions]="[25, 50, 100, 250, 500]"
  [showFirstLastButtons]="true"
  class="paginator"
  (pageChanged)="pageChanged($event)"
>
</mat-paginator>
