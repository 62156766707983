<header class="sub-page-layout__header paymentCodes__header">
  <div class="paymentCodes__header-text h2 h2--semi-bold">Payment Codes</div>
  <button mat-flat-button color="primary" class="paymentCodes__add-item" (click)="uploadCodes()">
    <mat-icon>file_upload</mat-icon> Upload Payment Codes
  </button>
  <button mat-flat-button color="primary" class="paymentCodes__add-item" (click)="addPaymentCode()">
    <mat-icon>add_circle_outline</mat-icon> Add Payment Code
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No Payment Codes Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="paymentCodes__footer">
  <lib-button-loading color="primary" class="paymentCodes__button" [invalid]="itemCount === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
