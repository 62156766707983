<article class="procedure-details">
  <header class="procedure-details__header">
    Procedure {{ procedureNumber }}
    <button
      *ngIf="procedure?.isReversed === false && procedure?.createdBy !== 'Reversal'"
      mat-button
      [matMenuTriggerFor]="menu"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="editProcedure.emit(procedure.id)">Edit</button>
      <button mat-menu-item (click)="reverseProcedure.emit(procedure.id)">Reverse</button>
    </mat-menu>
  </header>

  <dl *ngIf="procedure" class="procedure-details__data data-list">
    <dt>Post Date</dt>
    <dd>
      {{ procedure.postDate | dateDisplay }}
    </dd>
    <dt>Procedure</dt>
    <dd>
      {{ procedure | procedureDescription }}
      {{
        procedure.nationalDrugCode
          ? '(' +
            formatDrugCode(procedure.nationalDrugCode.ndcCode) +
            ', ' +
            procedure.nationalDrugCode.ndcQuantity.toFixed(2) +
            ' ' +
            procedure.nationalDrugCode.ndcUnit +
            ')'
          : ''
      }}
    </dd>
    <dt>Modifier(s)</dt>
    <dd>
      {{ procedure.modifier1?.code }} {{ procedure.modifier2?.code }} {{ procedure.modifier3?.code }}
      {{ procedure.modifier4?.code }}
    </dd>
    <dt>Diagnosis Code</dt>
    <dd>
      {{ procedure | diagnosisDescription : diagnoses }}
    </dd>
    <dt>Service Date</dt>
    <dd>
      {{ procedure.serviceFromDate | dateDisplay }}
    </dd>
    <dt>Attending Provider</dt>
    <dd>
      {{ procedure.attendingProvider | physicianDisplay }}
    </dd>
    <dt>Referring Provider</dt>
    <dd>
      {{ procedure.referringProvider | physicianDisplay }}
    </dd>
    <dt>Location</dt>
    <dd>
      {{ procedure.location.name }}
    </dd>

    <dt>Place of Service Code</dt>
    <dd>
      {{ procedure.placeOfServiceCode?.code || '--' }}
    </dd>
    <dt>Accession #</dt>
    <dd>
      {{ procedure.accessionNumber || '--' }}
    </dd>
    <dt>Authorization#</dt>
    <dd>
      {{ procedure.insuranceAuthNumber || '--' }}
    </dd>

    <dt>Amount</dt>
    <dd>
      <span class="text-bold">
        {{ procedure.procedureAmount | currency }}
      </span>
    </dd>
    <dt>Balance</dt>
    <dd>
      {{ procedure.balance | currency }}
    </dd>
  </dl>

  <aside class="procedure-details__sidebar">
    <div class="procedure-details__bill-procedure">
      <div>
        <div class="h5 h5--semi-bold">Bill Procedure</div>
        <span *ngIf="procedure?.previouslyBilled">This procedure will be billed again.</span>
        <span *ngIf="procedure?.currentlyBilled"
          >Billing currently scheduled to {{ procedure.currentlyBilledCarriers }}</span
        >
      </div>
      <mat-slide-toggle color="primary" (change)="billProcedureChanged($event)" checked="true"> </mat-slide-toggle>
    </div>
  </aside>

  <lib-procedure-activity [transactions]="procedure?.arTransactionDetails" class="procedure-details__procedures">
  </lib-procedure-activity>
</article>
