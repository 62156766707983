import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { JobService, Operation, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class JobWrapperService extends BaseWrapperService {
  constructor(private service: JobService, private store: Store<any>) {
    super(store);
  }

  apiV1JobAllPost(pagedModelRequest: PagedModelRequest) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1JobAllPost(dataSetId, pagedModelRequest);
      })
    );
  }
  apiV1JobIdGet(jobId: string) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1JobIdGet(jobId, dataSetId);
      })
    );
  }
}
