import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AddInsuranceCarrierClaimValueViewModel,
  InsuranceCarrierClaimValueDetailsViewModel,
  InsuranceCarrierClaimValueDetailsViewModelPagedModelResponse,
  InsuranceCarrierClaimValueService
} from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCarrierClaimValueWrapperService extends BaseWrapperService {
  constructor(private service: InsuranceCarrierClaimValueService, private store: Store<any>) {
    super(store);
  }

  apiV1InsuranceCarrierClaimValueAllPost(
    requestBody
  ): Observable<InsuranceCarrierClaimValueDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValueAllPost(dataSetId, requestBody))
    );
  }

  apiV1InsuranceCarrierClaimValueIdGet(id: string): Observable<InsuranceCarrierClaimValueDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValueIdGet(id, dataSetId))
    );
  }

  apiV1InsuranceCarrierClaimValueAddPost(requestBody): Observable<AddInsuranceCarrierClaimValueViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValueAddPost(dataSetId, requestBody))
    );
  }

  apiV1InsuranceCarrierClaimValuePatchIdPatch(
    id: string,
    operation
  ): Observable<InsuranceCarrierClaimValueDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValuePatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1InsuranceCarrierClaimValueIdDelete(id: string): Observable<InsuranceCarrierClaimValueDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValueIdDelete(id, dataSetId))
    );
  }

  apiV1InsuranceCarrierClaimValueUpdateAll(insurancecarrierid: string, requestBody): Observable<Array<string>> {
      return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValueUpdateAllInsurancecarrieridPost(insurancecarrierid, dataSetId, requestBody))
    );
  }

  apiV1InsuranceCarrierClaimValueEnsureClaimValuePost(ruleCardId: string, insurancecarrierid: string): Observable<Boolean> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1InsuranceCarrierClaimValueEnsureClaimValuePost(dataSetId, ruleCardId, insurancecarrierid))
    );
  }

  apiV1InsuranceCarrierClaimValueSearchInsurancecarrieridPost(insurancecarrierid: string, requestBody): Observable<InsuranceCarrierClaimValueDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) =>
        this.service.apiV1InsuranceCarrierClaimValueSearchInsurancecarrieridPost(
          insurancecarrierid,
          dataSetId,
          requestBody
        )
      )
    );
  }
}
