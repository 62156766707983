/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FinancialClassViewModel } from './financialClassViewModel';
import { CodeViewModel } from './codeViewModel';
import { FeeScheduleViewModel } from './feeScheduleViewModel';

export interface InsuranceCarrierDetailsViewModel {
  id?: string;
  datasetId?: string;
  carrierCode?: string | null;
  carrierName?: string | null;
  crossoverPayerId?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
  holdBilling?: boolean;
  referringProviderRequired?: boolean;
  policyNumberRequired?: boolean;
  groupNumberRequired?: boolean;
  claimType?: string | null;
  carrierType?: string | null;
  payerId?: string | null;
  chargeInsCode?: number;
  financialClassId?: string;
  financialClass?: FinancialClassViewModel;
  feeScheduleId?: string | null;
  feeSchedule?: FeeScheduleViewModel;
  procedureFeeScheduleId?: string | null;
  procedureFeeSchedule?: FeeScheduleViewModel;
  acceptAssignment?: boolean;
  sameRefund?: boolean;
  refundName?: string | null;
  refundAddress1?: string | null;
  refundAddress2?: string | null;
  refundCity?: string | null;
  refundStateCode?: string | null;
  refundZip?: string | null;
  refundCountryCode?: string | null;
  useDeptOfLaborProviderId?: boolean;
  email?: string | null;
  inactive?: boolean;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  paymentCodeId?: string | null;
  adjustmentCodeId?: string | null;
  interestCodeId?: string | null;
  paymentCode?: CodeViewModel;
  adjustmentCode?: CodeViewModel;
  interestCode?: CodeViewModel;
  codeSearch?: string | null;
  nameSearch?: string | null;
  defaultInsuranceTypeId?: string | null;
  excludeEligibility?: boolean;
  autoAdjustProcedures?: boolean;
  readonly codeSearchIndex?: number;
  readonly nameSearchIndex?: number;
}
