<div class="visit-details mat-elevation-z4">
  <header class="visit-details__details-header">
    <h3>Visit {{ visit?.referenceNumber }}</h3>

    <div class="visit-details__details-buttons">
      <button mat-button (click)="visitAction.emit({ action: 'cancel' })">Cancel</button>
      <button mat-stroked-button color="primary" (click)="visitAction.emit({ action: 'edit' })">Edit Visit</button>
      <button mat-flat-button color="primary" (click)="visitAction.emit({ action: 'bill', data: this.billProcedure })">
        Bill Visit
      </button>
      <!-- !This functionality has not been scoped yet -->
      <!-- <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>
          more_horiz
        </mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="notImplemented()">
          <span>Print On-demand</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Flag for ECS</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Flag for Paper</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Copy/Merge</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Delete</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Collections</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Add Note</span>
        </button>
        <button mat-menu-item (click)="notImplemented()">
          <span>Manual Hold</span>
        </button>
      </mat-menu> -->
    </div>
  </header>

  <div class="visit-details__visit-body">
    <lib-card>
      <ng-container name="title">Procedure Entry</ng-container>
      <lib-procedure-details
        *ngFor="let procedure of visit?.procedureTransactions; let i = index; trackBy: trackById"
        [procedure]="procedure"
        [procedureNumber]="i + 1"
        [diagnoses]="diagnoses"
        [locations]="locations"
        (editProcedure)="visitAction.emit({ action: 'edit' })"
        (reverseProcedure)="reverseProcedure.emit($event)"
        (billProcedureChange)="billProcedureChanged($event)"
        class="visit-details__procedure-entry"
      >
      </lib-procedure-details>
    </lib-card>
    <lib-procedure-totals [visit]="visit"></lib-procedure-totals>
    <lib-visit-information [visit]="visit"></lib-visit-information>
    <lib-eob-history [visit]="visit"></lib-eob-history>
  </div>
</div>
