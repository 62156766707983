/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddDatasetViewModel {
  name?: string | null;
  acronym?: string | null;
  taxIdNumber?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  phoneNumber?: string | null;
  taxonomy?: string | null;
  npi?: string | null;
  stateLicenseNumber?: string | null;
  mailingName?: string | null;
  mailingAddress1?: string | null;
  mailingAddress2?: string | null;
  mailingCity?: string | null;
  mailingStateCode?: string | null;
  mailingZip?: string | null;
  mailingCountryCode?: string | null;
  websiteUrl?: string | null;
  contactEmailAddress?: string | null;
  personEntity?: boolean;
  billingProviderLastOrgName?: string | null;
  billingProviderFirstName?: string | null;
  billingProviderMiddleName?: string | null;
  billingProviderSuffix?: string | null;
  billingProviderNPI?: string | null;
  maxDelinquencyCode?: number;
  defaultPwkReportTypeCode?: string | null;
  defaultPwkTransmissionCode?: string | null;
  deptOfLaborProviderId?: string | null;
  monthCloseDate?: string | null;
  sequence?: number;
}
