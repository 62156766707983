import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar.component';
import { RouterModule } from '@angular/router';
import { DataSetSelectorModule } from './data-set-selector';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialModule } from '../../shared';
@NgModule({
  declarations: [SidebarComponent],
  exports: [SidebarComponent],
  imports: [CommonModule, RouterModule, DataSetSelectorModule, MatMenuModule, MaterialModule]
})
export class SidebarModule {}
