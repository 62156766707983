<header class="sub-page-layout__header adjustmentCodes__header">
  <div class="adjustmentCodes__header-text h2 h2--semi-bold">Adjustment Codes</div>
  <button mat-flat-button color="primary" class="adjustmentCodes__add-item" (click)="uploadCodes()">
    <mat-icon>file_upload</mat-icon> Upload Adjustment Codes
  </button>
  <button mat-flat-button color="primary" class="adjustmentCodes__add-item" (click)="addAdjustmentCodes()">
    <mat-icon>add_circle_outline</mat-icon> Add Adjustment Code
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No Adjustment Codes Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="adjustmentCodes__footer">
  <lib-button-loading
    color="primary"
    class="adjustmentCodes__button"
    [invalid]="itemCount === 0"
    (clickEvent)="next()"
    id="nextButton"
    >Next
  </lib-button-loading>
</footer>
