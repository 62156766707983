/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BatchViewModel } from './batchViewModel';

export interface ImaginePayUnappliedPaymentViewModel {
  paymentUnappliedId?: string;
  paymentRecordId?: string;
  datasetId?: string;
  providerId?: string | null;
  accountNumber?: string | null;
  statementId?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  dateOfBirth?: string | null;
  requestType?: string | null;
  balance?: number;
  paymentAmount?: number;
  unappliedAmount?: number;
  paymentType?: string | null;
  cardType?: string | null;
  checkNumber?: string | null;
  referenceNumber?: string | null;
  settlementDate?: string;
  createdDate?: string;
  unappliedReasons?: string | null;
  batchId?: string;
  batchNumber?: number;
  batch?: BatchViewModel;
}
