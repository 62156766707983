import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AddRuleCardCriteriaViewModel, AuthUserPostModel, Operation, PagedModelRequest, RuleCardCriteriaService } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class RuleCardCriteriaWrapperService extends BaseWrapperService {

  constructor(private ruleCardCriteriaService: RuleCardCriteriaService, private store: Store<any>) {
    super(store);
  }

  // Rule Card Criteria
  apiV1RulecardcriteriaAllPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaAllPost(dataSetId, pagedModelRequest))
    );
  }

  // Rule Card Criteria Details
  apiV1RulecardcriteriaAllDetailPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaAllDetailPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1RulecardcriteriaGetcriteriadataPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaGetcriteriadataPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1RulecardcriteriaGetdropdownvaluesPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaGetdropdownvaluesPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1RulecardcriteriaAddPost(addRuleCardCriteriaViewModel?: AddRuleCardCriteriaViewModel ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaAddPost(dataSetId, addRuleCardCriteriaViewModel))
    );
  }

  apiV1RulecardcriteriaDetailsIdGet(ruleCardCriteriaId: string ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaDetailsIdGet(ruleCardCriteriaId, dataSetId))
    );
  }

  apiV1RulecardcriteriaPatchIdPatch(userId?: string, operation?: Array<Operation> ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaPatchIdPatch(dataSetId, userId, operation))
    );
  }

  apiV1RulecardcriteriaIdDelete(userId?: string ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.ruleCardCriteriaService.apiV1RulecardcriteriaIdDelete(dataSetId, userId))
    );
  }

}
