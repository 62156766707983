import { Injectable } from '@angular/core';
import { getSelectedDataSetId } from '@core/state/data-set/data-set.selectors';
import { select, Store } from '@ngrx/store';
import { PlaceOfServiceCrosswalkService } from 'projects/data/src/lib/api/placeOfServiceCrosswalk.service';
import { UpdatePlaceOfServiceCrosswalkViewModel } from 'projects/data/src/lib/model/updatePlaceOfServiceCrosswalkViewModel';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class PlaceOfServiceCrosswalkWrapperService extends BaseWrapperService {
  constructor(private service: PlaceOfServiceCrosswalkService, private store: Store<any>) {
    super(store);
  }
  
  apiV1PlaceOfServiceCrosswalkInsuranceCarrierInsurancecarrieridGet(insuranceCarrierId) {
    return this.store
    .pipe(select(getSelectedDataSetId))
    .pipe(switchMap((dataSetId) => this.service.apiV1PlaceOfServiceCrosswalkInsuranceCarrierInsurancecarrieridGet(insuranceCarrierId, dataSetId)));
  }

  apiV1PlaceOfServiceCrosswalkUpdateInsurancecarrieridPost(insuranceCarrierId, crosswalks: UpdatePlaceOfServiceCrosswalkViewModel[]) {
    return this.store
    .pipe(select(getSelectedDataSetId))
    .pipe(switchMap((dataSetId) => this.service.apiV1PlaceOfServiceCrosswalkUpdateInsurancecarrieridPost(insuranceCarrierId, dataSetId, crosswalks)));
  }

}
