import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthUserPostModel, Operation, PagedModelRequest, RoleService, UserService } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class UserMaintenanceSecurityWrapperService extends BaseWrapperService {

  constructor(private roleService: RoleService, private userService: UserService, private store: Store<any>) {
    super(store);
  }

  apiV1RolePost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.roleService.apiV1RolePost(dataSetId, pagedModelRequest))
    );
  }

  apiV1RoleCreateRolePost(authRolePostModel?: any ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.roleService.apiV1RoleCreateRolePost(dataSetId, authRolePostModel))
    );
  }

  apiV1RoleIdGet(roleId: string ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.roleService.apiV1RoleIdGet(roleId, dataSetId))
    );
  }

  apiV1RoleDeleteRoleDelete(roleId?: string ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.roleService.apiV1RoleDeleteRoleDelete(dataSetId, roleId))
    );
  }

  apiV1RoleUpdateRolePost(authRolePostModel): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.roleService.apiV1RoleUpdateRolePost(dataSetId, authRolePostModel))
    );
  }


  // User APIs
  apiV1UserPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1UserCreateUserPost(authRolePostModel?: AuthUserPostModel ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserCreateUserPost(dataSetId, authRolePostModel))
    );
  }

  apiV1UserIdGet(userId: string ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserIdGet(userId, dataSetId))
    );
  }

  apiV1UserUpdateUserPatch(userId?: string, operation?: Array<Operation> ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserUpdateUserPatch(dataSetId, userId, operation))
    );
  }

  apiV1UserDeleteUserDelete(userId?: string ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserDeleteUserDelete(dataSetId, userId))
    );
  }

  apiV1UserRolesPost(userRolePostModel?: any ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserRolesPost(dataSetId, userRolePostModel))
    );
  }

  apiV1UserDatasetsPost(userDatasetPostModel?: any ): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.userService.apiV1UserDatasetsPost(dataSetId, userDatasetPostModel))
    );
  }
}
