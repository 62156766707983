import { DatePipe } from '@angular/common';
import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DialogContent, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FeeScheduleWrapperService } from 'src/app/core/services/service-wrappers/fee-schedule-wrapper.service';
import { FeeScheduleEntryViewModel } from '../../../../../../projects/data/src/public-api';
import { DialogService } from '../../../../core/services/dialog.service';
import { ModifiersLookupService, ProcedureCodesLookupService } from '../../../../core/services/lookup';
import { FeeScheduleLookupService } from '../../../../core/services/lookup/fee-schedule-lookup.service';
import { FeeSchedulesEntryDialogComponent } from './fee-schedules-entry-dialog/fee-schedules-entry-dialog.component';
import { PopulateFeeScheduleDialogComponent } from './populate-fee-schedule-dialog/populate-fee-schedule-dialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-fee-schedules-dialog',
  templateUrl: './fee-schedules-dialog.component.html',
  styleUrls: ['./fee-schedules-dialog.component.scss'],
  providers: [DatePipe]
})
export class FeeSchedulesDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<FeeScheduleEntryViewModel>;
  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns: string[] = ['name', 'procedure', 'modifier', 'amount', 'actions'];
  dataSource: any;
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  name: string;
  saving = false;
  commit = false;
  allow = false;
  sameFeeName = false;
  duplicateName = false;
  entryForm: UntypedFormGroup;
  produreArray = [];
  modifierArray = [];
  entryArray = [];
  entryIdArray = [];
  allowAdd = false;
  createEntries = [];
  updateEntries = [];
  completeArray = [];

  formDefinitions: FormGroupDefinition[] = [
    {
      hideTitle: true,
      controls: [
        {
          label: 'Name',
          name: 'name',
          focusId: 'name',
          type: 'text',
          class: 'form-span-10',
          validators: Validators.required,
          selectionChanged: (event) => {
            this.nameChange(event);
          }
        },
        {
          label: 'Effective From',
          name: 'effectiveFrom',
          type: 'date',
          class: 'form-span-6',
          validators: Validators.required,
          datepickerChanged: (event) => {
            this.sameNameFrom(event);
          }
        },
        {
          label: 'Effective To',
          name: 'effectiveTo',
          type: 'date',
          class: 'form-span-6',
          customErrorMessage: 'Effective From date.',
          validators: Validators.required,
          datepickerChanged: (event) => {
            this.validatorEffectiveToDate(event);
            this.sameNameTo(event);
          }
        },
        {
          label: 'Upload File',
          name: 'uploadFile',
          type: 'empty',
          class: 'form-span-12'
        }
      ]
    }
  ];

  feeScheduleId;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<FeeSchedulesDialogComponent>,
    private service: FeeScheduleWrapperService,
    private procedureLookup: ProcedureCodesLookupService,
    private modifierLookup: ModifiersLookupService,
    private fb: UntypedFormBuilder,
    private dialogService: DialogService,
    public dialog: MatDialog,
    public lookup: FeeScheduleLookupService,
    public datePipe: DatePipe
  ) {
    this.feeScheduleId = data?.feeScheduleId;
  }

  ngOnInit(): void {
    this.setupForm();
    if (this.feeScheduleId && this.feeScheduleId !== '') {
      this.allowAdd = true;
      this.loadGrid(this.feeScheduleId);
    }
  }

  populateFees() {
    const dialog = this.dialog.open(PopulateFeeScheduleDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        feeScheduleId: this.feeScheduleId
      },
      height: '47%',
      width: '50%'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.notificationService.success('Fee Schedule Has Been Populated');
        this.loadGrid(this.feeScheduleId);
      }
    });
  }

  editFee(row) {
    const dialog = this.dialog.open(FeeSchedulesEntryDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: {
        feeScheduleEntry: row.id,
        feeSchedule: row.feeScheduleId
      },
      height: '500px',
      width: '800px'
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadGrid(this.feeScheduleId);
      }
    });
  }

  addEntry() {
    const dialog = this.dialog.open(FeeSchedulesEntryDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { feeSchedule: this.feeScheduleId },
      height: '500px',
      width: '800px'
    });
    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.loadGrid(this.feeScheduleId);
      }
    });
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  //throws error if name and date range values already exist
  nameChange(event) {
    if (event !== '') {
      let to = moment(new Date(this.datePipe.transform(this.formGroup.get('effectiveTo').value, 'MM/dd/yyyy')));
      this.lookup.search('').subscribe((result) => {
        result
          .filter((fee) => fee.label !== this.name)
          .forEach((schedule) => {
            if (schedule?.label?.toLowerCase() === event?.toLowerCase()) {
              this.sameFeeName = true;
              this.sameNameTo(to, event);
            } else {
              this.sameFeeName = false;
              this.duplicateName = false;
            }
          });
      });
    }
  }

  //updates validity of name input if from date field fall within already existing range
  sameNameFrom(date) {
    if (this.formGroup.get('name').value !== null) {
      this.formGroup.get('effectiveTo').setValue(null);
    }
  }

  //updates validity of name input if To date field fall within already existing range
  sameNameTo(date, name: string = this.formGroup.get('name').value) {
    if (name !== null) {
      let dateTo = moment(new Date(date.value));
      let dateFrom = moment(new Date(this.formGroup.get('effectiveFrom').value));
      this.lookup.search(name).subscribe((result) => {
        let sameNameFromDate = moment(new Date(this.datePipe.transform(result[0]?.fromDate, 'MM/dd/yyyy')));
        let sameNameToDate = moment(new Date(this.datePipe.transform(result[0]?.toDate, 'MM/dd/yyyy')));
        if (
          dateFrom.isBetween(sameNameFromDate, sameNameToDate) ||
          dateTo.isBetween(sameNameFromDate, sameNameToDate) ||
          dateFrom.isSame(sameNameFromDate) ||
          dateFrom.isSame(sameNameToDate) ||
          dateTo.isSame(sameNameFromDate) ||
          dateTo.isSame(sameNameToDate) ||
          (new Date(this.formGroup.get('effectiveFrom').value).getFullYear() === 1 &&
            new Date(this.formGroup.get('effectiveTo').value).getFullYear() === 9999)
        ) {
          this.duplicateName = true;
        } else {
          this.duplicateName = false;
        }
      });
    }
  }

  //validates that effective To Date is Greater that effective From Date
  validatorEffectiveToDate(date) {
    const to = new Date(date.value);
    const from = new Date(this.formGroup.get('effectiveFrom').value);
    if (to < from && from !== null) {
      this.formGroup.get('effectiveTo').setErrors({ greaterDate: true });
      this.formGroup.get('effectiveFrom');
    } else {
      this.formGroup.get('effectiveTo').setErrors(null);
      this.formGroup.get('effectiveFrom').setErrors(null);
      this.formGroup.get('effectiveFrom').updateValueAndValidity();
    }
  }

  //refreshes any update to fee schedule entry for the current fee schedule
  loadGrid(fee: string) {
    this.service
      .apiV1FeeScheduleDetailsIdGet(fee)
      .pipe(first())
      .subscribe((result) => {
        this.name = result.name;
        this.formGroup.patchValue(result);
        this.entryArray = result.feeScheduleEntries
        this.dataSource = new MatTableDataSource(this.entryArray);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'procedure': return item.procedureCode;
            default: return item[property];
          }
        };
      });
  }

  save(closeWindow: boolean) {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        name: this.formGroup.get('name').value,
        effectiveFrom: this.formGroup.get('effectiveFrom').value,
        effectiveTo: this.formGroup.get('effectiveTo').value
      };

      let saveEntry: Observable<any>;

      let saveObservable: Observable<any>;
      let notification;
      if (this.feeScheduleId) {
        notification = 'Fee Schedule Updated';
        saveObservable = this.service.apiV1FeeSchedulePatchIdPatch(this.feeScheduleId, compare({}, formData)).pipe(
          map((x: any) => {
            return x;
          })
        );
      } else {
        notification = 'Fee Schedule Added';
        saveObservable = this.service.apiV1FeeScheduleAddPost(formData).pipe(
          map((x: any) => {
            return x;
          })
        );
      }

      saveObservable.pipe(take(1)).subscribe(
        (response) => {
          this.saving = false;
          this.feeScheduleId = response.id;
          this.allowAdd = true;
          this.notificationService.success('Fee Schedule Saved');
          if (closeWindow === true) {
            this.dialogRef.close(true);
          }
        },
        (err) => this.notificationService.error('Error Saving Fee Schedule')
      );
    }
  }

  delete(row) {
    const modalContent: DialogContent = {
      header: '',
      body: `Are you sure you want to delete this fee schedule entry?`,
      cancelButtonText: 'Back',
      OKButtonText: 'Confirm'
    };
    this.dialogService.showConfirmDialog(modalContent).subscribe((result) => {
      if (result) {
        this.service
          .apiV1FeeScheduleEntryIdDelete(row.id)
          .pipe(
            map((x: any) => {
              return x;
            }),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.loadGrid(row.feeScheduleId);
          });
      }
    });
  }

  cancel() {
    if (this.data.upload === true) {
      this.service
        .apiV1FeeScheduleIdDelete(this.feeScheduleId)
        .pipe(
          map((x: any) => x),
          take(1)
        )
        .subscribe((deleteResult) => {
          this.dialogRef.close();
        });
    } else {
      this.dialogRef.close();
    }
  }
}
