import { Injectable } from '@angular/core';
import { BaseGridSource, PagedModel } from 'components';
import { of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { ProcedureWrapperService } from 'src/app/core/services/service-wrappers/procedure-wrapper.service';

@Injectable()
export class ProceduresService extends BaseGridSource<any> {
  constructor(private service: ProcedureWrapperService) {
    super();
  }
  load(pagedModel: PagedModel) {
    this.loadingSubject.next(true);

    this.service
      .apiV1ProcedureAllPost(pagedModel)
      .pipe(
        map((response) => {
          this.itemCountSubject.next(response.metaData.totalItemCount);
          const data = response.data.map((procedure) => {
            return {
              ...procedure,
              effectiveTo: new Date(procedure.effectiveTo),
              effectiveFrom: new Date(procedure.effectiveFrom)
            };
          });

          return { ...response, data };
        }),
        catchError(() => of({ data: [] })),
        finalize(() => {
          this.loadingSubject.next(false);
        }),
        take(1)
      )
      .subscribe((data) => this.dataSourceSubject.next(data.data));
  }
}
