<section class="lib-upload-modal">
  <h2 *ngIf="data?.title">{{ data.title }}</h2>
  <h4 *ngIf="data?.subTitle">{{ data.subTitle }}</h4>
  <!-- <mat-icon class='lib-upload-modal__close' (click)='close()'>close</mat-icon> -->
  <div
    class="lib-upload-modal__drop-zone"
    [ngClass]="{ dragging: isDragging, disabled: disabled }"
    [fileUploadInputFor]="fileUploadQueue"
    (dragstart)="dragging(true)"
    (dragenter)="dragging(true)"
    (dragend)="dragging(false)"
    (dragleave)="dragging(false)"
    (drop)="dragging(false)"
    *ngIf="!(fileUploadQueue?.files?.length >= data.maxFiles)"
  >
    Drop file here, or
    <label for="drop-zone" class="lib-upload-modal__drop-zone--label">browse</label>
    <input
      type="file"
      id="drop-zone"
      class="lib-upload-modal__drop-zone--button"
      [multiple]="data.maxFiles > 1"
      [disabled]="disabled"
      [fileUploadInputFor]="fileUploadQueue"
    />
  </div>

  <section class="lib-upload-modal__uploaded-files">
    <lib-file-upload-queue
      #fileUploadQueue
      [uploadAllLabel]="data.uploadAllLabel"
      [httpUrl]="data.uploadUrl"
      [maxFiles]="data.maxFiles"
      [allowedExtensions]="data.allowedExtensions"
      [httpRequestHeaders]="data.httpRequestHeaders"
      [httpRequestParams]="data.httpRequestParams"
      (isDisabled)="setIsDisabled($event)"
      [checkUploadFile]="data.checkUploadFile"
    >
      <h6 *ngIf="fileUploadQueue?.files?.length > 0">{{ selectionTitle }}</h6>
      <lib-file-upload
        *ngFor="let file of fileUploadQueue.files; let i = index; trackBy: trackByIndex"
        [id]="i"
        [file]="file"
        (uploadEvent)="uploadFinished($event, i)"
      ></lib-file-upload>
    </lib-file-upload-queue>
  </section>
</section>
