import { BodyClasses, ColumnSizes } from './grid-classes';
import { FilterConfiguration } from './filter-configuration';
import { ColumnDisplayType } from './column-display-type.enum';
import { CellActionButton } from './cell-action-button';
import { OverflowMenuOptions } from '../../overflow-menu/overflow-menu.component';
import { FormControl, Validator, ValidatorFn, Validators } from '@angular/forms';
/**
 * Object to be passed into our grid to configure rows and columns
 *
 * @export
 * @interface ColumnDefinitions
 */
export interface ColumnDefinition {
  // Column Id
  id: string;
  // Header text present in grid.
  headerText: string;
  // Property key used for displaying data ex: data[displayKey]
  displayKey: string;
  // Order column to be displayed.
  displayOrder: number;
  // Dynamic named column
  dynamic?: boolean;
  // Array of classes for the header
  headerClasses?: string[];
  // Array of classes for row
  bodyClasses?: BodyClasses[];
  columnSize?: ColumnSizes;
  // Future functionality. Allow for sorting
  sortable?: boolean;
  // Future functionality. Allow for filterting.
  filterable?: boolean;
  filterConfig?: FilterConfiguration;
  // Method to parse the value into the display format
  formatter?: (value: any, data?: any) => string;
  // If the value will be a router link
  routerLink?: string;
  sticky?: boolean;
  displayType?: ColumnDisplayType;
  cellClickEvent?: (value: any) => void;
  cellActionButton?: CellActionButton;
  selectAllMenuOptions?: OverflowMenuOptions[];
  selectAllMenuClicked?: (value: any, data: any) => void;
  overflowMenuOptions?: OverflowMenuOptions[];
  overflowMenuClicked?: (value: any, data: any) => void;
  status_icon?: (value: any, data?: any) => string;
  show_icon_condition?: (value: any, data?: any) => boolean;
  icon_class?: (value: any, data?: any) => string;
  icon_tooltip?: (value: any, data?: any) => string;
  bold_condition?: (value: any, data?: any) => boolean;
}
