import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  LocationService,
  LocationDetailsViewModel,
  LocationDetailsViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class LocationsWrapperService extends BaseWrapperService {
  constructor(private service: LocationService, private store: Store<any>) {
    super(store);
  }

  apiV1LocationAllPost(pagedModelRequest?: PagedModelRequest): Observable<LocationDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1LocationAllDetailPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1LocationIdGet(id: string): Observable<LocationDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1LocationIdGet(id, dataSetId)));
  }
  apiV1LocationDetailsIdGet(id: string): Observable<LocationDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1LocationDetailsIdGet(id, dataSetId)));
  }
  private cache: Observable<string>[] = [];
  apiV1LocationIdGetFromCache(id: string): Observable<LocationDetailsViewModel> {
    if (!this.cache[id]) {
      this.cache[id] = this.apiV1LocationIdGet(id).pipe(shareReplay());
    }
    return this.cache[id];
  }

  apiV1LocationAddPost(requestBody): Observable<LocationDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1LocationAddPost(dataSetId, requestBody)));
  }

  apiV1LocationPatchIdPatch(id: string, operation): Observable<LocationDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1LocationPatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1LocationIdDelete(id: string): Observable<LocationDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1LocationIdDelete(id, dataSetId)));
  }
}
