import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable, of } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { CodeWrapperService } from '../service-wrappers/code-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisCodesLookupService extends ApiAutoCompleteService {
  constructor(private service: CodeWrapperService) {
    super();
  }
  diagnosisCodeEmpty$ = this.service
    .apiV1CodeCodetypePost('DiagnosisCode', { generalSearch: '', pageSize: 25 })
    .pipe(shareReplay({bufferSize: 1, refCount: true}));

  mapRow(result) {
    return {
      value: result.id,
      label: `${result.code} - ${result.name}`,
      shortLabel:result.code
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.diagnosisCodeEmpty$.pipe(map((results) => results.data.filter(d => !d.inactive).map(this.mapRow)));
    } 
    return this.service
      .apiV1CodeCodetypePost('DiagnosisCode', { generalSearch: value, pageSize: 200 })
      .pipe(map((results) => results.data.map(this.mapRow)));
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1CodeCodetypeIdGet('DiagnosisCode', id).pipe(map(this.mapRow));
  }
}
