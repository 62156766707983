import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ImplementationViewModel } from 'data';
import { Observable } from 'rxjs';
import { BaseWrapperService } from './base-wrapper.service';
import { ImplementationService } from 'data';
import { switchMap, take, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ImplementationWrapperService extends BaseWrapperService {

  constructor(private service: ImplementationService, private store: Store<any>) { 
    super(store);
  }

  datasetId = null;
  apiV1ImplementationGet(): Observable<ImplementationViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        this.datasetId = dataSetId;
        return this.service.apiV1ImplementationGet(dataSetId);
      })
    );
  }

  apiV1ImplementationCompleteStepCodePost(stepCode): Observable<ImplementationViewModel> {
        return this.service.apiV1ImplementationCompleteStepCodePost(stepCode, this.datasetId);
  }

  apiV1ImplementationPatchPatch(id: string, operation): Observable<ImplementationViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ImplementationPatchPatch(dataSetId, operation))
    );
  }

}
