import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AddScheduledJobViewModel, AddScheduledReportViewModel, Operation, PagedModelRequest, ScheduledJobService } from 'data';
import { ScheduledJobDetailsViewModel } from 'projects/data/src/lib/model/scheduledJobDetailsViewModel';
import { ScheduledJobHistoryViewModelPagedModelResponse } from 'projects/data/src/lib/model/scheduledJobHistoryViewModelPagedModelResponse';
import { ScheduledReportExecutionViewModelPagedModelResponse } from 'projects/data/src/lib/model/scheduledReportExecutionViewModelPagedModelResponse';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
import { BaseWrapperService } from './base-wrapper.service';
@Injectable({
  providedIn: 'root'
})
export class ScheduledJobWrapperService extends BaseWrapperService {
  constructor(private service: ScheduledJobService, private store: Store<any>) {
    super(store);
  }
  apiV1ScheduledJobDetailsIdGet(id: string): Observable<any> {
    return this.store
      .pipe(select(getSelectedDataSetId))
      .pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobDetailsIdGet(id, dataSetId)));
  }

  apiV1ScheduledJobAllDetailPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ScheduledJobAllDetailPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1ScheduledJobAddPost(addScheduledJobViewModel?: AddScheduledJobViewModel): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ScheduledJobAddPost(dataSetId, addScheduledJobViewModel))
    );
  }
  apiV1ScheduledJobPatchIdPatch(id: string, operation?: Array<Operation>): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => 
      this.service.apiV1ScheduledJobPatchIdPatch(id, dataSetId, operation)
    )
    );
  }
  apiV1ScheduledJobHistoryPost(pagedModelRequest?: PagedModelRequest): Observable<ScheduledJobHistoryViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobHistoryPost(dataSetId, pagedModelRequest)));
  }
  apiV1ScheduledJobDownloadIdPost(id: string): Observable<Blob> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobDownloadIdPost(id, dataSetId)));
  }
  apiV1ScheduledJobDownloadFileIdPost(id: string): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobDownloadFileIdPost(id, dataSetId)));
  }
  apiV1ScheduledJobAddReportPost(addScheduledReportViewModel: AddScheduledReportViewModel): Observable<ScheduledJobDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobAddReportPost(dataSetId, addScheduledReportViewModel)));
  }

  apiV1ScheduledJobReportHistoryPost(pagedModelRequest?: PagedModelRequest): Observable<ScheduledReportExecutionViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobReportHistoryPost(dataSetId, pagedModelRequest)));
  }

  apiV1ScheduledJobIdDelete(id: string): Observable<any> {
    return this.store
      .pipe(select(getSelectedDataSetId))
      .pipe(switchMap((dataSetId) => this.service.apiV1ScheduledJobIdDelete(id, dataSetId)));
  }

}
