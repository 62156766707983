/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationDetailsViewModel } from './locationDetailsViewModel';
import { FinancialClassViewModel } from './financialClassViewModel';
import { InsuranceViewModel } from './insuranceViewModel';
import { DatasetViewModel } from './datasetViewModel';
import { GuarantorDetailsViewModel } from './guarantorDetailsViewModel';
import { VisitDetailsViewModel } from './visitDetailsViewModel';

export interface PatientDetailsViewModel {
  id?: string;
  datasetId?: string;
  guarantorId?: string;
  accountNumber?: number;
  readonly accountNumberDisplay?: string | null;
  lastName?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  suffix?: string | null;
  birthDate?: string;
  sex?: string | null;
  ssn?: string | null;
  medicalRecordNumber?: string | null;
  admissionDate?: string | null;
  dischargeDate?: string | null;
  deathDate?: string | null;
  defaultInjuryDate?: string | null;
  defaultInjuryEmploymentCode?: string | null;
  defaultAccidentType?: string | null;
  defaultLocationId?: string | null;
  defaultAuthNumber?: string | null;
  defaultHoldInsuranceBilling?: boolean | null;
  defaultHoldPatientBilling?: boolean | null;
  printFirstReport?: boolean;
  militaryBranch?: string | null;
  militaryDutyStatus?: string | null;
  maritalStatus?: string | null;
  employmentStudentStatus?: string | null;
  primaryDiagnosisCodeId?: string | null;
  secondaryDiagnosisCodeId?: string | null;
  tertiaryDiagnosisCodeId?: string | null;
  quarternaryDiagnosisCodeId?: string | null;
  primaryInsurance?: InsuranceViewModel;
  secondaryInsurance?: InsuranceViewModel;
  tertiaryInsurance?: InsuranceViewModel;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  zip?: string | null;
  countryCode?: string | null;
  homePhone?: string | null;
  workPhone?: string | null;
  mobilePhone?: string | null;
  email?: string | null;
  useAlternateMailingAddress?: boolean;
  alternateName?: string | null;
  alternateAddress1?: string | null;
  alternateAddress2?: string | null;
  alternateCity?: string | null;
  alternateStateCode?: string | null;
  alternateZip?: string | null;
  alternateCountryCode?: string | null;
  employerName?: string | null;
  employerAddress1?: string | null;
  employerAddress2?: string | null;
  employerCity?: string | null;
  employerStateCode?: string | null;
  employerZip?: string | null;
  employerCountryCode?: string | null;
  latestProcedureTransaction?: string | null;
  lastPaymentDate?: string | null;
  lastPatientPaymentDate?: string | null;
  totalBalanceDue?: number;
  totalBalanceAged30?: number;
  totalBalanceAged60?: number;
  totalBalanceAged90?: number;
  totalBalanceAged120?: number;
  totalBalanceAged150?: number;
  totalBalanceAgedOver150?: number;
  monthsAtZeroBalance?: number;
  latestDelinquency?: number;
  patientBalance?: number;
  insuranceBalance?: number;
  lastStatementDate?: string | null;
  holdStatement?: boolean;
  statementHoldReason?: string | null;
  statementHoldDate?: string | null;
  financialClassId?: string;
  bannerMessage?: string | null;
  letterId?: string | null;
  financialClass?: FinancialClassViewModel;
  dataset?: DatasetViewModel;
  latestVisit?: VisitDetailsViewModel;
  defaultLocation?: LocationDetailsViewModel;
  generateStatementOnDemand?: boolean | null;
  holdDelinquencyCodeOnDemand?: boolean | null;
  visits?: Array<VisitDetailsViewModel> | null;
  guarantor?: GuarantorDetailsViewModel;
}
