import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'components/shared/modules';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { getBaseHref } from 'src/app/core/utilities/runtime.utils';
import { Configuration } from 'projects/data/src/lib/configuration';
import { APP_BASE_HREF } from '@angular/common';
import { ImaginePayAuthModule, Configuration as ImaginePayConfiguration } from 'imagine-pay-api';
import { MsalBroadcastService, MsalGuard, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { SecurityModule } from './features/security/security.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule, 
    BrowserAnimationsModule,
    CoreModule,
    MaterialModule,
    SharedModule,
    MsalModule,
    ImaginePayAuthModule.forRoot(environment.imaginePayConfig)
  ],
  providers: [
    {
      provide: Configuration,
      useFactory: () =>
        new Configuration({
          basePath: environment.apiConfig.baseUrl,
          credentials: { Bearer: 'Bearer' }
        }),
      multi: false
    },
    {
      provide: ImaginePayConfiguration,
      useFactory: () =>
        new Configuration({
          basePath: environment.imaginePayConfig.baseUrl,
          credentials: { Bearer: 'Bearer' }
        }),
      multi: false
    },
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref
    },
    MsalService,
    MsalGuard, 
    MsalBroadcastService,
    SecurityModule
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {
  
}
