/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UploadSummaryViewModel {
  id?: string;
  datasetId?: string;
  sendingFacilityId?: string | null;
  sendingFacilityName?: string | null;
  originalName?: string | null;
  totalPatients?: number;
  demographicErrors?: number;
  totalProcedures?: number;
  procedureErrors?: number;
  totalPatientsPosted?: number;
  totalPatientsUpdated?: number;
  totalDemoImportErrors?: number;
  totalProceduresPosted?: number;
  totalProcedureImportError?: number;
  totalNonPost?: number;
  totalChargeCentral?: number;
  note?: string | null;
  createdBy?: string | null;
  createdDate?: string;
}
