import { APP_BASE_HREF } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DialogService } from '@core/services/dialog.service';
import { NotificationService } from '@core/services/notification.service';
import { FeeScheduleWrapperService } from '@core/services/service-wrappers/fee-schedule-wrapper.service';
import { getSelectedDataSetId } from '@core/state/data-set/data-set.selectors';
import { environment } from '@environment/environment';
import { Store } from '@ngrx/store';
import { BASE_PATH, FeeScheduleService, UploadService } from 'data';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FeeSchedulesDialogComponent } from '../fee-schedules-dialog/fee-schedules-dialog.component';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  saving;
  data;
  basePath;
  uploaded = false;

  constructor(
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private store: Store<any>,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Inject(APP_BASE_HREF) private baseHref: string,
    public dialog: MatDialog,
    private router:Router,
    private feeScheduleService: FeeScheduleService,
    private feeScheduleWrapperService: FeeScheduleWrapperService
  ) {
    this.basePath = basePath;
    baseHref = environment.apiConfig.baseUrl;
  }

  private sub$: Subscription;
  url: string;
  dataSetId$ = this.store.select(getSelectedDataSetId).pipe(filter((cId) => !!cId));

  ngOnInit(): void {
    this.dataSetId$.pipe(take(1)).subscribe((dataSetId) => {
      const credential = this.feeScheduleService.configuration.lookupCredential('Bearer');

      this.data = {
        title: 'Select File to Upload',
        subTitle: 'Files should be in CSV format.',
        uploadUrl: `${environment.apiConfig.baseUrl}/api/v1/fee-schedule/upload`,
        httpRequestHeaders: credential
          ? {
            Authorization: credential,
            DatasetId: dataSetId
          }
          : {},
  
        uploadAllLabel: 'Upload File',
        maxFiles: 1,
        allowedExtensions: ['csv'],
      };
    });
  }

  uploadAction(data) {
    this.openFeeScheduleModal(data[0].id);
  }

  openFeeScheduleModal(feeScheduleId: string) {
    const dialog = this.dialog.open(FeeSchedulesDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { feeScheduleId, upload: true },
      height: '1000px',
      width:'1500px'
    });

    dialog.afterClosed().subscribe((result) => {
      this.dialog.closeAll();
    });
  }
}
