/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DeniedChargesProcedureTransactionAdjustmentViewModel } from './deniedChargesProcedureTransactionAdjustmentViewModel';

export interface AddDeniedChargesWriteOffViewModel {
  datasetId?: string;
  patientId?: string;
  visitId?: string;
  adjustmentCodeId?: string;
  totalAdjustmentAmount?: number;
  procedureTransactionAdjustments?: Array<DeniedChargesProcedureTransactionAdjustmentViewModel> | null;
}
