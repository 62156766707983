import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AddNationalDrugCodeViewModel, NationalDrugCodeDetailsViewModel, NationalDrugCodeService } from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class NationalDrugCodeWrapperService extends BaseWrapperService {

  constructor(private service: NationalDrugCodeService, private store: Store<any>)
  {
  super(store);
  }
  apiV1NationalDrugCodeDetailsIdGet(id: string): Observable<NationalDrugCodeDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1NationalDrugCodeDetailsIdGet(id, dataSetId)));
  }
  apiV1NationalDrugCodeByProcedureIdIdGet(id: string): Observable<NationalDrugCodeDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1NationalDrugCodeByProcedureIdIdGet(id, dataSetId)));
  }
  apiV1NationalDrugCodePatchIdPatch(id: string, operation): Observable<NationalDrugCodeDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1NationalDrugCodePatchIdPatch(id, dataSetId, operation)));
  }

  apiV1NationalDrugCodeAddPost(addModel: AddNationalDrugCodeViewModel): Observable<NationalDrugCodeDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1NationalDrugCodeAddPost(dataSetId, addModel)));
  }
  apiV1NationalDrugCodeIdDelete(id: string): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1NationalDrugCodeIdDelete(id, dataSetId)));
  }
}
