<header class="sub-page-layout__header locations__header">
  <div class="locations__header-text h2 h2--semi-bold">Locations</div>
  <button mat-flat-button color="primary" class="locations__add-item" (click)="uploadCodes()">
    <mat-icon>file_upload</mat-icon> Upload Locations
  </button>
  <button mat-flat-button color="primary" class="locations__add-item" (click)="addLocation()">
    <mat-icon>add_circle_outline</mat-icon> Add Location
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No Locations Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="locations__footer">
  <lib-button-loading color="primary" class="locations__button" (clickEvent)="next()">Next </lib-button-loading>
</footer>
