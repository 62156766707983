import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  AsyncValidatorFn,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DatasetWrapperService } from '../../../../../../../src/app/core/services/service-wrappers/dataset-wrapper.service';
import { DataSet } from '../../../core/sidebar/data-set-selector';

type ValidatorResponse = { [key: string]: boolean } | null;
type ValidatorResponseString = { [key: string]: string } | null;

export function providerAddressValidator(control: UntypedFormControl): ValidatorResponse {
  let providerAddress = control.value;
  if (!providerAddress) {
    return null;
  } else {
    if (
      providerAddress.toLowerCase().includes('po box') ||
      providerAddress.toLowerCase().includes('p.o. box') ||
      providerAddress.toLowerCase().includes('p o box') ||
      providerAddress.toLowerCase().includes('post office box')
    ) {
      return { addressProvider: true };
    } else {
      return null;
    }
  }
}

export function spaceValidator(control: UntypedFormControl): ValidatorResponse {
  let input = control.value;
  let regex = new RegExp(/^\S+(?: \S+)*$/);
  if (!input) {
    return null
  } else if (!regex.test(input)) {
    return { startsWithSpace: true };
  }
}

export function numberValidator(control: UntypedFormControl): ValidatorResponse {
  let input = control.value;
  let regex = new RegExp(/^[0-9]*$/);
  if (!input) {
    return null;
  } else if (!regex.test(input)) {
    return { nonNumber: true };
  }
}

export function ndcCodeValidator(control: UntypedFormControl): ValidatorResponse {
  let code: string = control.value;
  let regex = new RegExp(/^\d{5}-?\d{4}-?\d{2}$/);
  let regexNumber = new RegExp('^[0-9]+$');
  if (!code) {
    return null;
  } else if (regexNumber.test(code) && code.length === 11) {
    let result = code.slice(0, 5) + '-' + code.slice(5, 9) + '-' + code.slice(9);
    control.setValue(result);
    code = control.value;
  } else if (!regex.test(code)) {
    return { ndcCode: true };
  }
}

export function dateValidator(control: UntypedFormControl): ValidatorResponse {
  let dob = control.value;
  let regex = new RegExp(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/g);
  if (!dob) {
    return null;
  } else if (!regex.test(dob)) {
    return { invalidDate: true };
  } else {
    if (moment(dob, 'MM/DD/YYYY').isValid()) {
      return null;
    } else {
      return { invalidDate: true };
    }
  }
}

export function ndcAmountValidator(procedureAmount: Number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let amount = Number(control.value);
    if (!amount) {
      return null;
    } else {
      if (amount !== procedureAmount) {
        return {
          invalidAmount: true
        };
      } else {
        return null;
      }
    }
  };
}

export function percentageValidator(control: UntypedFormControl): ValidatorResponse {
  let percentage: number = control.value;
  if (!percentage) {
    return null;
  } else if (percentage < 0) {
    return { percentError: true };
  }
}

export function acronymValidator(datasets: any[]) {
  return (control: UntypedFormControl): ValidatorResponse => {
    let input = control.value;
    let badDatasets = [];
    if (input === null) {
      return null;
    } else {
      if ((datasets?.length > 0 && datasets !== null) || undefined) {
        datasets?.forEach((dataset) => {
          dataset.acronymExists = false;
          if (input === dataset.acronym) {
            dataset.acronymExists = true;
            badDatasets.push(dataset);
          } else {
            dataset.acronymExists = false;
          }
        });
        if (badDatasets.length > 0) {
          return { invalidAcronym: true };
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  };
}

export function twoDecimalValidator(control: UntypedFormControl): ValidatorResponse {
  let decimal: string = String(control.value);
  decimal = decimal.replace(/\b0+(0\.\d+)/g, '');
  let regexOneDecimal = new RegExp('^[0-9]{1,5}?.[0-9]{1}$');
  let regex = new RegExp('^[0-9]{1,5}.[0-9]{0,2}$');
  let regexNumber = new RegExp('^[0-9]+$');
  if (decimal === null) {
    return null;
  } else if (regexNumber.test(decimal) && decimal.length > 2) {
    if (decimal.length < 8) {
      let result = decimal.slice(0, decimal.length - 2) + '.' + decimal.slice(decimal.length - 2);
      control.setValue(result);
      decimal = control.value;
    } else {
      return { twoDecimal: true };
    }
  } else if (regexNumber.test(decimal) && decimal.length === 1) {
    control.setValue(decimal + '.00');
    decimal = control.value;
  } else if (regexOneDecimal.test(decimal)) {
    control.setValue(decimal + '0');
    decimal = control.value;
  } else if (control.value && !regex.test(decimal) && !regexNumber.test(decimal)) {
    return { twoDecimal: true };
  } else {
    return null;
  }
}

export function taxonomyPatternValidator(control: UntypedFormControl): ValidatorResponse {
  let taxonomy = control.value;
  if (!taxonomy) {
    return null;
  } else {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (taxonomy.toUpperCase().indexOf('X') !== 9 || !regex.test(taxonomy)) {
      return { taxonomyPattern: true };
    } else {
      return null;
    }
  }
}

export function passwordValidator(control: UntypedFormControl): ValidatorResponse {
  let password = control.value;
  if (!password) {
    return null;
  } else {
    const specialRegex = new RegExp(/[$-/:-?{-~!"^_@`\[\]]/g);
    const lowerRegex = new RegExp('^(?=.*[a-z])');
    const upperRegex = new RegExp('^(?=.*[A-Z])');
    const numberRegex = new RegExp('^(?=.*[A-Z])');
    const doubleRegex = new RegExp(/([a-zA-Z0-9])\1{2,}/g);

    if (
      !specialRegex.test(password) ||
      !lowerRegex.test(password) ||
      !upperRegex.test(password) ||
      !numberRegex.test(password) ||
      doubleRegex.test(password) ||
      password.length < 12
    ) {
      return { invalidPassword: true };
    } else {
      return null;
    }
  }
}

export function zipCodePatternValidator(control: UntypedFormControl): ValidatorResponse {
  var zip = control.value?.toString().replace(/[-]/g, '');

  if (!zip) {
    return null;
  } else {
    const regex = new RegExp('^([0]|[0-9]{5,9})$');
    // clean string must be 5 or 9 chars and all digits
    if ((zip.length != 5 && zip.length != 9) || !regex.test(zip)) {
      return { zipPattern: true };
    } else {
      return null;
    }
  }
}

export function extendedZipCodePatternValidator(control: UntypedFormControl): ValidatorResponse {
  var zip = control.value?.toString().replace(/[-]/g, '');

  if (!zip) {
    return null;
  } else {
    const regex = new RegExp('^([0]|[0-9]{9})$');
    // clean string must be 5 or 9 chars and all digits
    if (zip.length != 9 || !regex.test(zip)) {
      return { extendedZipPattern: true };
    } else {
      return null;
    }
  }
}

export function moneyFormatValidator(control: UntypedFormControl): ValidatorResponse {
  let money = control.value;
  let regex = new RegExp(/^\d+(\.\d{1,2})?$/);
  if (!money) {
    return null;
  }

  if (!regex.test(money)) {
    return { money: true };
  } else {
    return null;
  }
}

export function moneyTextFormatValidator(control: UntypedFormControl): ValidatorResponse {
  let money = control.value;

  let regexTwo = new RegExp('^[0-9]{1,7}.[0-9]{2}$');
  let regexOne = new RegExp('^[0-9]{1,7}.[0-9]{1}$');
  let regexNumber = new RegExp('^[0-9]{1,7}$');
  if (!money) {
    return null;
  } else if (regexNumber.test(money)) {
    let result = money + '.' + '00';
    control.setValue(result);
  } else if (regexOne.test(money)) {
    let result = money + '0';
    control.setValue(result);
  } else if (!regexTwo.test(money)) {
    return { money: true };
  } else {
    return null;
  }
}

export function taxonomyLengthValidator(control: UntypedFormControl): ValidatorResponse {
  let taxonomy = control.value;
  if (!taxonomy) {
    return null;
  } else {
    if (taxonomy.length != 10) {
      return { taxonomyLength: true };
    } else {
      return null;
    }
  }
}

export function phoneValidator(control: UntypedFormControl): ValidatorResponse {
  if (!control.value) {
    return null;
  }
  const length = control.value.replace(/\D/g, '').length;
  return length < 10 || length > 14
    ? {
        phone: true
      }
    : null;
}

export function ssnValidator(control: UntypedFormControl): ValidatorResponse {
  var ssnValue = control.value;

  if (!control.value) {
    return null;
  } else {
    ssnValue = ssnValue.replaceAll('-', '');
  }

  const length = ssnValue.length;
  return length !== 9
    ? {
        ssn: true
      }
    : null;
}
export function dobValidator(control: UntypedFormControl): ValidatorResponse {
  var dobValue = control.value;
  var oldDate = new Date('01/01/1900');
  if (dobValue < oldDate) {
    return {
      pastDOB: true
    };
  } else {
    return null;
  }
}
export function insuranceEndDateValidator(control: UntypedFormControl): ValidatorResponse {
  if (control) {
    return {
      insuranceEndDate: true
    };
  } else {
    return null;
  }
}

const MAX_SQL_INTEGER = 2147483647;
const MIN_SQL_INTEGER = -2147483647;
function isInteger(value) {
  return parseFloat(value) === parseInt(value, 0) && !isNaN(value);
}

export function sqlIntegerValidator(control: UntypedFormControl): ValidatorResponse {
  if (!control.value) {
    return null;
  }
  const value = parseInt(control.value?.toString().replace(/[^0-9.]/g, ''), 0);
  return !isInteger(value) || value > MAX_SQL_INTEGER || value < MIN_SQL_INTEGER
    ? {
        notInteger: true
      }
    : null;
}

/*
    API is currently using decimal(18, 4).
  */
export function sqlDecimalValidator(control: UntypedFormControl): ValidatorResponse {
  if (!control.value) {
    return null;
  }

  const splitValue = control.value
    ?.toString()
    .replace(/[^0-9.]/g, '')
    .split('.');
  const wholeLengthValid = (splitValue[0]?.length ?? 0) < 19;
  const decimalLengthValid = (splitValue[1]?.length ?? 0) < 5;

  return isNaN(parseFloat(control.value)) || !wholeLengthValid || !decimalLengthValid
    ? {
        notDecimal: true
      }
    : null;
}

export function portValidator(control: UntypedFormControl): ValidatorResponse {
  if (!control.value) {
    return null;
  }
  const value = parseInt(control.value);

  return !isInteger(value) || value > 65535 || value < 1
    ? {
        listenAtPort: true
      }
    : null;
}

export function ipValidator(control: UntypedFormControl): ValidatorResponse {
  if (!control.value) {
    return null;
  }

  const ipRegex = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$/;
  return !ipRegex.test(control.value)
    ? {
        listenAtIp: true
      }
    : null;
}

export function noPriorDateValidator(control: UntypedFormControl): ValidatorResponse {
  let scheduledDate: Date = control.value;
  return scheduledDate && scheduledDate < new Date(new Date().toDateString()) ? { PriorDateNotAllowed: true } : null;
}

export function nullCodeValidator(control: UntypedFormControl): ValidatorResponse {
  if (!control.value) {
    return {
      nonNull: true
    };
  } else {
    return null;
  }
}

export function noAsterisksValidator(control: UntypedFormControl): ValidatorResponse {
  let asterisks = control.value;
  let regex = new RegExp(/\*/);
  if (!asterisks) {
    return null;
  }

  if (regex.test(asterisks)) {
    return { noAsterisks: false };
  } else {
    return null;
  }
}

export function maxIntValue(control: UntypedFormControl): ValidatorResponse {
  if (control.value > Number.MAX_SAFE_INTEGER) {
    return {exceedsMaxInt: true};
  } else {
    return null;
  }
}
