import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Document360Service } from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class Doccument360WrapperService extends BaseWrapperService {

  constructor(private service: Document360Service, private store: Store<any>) { super(store) }

  apiV1Doc360Launch360Get(): Observable<string> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1Doc360Launch360Get(dataSetId))
    )
  }
}
