import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  AddPatientViewModel,
  MergePatientViewModel,
  Operation,
  PagedModelRequest,
  PatientDetailsViewModel,
  PatientDetailsViewModelPagedModelResponse,
  PatientLedgerRequestModel,
  PatientNumberViewModel,
  PatientReceiptRequestModel,
  PatientSearchViewModelPagedModelResponse,
  PatientService,
  PatientViewModel,
  PatientViewModelPagedModelResponse,
  ZeroBalanceRequestViewModel,
  VisitViewModelPagedModelResponse
} from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class PatientWrapperService extends BaseWrapperService {
  constructor(private service: PatientService, private store: Store<any>) {
    super(store);
  }
  apiV1PatientPatchIdPatch(id: string, operation?: Array<Operation>): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PatientPatchIdPatch(id, dataSetId, operation))
    );
  }
  apiV1PatientAddPost(addPatientViewModel?: AddPatientViewModel): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PatientAddPost(dataSetId, addPatientViewModel))
    );
  }
  apiV1PatientAllPost(pagedModelRequest?: PagedModelRequest): Observable<PatientViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PatientAllPost(dataSetId, pagedModelRequest))
    );
  }
  apiV1PatientAllDetailPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<PatientDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PatientAllDetailPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1PatientIdGet(id: string): Observable<PatientViewModel> {
      return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientIdGet(id, dataSetId)));
  }


  apiV1PatientDetailsIdGet(id: string): Observable<PatientDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientDetailsIdGet(id, dataSetId)));
  }


  apiV1PatientMergePatientPost(patientMerge: MergePatientViewModel): Observable<PatientDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientMergePatientPost(dataSetId, patientMerge)));
  }


  apiV1PatientPurgeAccounts(request: ZeroBalanceRequestViewModel): Observable<PatientDetailsViewModel[]> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientPurgeAccountsPost(dataSetId, request)));
  }

  apiV1PatientPatientNumberPost(): Observable<PatientNumberViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientPatientNumberPost(dataSetId)));
  }

  apiV1PatientSearchPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<PatientSearchViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PatientSearchPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1PatientIdVisitsPost(id: string, pagedModelRequest?: PagedModelRequest
  ): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientIdVisitsPost(id, dataSetId, pagedModelRequest))
    );
  }

  apiV1PatientPatientNumberPatientnumberGet(accountNumber: number): Observable<PatientDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientPatientNumberPatientnumberGet(accountNumber, dataSetId)));
  }

  apiV1PatientValidateAccountNumberPost(accountNumber: number): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientValidateAccountNumberPost(dataSetId, accountNumber)));
  }

  apiV1PatientUpdateBannerIdPost(id: string, requestBody): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientUpdateBannerIdPost(id, dataSetId, requestBody)));
  }
 
  apiV1PatientReceiptPdfPost(request: PatientReceiptRequestModel): Observable<Blob> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientReceiptPdfPost(dataSetId, request)));
  }

  apiV1PatientLedgerPdfPost(request: PatientLedgerRequestModel): Observable<Blob> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1PatientLedgerPdfPost(dataSetId, request)));
  }
}
