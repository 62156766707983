import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  FinancialClassService,
  FinancialClassViewModel,
  FinancialClassViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class FinancialClassWrapperService extends BaseWrapperService {
  constructor(private service: FinancialClassService, private store: Store<any>) {
    super(store);
  }

  apiV1FinancialClassAllPost(
    pagedModelRequest?: PagedModelRequest
  ): Observable<FinancialClassViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1FinancialClassAllPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1FinancialClassIdGet(id: string): Observable<FinancialClassViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1FinancialClassIdGet(id, dataSetId)));
  }

  apiV1FinancialClassAddPost(requestBody): Observable<FinancialClassViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1FinancialClassAddPost(dataSetId, requestBody))
    );
  }

  apiV1FinancialClassPatchIdPatch(id: string, operation): Observable<FinancialClassViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1FinancialClassPatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1FinancialClassIdDelete(id: string): Observable<FinancialClassViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1FinancialClassIdDelete(id, dataSetId)));
  }
}
