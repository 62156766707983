<ng-container [ngSwitch]="type" *ngIf="parent">
  <ng-container *ngSwitchCase="'noInput'"></ng-container>
  <!-- Empty container -->
  <div [class]="classes" *ngSwitchCase="'empty'"></div>

  <!-- Label container -->
  <div [class]="classes" *ngSwitchCase="'label'">{{ label }}</div>

  <!-- Radio buttons field type -->
  <div class="lib-form-field-radio" [class]="classes" *ngSwitchCase="'radio'">
    <span *ngIf="label" class="mat-form-field-label-wrapper">
      <label class="mat-form-field-label">{{ label }}</label>
    </span>
    <mat-radio-group [formControl]="control" [id]="name">
      <mat-radio-button
        *ngFor="let option of availableOptions; let i = index; trackBy: trackByValue"
        [value]="option.value"
        id="name-radio{{ i }}"
      >
        {{ option.label }}
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Checkbox field type -->
  <div class="lib-form-field-checkbox" [class]="classes" [formGroup]="parent" *ngSwitchCase="'checkbox'">
    <mat-checkbox
      [id]="(focusId ?? name) + ididx"
      [disabled]="isReadOnly"
      [formControlName]="name"
      (change)="checkboxChanged($event)"
      [checked]="checked"
    >
      <span class="lib-form-field-checkbox__label">{{ label }}</span>
    </mat-checkbox>
  </div>

  <!-- Main field type -->
  <mat-form-field *ngSwitchDefault appearance="outline" [class]="classes" [formGroup]="parent" [id]="name">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-label *ngIf="matLabel" class="mat-label">{{ matLabel }}</mat-label>
    <span matPrefix class="money" *ngIf="type === 'money'">$&nbsp;</span>
    <ng-container [ngSwitch]="type">
      <!-- Date field type -->
      <ng-container *ngSwitchCase="'date'">
        <input
          matInput
          class="input-date"
          [id]="(focusId ?? name) + ididx"
          [formControlName]="name"
          [matDatepicker]="datePickerId"
          [attr.placeholder]="placeholder"
          [min]="min"
          [max]="max"
          [required]="isRequired"
          [readonly]="isReadOnly"
          [disabled]="disabled"
          (dateChange)="datepickerDateChange($event)"
          (focus)="datePickerId.open()"
        />
        <mat-datepicker-toggle tabindex="-1" class="mat-datepicker-toggle__fix" [for]="datePickerId">
        </mat-datepicker-toggle>
        <mat-datepicker #datePickerId></mat-datepicker>
      </ng-container>

      <!-- Date Range field type -->
      <ng-container *ngSwitchCase="'dateRange'">
        <!-- <input
        matInput
        [id]="focusId"
        [formControlName]="name"
        [matDatepicker]="datePickerId"
        [attr.placeholder]="placeholder"
        [min]="min"
        [max]="max"
        [required]="isRequired"
        [readonly]="isReadOnly"
        (focus)="datePickerId.open()"
      /> -->
        <mat-date-range-input
          [formGroup]="parent"
          class="input"
          [class.disabled]="dateEmpty"
          [rangePicker]="datePickerId"
          [min]="min"
          [max]="max"
          [required]="isRequired"
          (dateChange)="dateRangeChanged($event)"
        >
          <input matStartDate [formControlName]="name + 'Start'" (dateChange)="dateRangeChanged($event, 'start')" />
          <input matEndDate [formControlName]="name + 'End'" (dateChange)="dateRangeChanged($event, 'end')" />
        </mat-date-range-input>
        <mat-datepicker-toggle tabindex="-1" class="mat-datepicker-toggle__fix" [for]="datePickerId">
        </mat-datepicker-toggle>
        <mat-date-range-picker #datePickerId></mat-date-range-picker>
      </ng-container>

      <!-- Select field type -->
      <ng-container *ngSwitchCase="'select'">
        <mat-select
          *ngIf="multiple"
          [id]="focusId"
          [formControlName]="name"
          [multiple]="true"
          [required]="isRequired"
          [disabled]="isReadOnly"
          (selectionChange)="selectSelectionChanged($event)"
        >
          <mat-checkbox
            *ngIf="!excludeSelectAllOption"
            class="lib-form-field-select-all"
            (change)="multipleSelectAll($event, 'select')"
            ><span class="lib-form-field-checkbox__label">-- Select All --</span></mat-checkbox
          >
          <mat-select-trigger *ngIf="customSelectedDisplay">
            {{ customSelectedDisplay }}
          </mat-select-trigger>
          <mat-option *ngIf="emptyOption" id="emptyOption" [value]="emptyOption.trim()">
            {{ emptyOption.trim() }}
          </mat-option>
          <mat-option *ngFor="let option of availableOptions; trackBy: trackByValue" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <mat-select
          *ngIf="!multiple"
          #select
          [id]="(focusId ?? name) + ididx"
          [formControlName]="name"
          [multiple]="false"
          [required]="isRequired"
          [disabled]="isReadOnly"
          (selectionChange)="selectSelectionChanged($event)"
          (blur)="selectUpdate($event, select)"
        >
          <mat-select-trigger *ngIf="customSelectedDisplay">
            {{ customSelectedDisplay }}
          </mat-select-trigger>
          <mat-option *ngIf="emptyOption" [value]="emptyOption.trim()">{{ emptyOption.trim() }}</mat-option>
          <mat-option *ngFor="let option of availableOptions; trackBy: trackByValue" [value]="option.value">
            {{ option.label }}
          </mat-option>
        </mat-select>
        <button mat-button *ngIf="clearButton && showClear" (click)="clear()" class="clear">
          <mat-icon class="icon">highlight_off</mat-icon>
        </button>
      </ng-container>
      <!--Password field type-->
      <ng-container *ngSwitchCase="'password'">
        <input
          class="password"
          matInput
          [id]="(focusId ?? name) + ididx"
          [placeholder]="placeholder"
          [type]="hide ? 'password' : 'text'"
          [formControlName]="name"
        />
        <mat-icon class="position-relative" (click)="passwordToggle()" matSuffix>
          {{ hide ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </ng-container>
      <!-- Autocomplete field type -->
      <ng-container *ngSwitchCase="'autocomplete'">
        <!-- is being returned as string instead of number but working -->
        <input
          matInput
          #input
          type="text"
          [ngClass]="{ autocomplete: isDelete }"
          [id]="(focusId ?? name) + ididx"
          [formControl]="autocompleteControl"
          [placeholder]="placeholder"
          [matAutocomplete]="auto"
          [readonly]="isReadOnly"
          [required]="isRequired"
          (blur)="autoCompleteUpdate($event)"
          (input)="autoCompleteFilterChange($event)"
          (keyup)="autoCompleteKeyup($event)"
        />
        <button mat-button *ngIf="length === 'lengthOfArray'" (click)="remove(idx)" class="remove">
          <mat-icon class="icon">highlight_off</mat-icon>
        </button>
        <mat-autocomplete
          #auto="matAutocomplete"
          [displayWith]="displayProperty"
          (optionSelected)="autocompleteSelected($event)"
          panelWidth="auto"
        >
          <mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByValue" [value]="option">
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
        <mat-error class="required-error" *ngIf="autocompleteControl.invalid && customRequiredMessage">
          {{ customRequiredMessage }}
        </mat-error>
        <button
          mat-button
          class="clear-autocomplete"
          *ngIf="clearButton && autocompleteControl.value"
          (click)="clear()"
        >
          <mat-icon class="icon">highlight_off</mat-icon>
        </button>
        <input type="hidden" [formControlName]="name" />
      </ng-container>

      <ng-container *ngSwitchCase="'multiSelect'">
        <mat-chip-grid #chipList>
          <mat-chip-row
            *ngFor="let option of selectedOptions; trackBy: trackByValue"
            class="chip"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(option)"
          >
            <div class="chip-text">
              {{ option.shortLabel }}
            </div>
            <div class="cancel">
              <mat-icon class="cancel-icon" matChipRemove *ngIf="removable">cancel</mat-icon>
            </div>
          </mat-chip-row>
          <input
            [placeholder]="placeholder"
            #multiSelectInput
            #matACTrigger="matAutocompleteTrigger"
            [formControl]="multiSelectControl"
            [matAutocomplete]="autoMulti"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          />
          <button mat-icon-button matSuffix class="expand-button" (click)="openAuto(autocompleteTrigger)">
            <mat-icon class="expand-icon">expand_more</mat-icon>
          </button>
        </mat-chip-grid>
        <mat-autocomplete #autoMulti="matAutocomplete" (optionSelected)="selected($event)" autoActiveFirstOption>
          <div class="select-all-div">
            <mat-checkbox *ngIf="!excludeSelectAllOption" class="select-all" (change)="multipleSelectAll($event)"
              ><span class="select-all-label">-- Select All --</span></mat-checkbox
            >
          </div>
          <mat-option *ngFor="let option of filteredOptions | async; trackBy: trackByValue" [value]="option">
            <mat-checkbox class="check" color="primary" [checked]="selectedOptions.indexOf(option) >= 0"></mat-checkbox>
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
      </ng-container>

      <ng-container *ngSwitchCase="'money'">
        <input
          matInput
          libMoneyFormatter
          [id]="(focusId ?? name) + ididx"
          [type]="type"
          [formControlName]="name"
          [attr.placeholder]="placeholder"
          [attr.min]="min"
          [attr.max]="max"
          [readonly]="isReadOnly"
          [required]="isRequired"
        />
      </ng-container>
      <ng-container *ngSwitchCase="'zipCode'">
        <input
          class="input"
          matInput
          [id]="(focusId ?? name) + ididx"
          type="text"
          [formControlName]="name"
          [attr.placeholder]="placeholder"
          [readonly]="isReadOnly"
          [required]="isRequired"
        />
      </ng-container>
      <ng-container *ngSwitchCase="'decimal'">
        <input
          matInput
          libDecimalFormatter
          [decimalCount]="decimalCount"
          [id]="(focusId ?? name) + ididx"
          [type]="type"
          [formControlName]="name"
          [attr.placeholder]="placeholder"
          [attr.min]="min"
          [attr.max]="max"
          [readonly]="isReadOnly"
          [required]="isRequired"
        />
      </ng-container>

      <!-- textbox field type -->
      <ng-container *ngSwitchCase="'textbox'" class="textarea">
        <textarea
          matInput
          [attr.rows]="min"
          [attr.maxlength]="max"
          [id]="(focusId ?? name) + ididx"
          [formControlName]="name"
        ></textarea>
      </ng-container>

      <!-- Default field type -->
      <ng-container *ngSwitchDefault>
        <input
          class="input"
          matInput
          [id]="(focusId ?? name) + ididx"
          [type]="type"
          [placeholder]="placeholder"
          [formControlName]="name"
          [attr.min]="min"
          [attr.max]="max"
          [readonly]="isReadOnly"
          [required]="isRequired"
        />
        <button mat-button *ngIf="clearButton && showClear" (click)="clear()" class="clear">
          <mat-icon class="icon">highlight_off</mat-icon>
        </button>
      </ng-container>

      <ng-container *ngSwitchCase="'time'">
        <input matInput type="time" [id]="focusId" [formControlName]="name" [attr.placeholder]="placeholder" />
      </ng-container>
    </ng-container>
    <ng-content></ng-content>
    <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
    <mat-hint *ngIf="fieldIssues?.updated" class="lib-form-field__update-hint">
      Previous: {{ fieldIssues.updated }}
    </mat-hint>
    <div *ngIf="!customRequiredMessage && parent.status === 'INVALID'">
      <mat-error class="required-error" *ngFor="let error of currentErrors" [innerHTML]="error"></mat-error>
    </div>
  </mat-form-field>
</ng-container>
