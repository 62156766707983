import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AddPaymentViewModel, PaymentService,} from 'data';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';
@Injectable({
  providedIn: 'root'
})
export class PaymentWrapperService extends BaseWrapperService {
  constructor(private service: PaymentService, private store: Store<any>) {
    super(store);
  }

  apiV1PaymentAddPost(addPaymentViewModel: AddPaymentViewModel) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PaymentAddPost(dataSetId, addPaymentViewModel))
    );
  }

  apiV1PaymentConfigPost() {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1PaymentConfigPost(dataSetId))
    );
  }
}
