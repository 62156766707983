import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PagedModelRequest, ProviderService } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { getSelectedDataSetId } from '../../state/data-set/data-set.selectors';
@Injectable({
  providedIn: 'root'
})
export class PhysiciansWrapperService {
  constructor(private service: ProviderService, private store: Store<any>) {}
  apiV1PhysicianIdGet(id: string): Observable<any> {
    return this.store
      .pipe(select(getSelectedDataSetId))
      .pipe(switchMap((dataSetId) => this.service.apiV1ProviderIdGet(id, dataSetId)));
  }

  apiV1PhysicianAllPost(pagedModelRequest?: PagedModelRequest): Observable<any> {
    return this.store
      .pipe(select(getSelectedDataSetId))
      .pipe(switchMap((dataSetId) => this.service.apiV1ProviderAllPost(dataSetId, pagedModelRequest)));
  }
}
