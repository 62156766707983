import { Injectable, Provider } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  ProviderService,
  ProviderDetailsViewModel,
  ProviderDetailsViewModelPagedModelResponse,
  PagedModelRequest
} from 'data';
import { Observable } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class ProvidersWrapperService extends BaseWrapperService {
  constructor(private service: ProviderService, private store: Store<any>) {
    super(store);
  }

  apiV1ProviderAllPost(pagedModelRequest?: PagedModelRequest): Observable<ProviderDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ProviderSearchPost(dataSetId, pagedModelRequest))
    );
  }

  private cache: Observable<string>[] = [];
  apiV1ProviderIdGetFromCache(id: string): Observable<ProviderDetailsViewModel> {
    if (!this.cache[id]) {
      this.cache[id] = this.apiV1ProviderIdGet(id).pipe(shareReplay());
    }
    return this.cache[id];
  }


  apiV1ProviderIdGet(id: string): Observable<ProviderDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProviderIdGet(id, dataSetId)));
  }

  apiV1ProviderDetailsIdGet(id: string): Observable<ProviderDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProviderDetailsIdGet(id, dataSetId)));
  }

  apiV1ProviderAddPost(requestBody): Observable<ProviderDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProviderAddPost(dataSetId, requestBody)));
  }

  apiV1ProviderPatchIdPatch(id: string, operation): Observable<ProviderDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1ProviderPatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1ProviderIdDelete(id: string): Observable<ProviderDetailsViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1ProviderIdDelete(id, dataSetId)));
  }
}
