import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { DialogService } from '@core/services/dialog.service';
import { NotificationService } from '@core/services/notification.service';
import { UserMaintenanceSecurityWrapperService } from '@core/services/service-wrappers/user-maintenance-security-wrapper.service';
import { DialogContent } from 'components';
import { map, take } from 'rxjs/operators';
import { AddEditUserDialogComponent } from './add-edit-user-dialog/add-edit-user-dialog.component';
import { AssignDatasetsDialogComponent } from './assign-datasets-dialog/assign-datasets-dialog.component';
import { AssignRolesDialogComponent } from './assign-roles-dialog/assign-roles-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ImplementationWrapperService } from '@core/services/service-wrappers/implementation-wrapper.service';
import { Store } from '@ngrx/store';
import { ImplementationState } from '../../../implementation/state/implementation.state';
import { Observable, Subscription } from 'rxjs';
import { ImplementationDetailViewModel, ImplementationViewModel } from 'data';
import { selectImplementation } from '../../../implementation/state/implementation.selectors';
import * as ImplementationActions from '../../../implementation/state/implementation.actions';

export interface DataSets {
  name: string;
  email: string;
  roles: string[];
  dataSets: string[];
}

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['action', 'name', 'email', 'roles', 'dataSets'];
  usersList = [];
  loading = false;
  error = false;
  userListError = "";
  public length: number = 0;
  currentPage: number;
  refreshPaginator = false;
  overflowMenuOptions = [
    {
      id: 'edit',
      label: 'Edit User'
    },
    {
      id: 'roles',
      label: 'Assign Roles'
    },
    {
      id: 'datasets',
      label: 'Assign Datasets'
    },
    {
      id: 'delete',
      label: 'Delete User'
    }
  ]

  constructor(
    private dialogService: DialogService,
    public dialog: MatDialog,
    private userMaintenanceSecurityService: UserMaintenanceSecurityWrapperService,
    private notificationService: NotificationService,
    private activatedRoute: ActivatedRoute,
    private implementationService: ImplementationWrapperService,
    private router: Router,
    private store: Store<ImplementationState>) { }

  ngOnInit(): void {
    this.getUserList();
    this.implementationSubscribe();
  }

  getUserList(page = 1, pageSize = 25): void {
    this.loading = true;
    this.userMaintenanceSecurityService
    .apiV1UserPost({
      page,
      pageSize
    }).subscribe((response) => {
        this.refreshPaginator = false;
        this.loading = false;
        this.error = false;
        const sysadmins = this.usersList = response.data.filter(d => d.fullName === 'sysadmin')
        this.usersList = response.data.filter(d => d.fullName !== 'sysadmin');
        this.length = response.metaData.totalItemCount - (sysadmins.length);
      }, (error) => {
        this.loading = false;
        this.error = true;
          
        if (error.status === 403) {
          this.userListError = "Current user is not authorized to view Users."
        }
        else {
          this.userListError = "Error while accessing users.  Please contact support."
        }
      }
      )
  }

  overflowMenuClicked(value, data) {
    if (value === 'delete') {
      this.openDeleteUserModal(data.id);
    } else if (value === 'roles') {
      this.assignRoles(data);
    } else if (value === 'datasets') {
      this.openAssignDatasetsModal(data);
    } else if (value === 'edit') {
      this.addEditUser(data);
    }
  }

  addEditUser(data = null) {
    this.openAddEditUserModal(data);
  }

  assignRoles(data) {
    this.openAssignRolesModal(data);
  }

  openAddEditUserModal(data) {
    const dialog = this.dialog.open(AddEditUserDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { data }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getUserList(this.currentPage);
      }
    });
  }

  openAssignRolesModal(data) {
    const dialog = this.dialog.open(AssignRolesDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { data }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getUserList(this.currentPage);
      }
    });
  }

  openAssignDatasetsModal(data) {
    const dialog = this.dialog.open(AssignDatasetsDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { data }
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        this.getUserList(this.currentPage);
      }
    });
  }


  openDeleteUserModal(userId: string) {
    const modalContent: DialogContent = {
      header: '',
      body: 'Are you sure you want to delete this user?',
      subBody: 'You are about to delete this user, would you like to continue?',
      cancelButtonText: 'Back',
      OKButtonText: 'Confirm'
    };
    this.dialogService.showConfirmDialog(modalContent).subscribe((result) => {
      if (result) {
        this.userMaintenanceSecurityService
          .apiV1UserDeleteUserDelete(userId)
          .pipe(
            map((x: any) => x),
            take(1)
          )
          .subscribe((deleteResult) => {
            this.notificationService.success('User Deleted');
            this.usersList = []
            this.refreshPaginator = true;
            this.getUserList();
          });
      }
    });
  }

  handlePageEvent(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.getUserList(this.currentPage, event.pageSize)
  }

  public isImplementation: boolean = false;
  public gonext: boolean = false;
  public itemCount: number = 0;
  private activatedRouteSub: Subscription;
  private implementation$: Observable<ImplementationViewModel> = this.store.select(selectImplementation);;
  private implementationSub: Subscription;
  private implementation : ImplementationViewModel = null;
  private implementationDetail: ImplementationDetailViewModel = null;
  private nextImplementationDetail: ImplementationDetailViewModel = null;  
  private STEP_CODE = 'users'

  implementationSubscribe() {
    this.activatedRouteSub = this.activatedRoute.data.subscribe(data => {
      this.isImplementation = data.implementation;

      if (this.isImplementation) {
        this.implementationSub =
          this.implementation$.subscribe((i) => {
            this.implementation = i;
            this.implementationDetail = this.implementation.details.find(d => d.implementationStepCode == this.STEP_CODE);
            this.nextImplementationDetail = this.implementation.details.find(d => d.order == this.implementationDetail.order + 1);
        });
      }
    });
    this.store.dispatch(ImplementationActions.updateImplementationStep({ stepCode: this.STEP_CODE}));
  }
  
  implementationUnsubscribe() {
    if (this.isImplementation) {
      this.activatedRouteSub.unsubscribe();
      this.implementationSub.unsubscribe();
    }
  }

  next(): void {
    if (this.implementationDetail.status == "InProgress") {
      this.gonext = true;
      this.implementationService
      .apiV1ImplementationCompleteStepCodePost(this.STEP_CODE)
      .subscribe(
        () => { 
          this.gonext = false;  
          this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
        },
        () => this.gonext = false
      );
    }
    else {
      this.router.navigateByUrl('/implementation/' + this.nextImplementationDetail.implementationStepCode);
    }
  }

  ngOnDestroy(): void {
    this.implementationUnsubscribe();
  }

  trackById(index: number, item: any) {
    return item.id;
  }
}
