import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, tap } from 'rxjs/operators';

import { SessionStorageService } from '@core/services/session-storage.service';
import { loadDataSets, loadDataSetsFailure, loadDataSetsSuccess, selectDataSet } from './data-set.actions';

const SESSION_STORAGE_CLIENT_ID = 'selected-data-set-id';

@Injectable()
export class DataSetEffects {
  constructor(private actions$: Actions, private sessionStorageService: SessionStorageService) {}

  // loadDataSets = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(loadDataSets),
  //     switchMap(() => this.clientService.apiV1ClientAllPost({ pageSize: 100 })),
  //     map((response) => {
  //       const clients = response.data;
  //       let existingSelectedClientId = this.localStorageService.getItem(LOCAL_STORAGE_CLIENT_ID);

  //       if ((existingSelectedClientId === '' || existingSelectedClientId === null) && clients.length > 0) {
  //         existingSelectedClientId = clients[0].id;
  //       } else if (!clients.find((x) => x.id === existingSelectedClientId)) {
  //         existingSelectedClientId = clients[0].id;
  //       }

  //       this.store.dispatch(selectDataSet({ userClientId: existingSelectedClientId }));

  //       return loadDataSetsSuccess({ dataSets:clients });
  //     })
  //   );
  // });

  setSelectedUserClient = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(selectDataSet),
        tap(({ dataSetId }) => {
          this.sessionStorageService.setItem(SESSION_STORAGE_CLIENT_ID, dataSetId);
        })
      );
    },
    { dispatch: false }
  );
}
