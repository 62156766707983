import { Injectable } from '@angular/core';
import { BaseGridSource, PagedModel } from 'components';
import { of } from 'rxjs';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { CodeWrapperService } from 'src/app/core/services/service-wrappers/code-wrapper.service';

@Injectable()
export class DiagnosisCodesService extends BaseGridSource<any> {
  constructor(private service: CodeWrapperService) {
    super();
  }
  load(pagedModel: PagedModel) {
    this.loadingSubject.next(true);

    this.service
      .apiV1CodeCodetypePost('DiagnosisCode', pagedModel)
      .pipe(
        map((response) => {
          this.itemCountSubject.next(response.metaData.totalItemCount);

          return response;
        }),
        catchError(() => of({ data: [] })),
        finalize(() => {
          this.loadingSubject.next(false);
        }),
        take(1)
      )
      .subscribe((data) => this.dataSourceSubject.next(data.data));
  }
}
