import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RVUCodeService, RVUCodeViewModel, RVUCodeViewModelPagedModelResponse, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class RvuWrapperService extends BaseWrapperService {
  constructor(private service: RVUCodeService, private store: Store<any>) {
    super(store);
  }

  apiV1RvucodeAllPost(pagedModelRequest?: PagedModelRequest): Observable<RVUCodeViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1RvucodeAllPost(dataSetId, pagedModelRequest))
    );
  }

  apiV1RvucodeIdGet(id: string): Observable<RVUCodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1RvucodeIdGet(id, dataSetId)));
  }

  apiV1RvucodeAddPost(requestBody): Observable<RVUCodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1RvucodeAddPost(dataSetId, requestBody)));
  }

  apiV1RvucodePatchIdPatch(id: string, operation): Observable<RVUCodeViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1RvucodePatchIdPatch(id, dataSetId, operation))
    );
  }

  apiV1RvucodeIdDelete(id: string): Observable<RVUCodeViewModel> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.service.apiV1RvucodeIdDelete(id, dataSetId)));
  }
}
