/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface ClaimPaymentViewModel {
  insuranceId?: string | null;
  transactionDate?: string;
  procedureAmount?: number;
  paymentAmount?: number;
  adjustmentCode?: string | null;
  adjustmentAmount?: number;
  adjustmentCode2?: string | null;
  adjustment2Amount?: number;
  deductibleAmount?: number;
  copayAmount?: number;
  coinsuranceAmount?: number;
  issues?: Array<HoldingIssue> | null;
}
