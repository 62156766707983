import { Injectable, Injector } from '@angular/core';
import { environment } from '@environment/environment';
import { Store } from '@ngrx/store';
import { DatasetService, SettingService } from 'data';
import { catchError, filter, first, map, switchMap, take } from 'rxjs/operators';
import { loadDataSetsSuccess } from '../state/data-set/data-set.actions';
import { selectDataSet } from '../state/data-set/data-set.actions';
import { DataSet } from '../state/data-set/data-set.state';
import { PaymentWrapperService } from './service-wrappers/payment-wrapper.service';
import { ConfigurationWrapperService } from './service-wrappers/configuration-wrapper.service';
import { SessionStorageService } from './session-storage.service';
import { actionSettingsChangeTheme } from '@core/state/settings/settings.actions';
import { of } from 'rxjs';

const SESSION_STORAGE_IMAGINEPAY_ACTIVE = 'imaginepay-active';
const DOCUMENTS_ACTIVE = "documents-active"
const REMITTANCE_ACTIVE = "remittance-active"
const UPLOAD_CUSTOMIZATION = "upload-customization"
const CUSTOM_LOGO = "custom-logo"
const DEFAULT_THEME = "default-theme";
const DEFAULT_THEME_LEVEL = "default-theme-level";
const PEREGRINE_CLIENT_ID = "peregrine-client-id";

@Injectable()
export class AppInitializerService {
  private appConfig;
  private storeObject;
  private currentTheme: string;
  constructor(
    private injector: Injector,
    private store: Store,
    private dataSetService: DatasetService,
    private sessionStorageService: SessionStorageService,
    private paymentService: PaymentWrapperService,
    private configurationService: ConfigurationWrapperService
  ) {
    this.store.subscribe((result) => {
      this.storeObject = result;
      this.currentTheme = this.storeObject?.settings?.theme;
    });
  }

  initialize() {
      return this.dataSetService.apiV1DatasetAllUserPost({ pageSize: 100 }).pipe(
        map((response) => {
          const clients = response.data;
          let existingSelectedClientId = this.sessionStorageService.getItem('selected-data-set-id');
          if ((existingSelectedClientId === '' || existingSelectedClientId === null) && clients.length > 0) {
            existingSelectedClientId = clients[0].id;
          } else if (!clients.find((x) => x.id === existingSelectedClientId) && clients.length > 0) {
            existingSelectedClientId = clients[0].id;
          }
          this.store.dispatch(selectDataSet({ dataSetId: existingSelectedClientId }));

          this.store.dispatch(loadDataSetsSuccess({ dataSets: clients as DataSet[] }));
          var ipActive = false;
          this.paymentService.apiV1PaymentConfigPost()
            .pipe(first())
            .subscribe(
              result => {
                ipActive = result.imaginePayDataset != null ? true : false;
                this.sessionStorageService.setItem(SESSION_STORAGE_IMAGINEPAY_ACTIVE, ipActive);
                this.sessionStorageService.removeItem('IMAGINE_PAY_TOKEN');
                this.sessionStorageService.removeItem('IMAGINE_PAY_TOKEN_EXP');
                environment.imaginePayConfig.authUserName = result.imaginePayDataset;
                environment.imaginePayConfig.authClientId = result.clientId;
                environment.imaginePayConfig.authPassword = result.password;

                this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("documents").subscribe(f => {
                  let documents_active = f.featureLevel ?? 0 > 0;
                  this.sessionStorageService.setItem(DOCUMENTS_ACTIVE, documents_active);
                });
                this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("remittance-upload").subscribe(f => {
                  let remittance_active = f.featureLevel ?? 0 > 0;
                  this.sessionStorageService.setItem(REMITTANCE_ACTIVE, remittance_active);
                });
                this.configurationService.apiV1ConfigurationUploadGet().subscribe(u => {
                  this.sessionStorageService.setItem(UPLOAD_CUSTOMIZATION, u);
                });
                this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("custom-logo").subscribe(f => {
                  if (f.featureLevel > 0) {
                    this.sessionStorageService.setItem(CUSTOM_LOGO, f.featureValue);
                  }
                });
                this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("default-theme").subscribe(f => {
                  this.sessionStorageService.setItem(DEFAULT_THEME, f.featureValue);
                  this.sessionStorageService.setItem(DEFAULT_THEME_LEVEL, f.featureLevel);
                  if (f.featureValue !== '' &&
                    (f.featureLevel === 1 && this.currentTheme != f.featureValue || f.featureLevel === 2 && this.currentTheme === '')
                  ) {
                    this.store.dispatch(actionSettingsChangeTheme({ theme: f.featureValue }));
                  }
                }
                );
                this.configurationService.apiV1ConfigurationFeatureFeaturenameGet("peregrine-client-id").subscribe(f => {
                  if (f.featureLevel > 0) {
                    this.sessionStorageService.setItem(PEREGRINE_CLIENT_ID, f.featureValue);
                  }
                });
              });
          return response;
        })
      ).subscribe();
  }

  getConfig() {
    return this.appConfig;
  }
}
