/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface ClaimVisitViewModel {
  visitIdentifier?: string | null;
  illnessInjuryDate?: string | null;
  accidentType?: string | null;
  accidentStateCode?: string | null;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  diagnoses?: Array<string> | null;
  delayReason?: string | null;
  frequency?: string | null;
  pwkReportTypeCode?: string | null;
  pwkTransmissionCode?: string | null;
  pwkAttachmentNumber?: string | null;
  primaryInsuranceAuthNumber?: string | null;
  secondaryInsuranceAuthNumber?: string | null;
  tertiaryInsuranceAuthNumber?: string | null;
  issues?: Array<HoldingIssue> | null;
}
