/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BillingDetailHoldingViewModel } from './billingDetailHoldingViewModel';
import { ClaimInsuranceViewModel } from './claimInsuranceViewModel';
import { ClaimVisitViewModel } from './claimVisitViewModel';
import { ClaimPatientViewModel } from './claimPatientViewModel';
import { HoldingIssue } from './holdingIssue';

export interface BillingHoldingViewModel {
  id?: string;
  datasetId?: string;
  patientId?: string;
  visitId?: string;
  insuranceId?: string;
  billingFileId?: string | null;
  isRebill?: boolean;
  isCorrected?: boolean;
  isVoided?: boolean;
  isDeleted?: boolean;
  originalICN?: string | null;
  carrierSequence?: number;
  billingDetailHoldings?: Array<BillingDetailHoldingViewModel> | null;
  patientDetails?: ClaimPatientViewModel;
  selectedInsuranceDetails?: ClaimInsuranceViewModel;
  otherInsuranceDetails?: ClaimInsuranceViewModel;
  visitDetails?: ClaimVisitViewModel;
  issues?: Array<HoldingIssue> | null;
}
