import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Output } from '@angular/core';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from '@core/services/notification.service';
import { UserMaintenanceSecurityWrapperService } from '@core/services/service-wrappers/user-maintenance-security-wrapper.service';
import { CustomValidators, DialogContent, FieldDefinition, FieldSelectOption, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, flatMap, map, startWith, take } from 'rxjs/operators';
import { EventEmitter } from 'stream';
import { DatasetViewModel, PagedModelRequest, RoleViewModel } from '../../../../../../../projects/data/src/public-api';
import { DialogService } from '../../../../../core/services/dialog.service';
import { DatasetWrapperService } from '../../../../../core/services/service-wrappers/dataset-wrapper.service';

type ValidatorResponse = { [key: string]: boolean } | null;
@Component({
  selector: 'app-add-edit-user-dialog',
  templateUrl: './add-edit-user-dialog.component.html',
  styleUrls: ['./add-edit-user-dialog.component.scss']
})
export class AddEditUserDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    firstname: new UntypedFormControl(null, Validators.required),
    lastname: new UntypedFormControl(null, Validators.required),
  });
  datasetCtrl = new UntypedFormControl(null)
  roleCtrl = new UntypedFormControl(null)
  uppercase;
  lowercase;
  open = false;
  repeatCharacter;
  specialCharacter;
  number;
  validLength;
  isEdit = false;
  saving = false;
  userId = '';
  strongPassword;
  password: string;
  needSpace = false;
  allValid = true;
  visible = true;
  selectable = true;
  removable = true;
  dset = [];
  selectedDatasets = []
  selectedRoles = []
  datasetsList= [];
  rolesList = [];
  filteredDatasets: Observable<any[]>;
  filteredRoles: Observable<any[]>
  opened = false;
  datasetPlaceHolder = "Assign Dataset(s)";
  rolePlaceHolder = "Assign Role(s)";
  pagedModel: PagedModelRequest = {
    page: 1,
    pageSize: 500
  };
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('datasetInput') datasetInput: ElementRef;
  @ViewChild('datasetAuto') datasetAuto: MatAutocomplete;
  @ViewChild('datasetMatACTrigger') datasetMatACTrigger: MatAutocompleteTrigger;
  @ViewChild('roleInput') roleInput: ElementRef;
  @ViewChild('roleAuto') roleAuto: MatAutocomplete;
  @ViewChild('roleMatACTrigger') roleMatACTrigger: MatAutocompleteTrigger;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userMaintenanceSecurityService: UserMaintenanceSecurityWrapperService,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<AddEditUserDialogComponent>,
    private dialogService: DialogService,
    private datasetService: DatasetWrapperService,

  ) {

   
  }

  ngOnInit(): void {
    this.datasetService.apiV1DatasetAllDetailPost(this.pagedModel).pipe(map((x: any) => x),
      take(1)).subscribe((result) => {
        let array = [];
        result.data.forEach((dataset) => {
          let data;

          data = {
            label: dataset.name,
            value: dataset.id,
            isChecked:false
          }
          array.push(data)

        })
        this.datasetsList = array

      })
    this.userMaintenanceSecurityService?.apiV1RolePost(this.pagedModel)?.pipe(map((x: any) => x),
      take(1)).subscribe((result) => {
        let array = [];
        result.data.forEach((role) => {
          let data;
          data = {
            label: role.roleName,
            value: role.id,
            isChecked:false
          }
          array.push(data)

        })
        this.rolesList = array

      })

    this.filteredDatasets = this.datasetCtrl.valueChanges.pipe(
      startWith(null),
      map((dataset: string | FieldSelectOption) => dataset ? this._datasetFilter(dataset) : this.datasetsList.slice()))


    this.filteredRoles = this.roleCtrl.valueChanges.pipe(
      startWith(null),
      map((role: string | FieldSelectOption) => role ? this._roleFilter(role) : this.rolesList.slice()))

    this.userId = this.data?.data?.id;

    if (this.userId) {
      this.isEdit = true
      this.datasetPlaceHolder = "";
      this.rolePlaceHolder = "";
      this.formGroup.patchValue({
        firstname: this.data?.data.firstName,
        lastname: this.data?.data.lastName,
        email: this.data?.data.email
      });

      this.data?.data?.userDatasets.forEach((set) => {
        let option = {
          label: set.name,
          value: set.id,
          isChecked:false

        }
        this.selectedDatasets.push(option)

      })

    

      this.data?.data?.userRoles.forEach((role) => {
        let option = {
          label: role.name,
          value: role.id,
          isChecked: false
        }
        this.selectedRoles.push(option)
      })

      // Remove non editable fields
      this.formGroup.removeControl('nickname');
    }
  }

  onBlur(input: string) {
    if (input === "dataset") {
      if (this.selectedDatasets.length === 0) {
        this.datasetCtrl.setErrors({ leastOne: true })
        this.datasetPlaceHolder = "Assign Dataset(s)";
      }
      else {
        this.datasetCtrl.setErrors(null)
        this.datasetPlaceHolder = "";
      }
    }
    else {
      if (this.selectedRoles.length === 0) {
        this.roleCtrl.setErrors({ leastOne: true })
        this.rolePlaceHolder = "Assign Role(s)";
      }
      else {
        this.roleCtrl.setErrors(null)
        this.rolePlaceHolder = "";
      }
    }
  }

  add(control: string, event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add to array
    if ((value || '').trim()) {
      control === 'dataset'
        ? this.selectedDatasets.push(value.trim())
        : this.selectedRoles.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.datasetCtrl.setValue(null);
    this.roleCtrl.setValue(null);
  }

  remove(input: string, fruit: string): void {
    if (input === 'dataset') {
      const index = this.selectedDatasets.indexOf(fruit);

      if (index >= 0) {
        this.selectedDatasets.splice(index, 1);
      }
    }
    else {
      const index = this.selectedRoles.indexOf(fruit);

      if (index >= 0) {
        this.selectedRoles.splice(index, 1);
      }
    }
    
  }

  selected(input: string, value: { label: string, value: string, isChecked:boolean }): void {
    if (input === 'dataset') {

      const newValue = value;
      if (this.selectedDatasets.some((d) => d.value === value.value)) {

        this.selectedDatasets = [...this.selectedDatasets.filter(dataset => dataset.value !== newValue.value)];
      } else {
        this.selectedDatasets.push(
          value
        );
      }

     this.datasetInput.nativeElement.value = '';
      this.datasetCtrl.setValue(null);
      if (this.selectedDatasets.length === 0) {
        this.datasetCtrl.setErrors({
          leastOne: true
        })
      }
    }
    else {
      const newValue = value;
      if (this.selectedRoles.some((r) => r.value === value.value)) {
        this.selectedRoles = [...this.selectedRoles.filter(dataset => dataset.value !== newValue.value)];
      } else {
        this.selectedRoles.push(
          value
        );

      }

      this.roleInput.nativeElement.value = '';
      this.roleCtrl.setValue(null);
      if (this.selectedRoles.length === 0) {
        this.roleCtrl.setErrors({
          leastOne:true
        })
      }

    }



    // keep the autocomplete opened after each item is picked.
    requestAnimationFrame(() => {
      if (input === 'dataset') {
        this.openAuto(input, this.datasetMatACTrigger);
      }
      else {
        this.openAuto(input, this.roleMatACTrigger)
      }
    })

  }

  public _datasetFilter(value: any): string[] {

    let list;

    const filterValue = value.length ? value.toLowerCase() : value.label.toLowerCase();

    list = this.datasetsList.filter(dataset => dataset.label.toLowerCase().indexOf(filterValue) >= 0);
    return list
  }

  public _roleFilter(value: any): string[] {
    let list;

    const filterValue = value.length ? value.toLowerCase() : value.label.toLowerCase();
    list = this.rolesList.filter(role => role.label.toLowerCase().indexOf(filterValue) >= 0);
    return list
  }

  openAuto(input: string, trigger: MatAutocompleteTrigger) {
    if (trigger.panelOpen !== true) {
      if (input==="dataset") {
        this.datasetPlaceHolder = "";
      }
      else {
        this.rolePlaceHolder = "";
      }

      trigger.openPanel();
      this.open = true
      if (input === 'dataset') {
        this.filteredDatasets.subscribe((result) => {
          result.forEach((set) => {
            if (this.selectedDatasets.some((d) => d.value === set.value)) {
              set.isChecked = true
            }
            else {
              set.isChecked = false
            }
          })
          this.filteredDatasets = of(result)
        })
        this.datasetInput.nativeElement.focus()
      }
      else {
        this.filteredRoles.subscribe((result) => {
          result.forEach((role) => {
            if (this.selectedRoles.some((r) => r.value === role.value)) {
              role.isChecked = true
            }
            else {
              role.isChecked = false
            }
          })
          this.filteredRoles = of(result)
        })
        this.roleInput.nativeElement.focus()
      }
    }
    else {
      trigger.closePanel();
    }

  }

  save() {
    if (this.formGroup.valid && this.datasetCtrl.valid && this.roleCtrl.valid) {
      let selectedSets = [];
      this.selectedDatasets.forEach((dataset) => {
        selectedSets.push(dataset.value)
      })
      let selected = []
      this.selectedRoles.forEach((role) => {
        selected.push(role.value)
      })
      this.saving = true;
      const formData = {
        ...this.formGroup.value,
        fullname: `${this.formGroup.value.firstname} ${this.formGroup.value.lastname}`,
        picture: null,
        datasetIds: selectedSets,
        roleIds: selected
      }
      let saveObservable: Observable<any>;
      let notification;
      if (this.userId) {
        delete formData.picture;
        saveObservable = this.userMaintenanceSecurityService
          .apiV1UserUpdateUserPatch(this.userId, compare({}, formData))
          .pipe(map((x: any) => x));
        notification = 'User Updated.';
      } else {
        notification = 'User Added';
        saveObservable = this.userMaintenanceSecurityService.apiV1UserCreateUserPost(formData).pipe(map((x: any) => x))
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => {
            this.notificationService.error('Error saving user.');
            if (err.status === 400) {
              const modalContent: DialogContent = {
                header: 'Error',
                body: `Add New User could not be completed due to the following error: ${err.error.detail} Please try again.`

              };
              this.dialogService.showConfirm(modalContent)
            }
              }
        )
        .add(() => {
          this.saving = false;
        });

    }
  }
}


