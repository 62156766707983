import { Component, Inject, Injector, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { LocationsWrapperService } from 'src/app/core/services/service-wrappers/locations-wrapper.service';
import { CountryCodesLookupService, PlacesOfServiceCodesLookupService, StateCodesLookupService } from 'src/app/core/services/lookup';
import { ProviderDetailsViewModel } from '../../../../../../projects/data/src/public-api';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MailingAddressUtility } from '../../../../core/utilities/function.utils';
import { RvuGpciLookupService } from '@core/services/lookup/rvu-gpci-lookup.service';

@Component({
  selector: 'app-locations-dialog',
  templateUrl: './locations-dialog.component.html',
  styleUrls: ['./locations-dialog.component.scss']
})
export class LocationsDialogComponent implements OnInit {
  @ViewChild(MatTable) table: MatTable<ProviderDetailsViewModel>;
  @ViewChild('paginator') paginator: MatPaginator;
  displayedColumns: string[] = ['title', 'lastName', 'firstName', 'npi', 'providerNumber'];
  dataSource: any;
  formGroup = new UntypedFormGroup({});
  formData = {};
  formInitialized = false;
  saving = false;
  locationId;
  providerArray = [];
  mailingAddressUtility = new MailingAddressUtility;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-3',
            validators: [
              Validators.required,
              CustomValidators.noAsterisksValidator
            ]
          },
          {
            label: 'Name',
            name: 'name',
            type: 'text',
            class: 'form-span-4',
            validators: Validators.required
          },
          {
            label: 'Tax ID',
            name: 'taxId',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-1',
            validators: Validators.required
          },
          {
            label: 'Hold',
            name: 'holdBilling',
            type: 'checkbox',
            initial: false,
            class: 'form-span-1',
            validators: Validators.required
          },
          {
            label: 'NPI',
            name: 'npi',
            type: 'text',
            class: 'form-span-4'
          },
          {
            label: 'CLIA',
            name: 'clia',
            type: 'text',
            class: 'form-span-4'
          },
          {
            label: 'Mammo',
            name: 'mammo',
            type: 'text',
            class: 'form-span-4'
          },
          {
            label: 'Location Type',
            name: 'locationType',
            type: 'select',
            options: [
              'Office',
              'Home',
              'DayCareFacility',
              'NightCareFacility',
              'InpatientHospital',
              'OutpatientHospital',
              'EmergencyRoom',
              'SkilledNursingFacility',
              'NursingHome',
              'Ambulance',
              'SpecialTreatmentFacility',
              'RespiratoryTreatmentCenter',
              'IndependentLaboratory',
              'AmbulatorySurgicalCenter',
              'OtherLocations'
            ],
            class: 'form-span-4',
            clearButton: true
          },
          {
            label: 'Default Place of Service',
            name: 'placeOfServiceCodeIdDefault',
            type: 'select',
            apiService: this.posLookup,
            class: 'form-span-4',
            clearButton: true
          },
          {
            label: 'RVU GPCI',
            name: 'rvuGpciId',
            type: 'autocomplete',
            apiService: this.rvuGpciLookup,
            class: 'form-span-4',
            clearButton: true
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Address',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'address1',
            type: 'text',
            class: 'form-span-6',
            validators: Validators.required
          },
          {
            label: 'Address Line 2',
            name: 'address2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'city',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'State',
            name: 'stateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Zip',
            name: 'zip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: [Validators.required, CustomValidators.zipCodePatternValidator]
          },
          {
            label: 'Country',
            name: 'countryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
            initial: 'US'
          },
          {
            label: 'Phone Number',
            name: 'phone',
            type: 'phone',
            class: 'form-span-3'
          },
          {
            label: 'Fax Number',
            name: 'fax',
            type: 'phone',
            class: 'form-span-3'
          },
          {
            label: 'Website',
            name: 'website',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Mailing Address',
            type: 'label',
            class: 'form-span-12'
          },
          {
            label: 'Same as Location Address',
            name: 'sameMailing',
            type: 'checkbox',
            initial: false,
            class: 'form-span-12'
          },
          {
            label: 'Address Line 1',
            name: 'mailingAddress1',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'Address Line 2',
            name: 'mailingAddress2',
            type: 'text',
            class: 'form-span-6'
          },
          {
            label: 'City',
            name: 'mailingCity',
            type: 'text',
            class: 'form-span-3'
          },
          {
            label: 'State',
            name: 'mailingStateCode',
            type: 'select',
            apiService: this.stateCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Zip',
            name: 'mailingZip',
            type: 'zipCode',
            class: 'form-span-3',
            validators: CustomValidators.zipCodePatternValidator
          },
          {
            label: 'Country',
            name: 'mailingCountryCode',
            type: 'select',
            apiService: this.countryService,
            class: 'form-span-3',
          }
        ]
      },
    ];
  }

  constructor(

    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<LocationsDialogComponent>,
    private service: LocationsWrapperService,
    private stateCodesLookupService: StateCodesLookupService,
    private posLookup: PlacesOfServiceCodesLookupService,
    private rvuGpciLookup: RvuGpciLookupService,
    private countryService: CountryCodesLookupService
  ) {
    this.locationId = data?.locationId;
    const isEdit = this.locationId && this.locationId !== '';
    this.formDefinitions = this.getFormDefinitions(isEdit);

  }

  ngOnInit(): void {
    this.setupForm();

    if (this.locationId && this.locationId !== '') {
      this.service
        .apiV1LocationDetailsIdGet(this.locationId)
        .pipe(first())
        .subscribe((result) => {

          result.locationProviders.forEach((provider) => {
            this.providerArray.push(provider.provider)
            this.dataSource = new MatTableDataSource(this.providerArray)
            this.dataSource.paginator = this.paginator;

          })

          this.formGroup.patchValue(result);
          this.formGroup.get('active').patchValue(!result.inactive);

        });
    }
  }

  setupForm() {

    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });

    this.setFormSubscriptions();

    this.formInitialized = true;
  }

  setFormSubscriptions() {

    this.formGroup.get('sameMailing').valueChanges.subscribe((sameAs) => {
      this.mailingAddressUtility.subSameAsFunctionalityToSecondAddress(this.formGroup, sameAs);
    })

  }

  save() {
    if (this.formGroup.valid) {

      this.saving = true;

      this.formData = {
        inactive: this.formGroup.get('active').value === true ? false : true,
        holdBilling: this.formGroup.get('holdBilling').value === true,
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        address1: this.formGroup.get('address1').value,
        address2: this.formGroup.get('address2').value,
        city: this.formGroup.get('city').value,
        stateCode: this.formGroup.get('stateCode').value,
        zip: this.formGroup.get('zip').value,
        countryCode: this.formGroup.get('countryCode').value,
        taxId: this.formGroup.get('taxId').value,
        npi: this.formGroup.get('npi').value,
        clia: this.formGroup.get('clia').value,
        mammo: this.formGroup.get('mammo').value,
        locationType: this.formGroup.get('locationType').value,
        website: this.formGroup.get('website').value,
        phone: this.formGroup.get('phone').value,
        fax: this.formGroup.get('fax').value,
        mailingAddress1: this.formGroup.get('mailingAddress1').value,
        mailingAddress2: this.formGroup.get('mailingAddress2').value,
        mailingCity: this.formGroup.get('mailingCity').value,
        mailingCountryCode: this.formGroup.get('mailingCountryCode').value,
        mailingStateCode: this.formGroup.get('mailingStateCode').value,
        mailingZip: this.formGroup.get('mailingZip').value,
        sameMailing: this.formGroup.get('sameMailing').value,
        placeOfServiceCodeIdDefault: this.formGroup.get('placeOfServiceCodeIdDefault').value,
        rvuGpciId: this.formGroup.get('rvuGpciId').value
      }

    };


    let saveObservable: Observable<any>;
    let notification;

    if (this.locationId) {

      notification = 'Location Updated';
      saveObservable = this.service
        .apiV1LocationPatchIdPatch(this.locationId, compare({}, this.formData))
        .pipe(map((x: any) => x));
    } else {
      notification = 'Location Added';
      saveObservable = this.service.apiV1LocationAddPost(this.formData).pipe(map((x: any) => x));
    }

    saveObservable
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.dialogRef.close(true);
          this.notificationService.success(notification);
        },
        (err) => this.notificationService.error('Saving Location')
      )
      .add(() => {
        this.saving = false;
      });
  }
}
