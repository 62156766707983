import { Component, Inject, Injector, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as currency from 'currency.js';
import { compare } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CustomValidators, FormGroupDefinition } from '../../../../../../../projects/components/src/public-api';
import { AddNationalDrugCodeViewModel, NationalDrugCodeDetailsViewModel } from '../../../../../../../projects/data/src/public-api';
import { NotificationService } from '../../../../../core/services/notification.service';
import { NationalDrugCodeWrapperService } from '../../../../../core/services/service-wrappers/national-drug-code-wrapper.service';
import { ProceduresDialogComponent } from '../procedures-dialog.component';


@Component({
  selector: 'app-national-drug-code-dialog',
  templateUrl: './national-drug-code-dialog.component.html',
  styleUrls: ['./national-drug-code-dialog.component.scss']
})
export class NationalDrugCodeDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<NationalDrugCodeDialogComponent>,
    public procedureDialogRef: MatDialogRef<ProceduresDialogComponent>,
    private service: NationalDrugCodeWrapperService,
  ) {
    this.procedureId = data?.data?.procedureId;
    this.procedureAmount = data?.data?.ndcAmount;
    const isEdit = this.procedureId && this.procedureId !== '';
    this.formDefinitions = this.getFormDefinitions();
  }
  formGroup = new UntypedFormGroup({}, { updateOn: 'blur' });
  formInitialized = false;
  saving = false;
  ndcId: string;
  drug: NationalDrugCodeDetailsViewModel;
  procedureId;
  procedureAmount;
  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'NDC Code',
            name: 'ndcCode',
            type: 'text',
            class: 'form-span-3',
            validators: [Validators.required, CustomValidators.ndcCodeValidator]
          },
          {
            label: 'NDC Drug Name',
            name: 'ndcDrugName',
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'NDC Quantity',
            name: 'ndcQuantity',
            type: 'number',
            class: 'form-span-4',
            validators: [Validators.required, CustomValidators.twoDecimalValidator]
          },
          {
            label: 'Active',
            name: 'inactive',
            type: 'checkbox',
            initial: true,
            class: 'form-span-2',
            validators: Validators.required
          },
          {
            label: 'NDC Unit',
            name: 'ndcUnit',
            type: 'select',
            options: [
              {
                label: 'F2',
                value: 'F2'
              },
              {
                label: 'GR',
                value: 'Gr'
              },
              {
                label: 'MG',
                value: 'Mg'
              },
              {
                label: 'ML',
                value: 'Ml'
              },
              {
                label: 'UN',
                value: 'Un'
              }
            ],
            class: 'form-span-3'
          },
          {
            label: 'NDC Qualifier',
            name: 'ndcQualifier',
            type: 'select',
            options:
              [
                'N4'
              ],
            class: 'form-span-3'
          },
          {
            label: 'NDC Amount',
            name: 'ndcAmount',
            type: 'money',
            isReadOnly:true,
            class: 'form-span-6',
            validators:CustomValidators.ndcAmountValidator(Number(this.procedureAmount))
          }
        ]
      }
    ];
  }
  ngOnInit(): void {
    this.setupForm();
    this.service.apiV1NationalDrugCodeByProcedureIdIdGet(this.procedureId).subscribe((ndc) => {
      if (ndc !== null) {
        this.formGroup.get('ndcCode').setValue(ndc.ndcCode)
        this.formGroup.get('ndcDrugName').setValue(ndc.ndcDrugName)
        this.formGroup.get('ndcQualifier').setValue(ndc.ndcQualifier)
        this.formGroup.get('ndcUnit').setValue(ndc.ndcUnit)
        this.formGroup.get('ndcAmount').setValue(this?.procedureAmount?.toFixed(2))
        this.formGroup.get('ndcQuantity').setValue(ndc?.ndcQuantity?.toFixed(2))
        this.formGroup.get('inactive').setValue(ndc.inactive ? false : true)
        this.ndcId = ndc.id
        this.drug = ndc;
      }
      else {
        this.formGroup.get('ndcAmount').setValue(this.procedureAmount.toFixed(2))
      }
    })
  }

  cancel() {
    this.dialogRef.close(this.drug)
    let procedureId = this.procedureId
    let dialog = this.dialog.open(ProceduresDialogComponent, {
      disableClose: true,
      autoFocus: false,
      data: { procedureId }
    });
  }
  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  save() {
    if (this.formGroup.valid) {

      this.saving = true;

      const formData: AddNationalDrugCodeViewModel = {
        procedureId: this.procedureId,
        ndcCode: this.formGroup.get('ndcCode').value.replaceAll('-', ''),
        ndcDrugName: this.formGroup.get('ndcDrugName').value,
        ndcQuantity: parseFloat(this.formGroup.get('ndcQuantity').value),
        inactive: this.formGroup.get('inactive').value ? false : true,
        ndcUnit: this.formGroup.get('ndcUnit').value,
        ndcQualifier: this.formGroup.get('ndcQualifier').value,
        ndcAmount: parseFloat(this.formGroup.get('ndcAmount').value),
      }

      let saveObservable: Observable<any>;
      let notification;

      if (this.ndcId) {
        notification = 'National Drug Code Updated';
        saveObservable = this.service
          .apiV1NationalDrugCodePatchIdPatch(this.ndcId, compare({}, formData))
          .pipe(map((x: any) => x));
      } else {
        notification = 'National Drug Code Added';
        saveObservable = this.service.apiV1NationalDrugCodeAddPost(formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving National Drug Code')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
}

