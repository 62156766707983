/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface RebillOptionsViewModel {
  insuranceCarrierIds?: Array<string> | null;
  financialClassIds?: Array<string> | null;
  locationIds?: Array<string> | null;
  attendingProviderIds?: Array<string> | null;
  referringProviderIds?: Array<string> | null;
  placeOfServiceCodeIds?: Array<string> | null;
  procedureCodeIds?: Array<string> | null;
  visitHeldStatus?: string | null;
  coverages?: string | null;
  serviceDateRangeStart?: string | null;
  serviceDateRangeEnd?: string | null;
  postDateRangeStart?: string | null;
  postDateRangeEnd?: string | null;
  billedDateRangeStart?: string | null;
  billedDateRangeEnd?: string | null;
}
