<header class="sub-page-layout__header insuranceCarriers__header">
  <div class="insuranceCarriers__header-text h2 h2--semi-bold">Insurance Carriers</div>
  <button mat-flat-button color="primary" class="insuranceCarriers__add-item" (click)="uploadCodes()">
    <mat-icon>file_upload</mat-icon> Upload Insurance Carriers
  </button>
  <button mat-flat-button color="primary" class="insuranceCarriers__add-item" (click)="addInsuranceCarrier()">
    <mat-icon>add_circle_outline</mat-icon> Add Insurance Carrier
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No Insurance Carriers Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="insuranceCarriers__footer">
  <lib-button-loading
    color="primary"
    class="insuranceCarriers__button"
    [invalid]="itemCount === 0"
    (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
