import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-button-loading',
  template: `
    <button
      *ngIf="buttonType === 'stroked'"
      mat-stroked-button
      [class]="btnClass"
      [color]="color"
      [disabled]="parent?.invalid || invalid || loading"
      (click)="onClick()"
    >
      <ng-container *ngTemplateOutlet="insideTemplate"></ng-container>
    </button>
    <button
      *ngIf="buttonType === 'flat'"
      [class]="btnClass"
      mat-flat-button
      [color]="color"
      [disabled]="parent?.invalid || invalid || loading"
      (click)="onClick()"
    >
      <ng-container *ngTemplateOutlet="insideTemplate"></ng-container>
    </button>
    <button
      *ngIf="buttonType === ''"
      [class]="btnClass"
      mat-button
      [color]="color"
      [disabled]="parent?.invalid || invalid || loading"
      (click)="onClick()"
    >
      <ng-container *ngTemplateOutlet="insideTemplate"></ng-container>
    </button>

    <ng-template #insideTemplate>
      <mat-spinner *ngIf="loading" [diameter]="20" id=buttonSpinner></mat-spinner> <ng-content></ng-content
    ></ng-template>
  `,
  styles: [
    `
           :host {
             display: inline-block;
           }
           button {
             width: 100%;
           }
           mat-spinner {
             display: inline-block;
             margin-right: 0.25rem;
           }
         `
  ]
})
export class ButtonLoadingComponent {
  /**
   * Disabled button and initializes loader
   *
   * @type {boolean}
   * @memberof ButtonLoadingComponent
   */
  @Input()
  loading: boolean;

  /**
   * Disables Button
   *
   * @type {boolean}
   * @memberof ButtonLoadingComponent
   */
  @Input()
  invalid: boolean;

  /**
   * Material Button type
   *
   * @type {('' | 'primary' | 'accent')}
   * @memberof ButtonLoadingComponent
   */
  @Input()
  color: '' | 'primary' | 'accent' = '';

  /**
   * CSS Class passed to button element
   *
   * @type {string}
   * @memberof ButtonLoadingComponent
   */
  @Input()
  btnClass: string;

  /**
   * Material Type. Empty for normal material button
   *
   * @type {('' | 'stroked' | 'flat')}
   * @memberof ButtonLoadingComponent
   */
  @Input()
  buttonType: '' | 'stroked' | 'flat' = 'flat';

  /**
   * Parent Form Group
   *
   * @type {FormGroup}
   * @memberof ButtonLoadingComponent
   */
  @Input()
  parent: UntypedFormGroup;

  /**
   * Click Event Output
   *
   * @memberof ButtonLoadingComponent
   */
  @Output()
  clickEvent = new EventEmitter<any>();

  constructor() {}

  /**
   * Emits Click Event
   *
   * @memberof ButtonLoadingComponent
   */
  onClick() {
    this.clickEvent.emit();
  }
}
