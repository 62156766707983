<div class="dialog-form report-form">
  <header mat-dialog-title class="dialog-form__title">
    <h2>National Drug Code</h2>
  </header>
  <main mat-dialog-content>
    <div class="dialog-form__container">
      <ng-container *ngIf="formInitialized">
        <lib-dynamic-form [dynamicFormGroup]="formGroup" [formGroupDefinitions]="formDefinitions"> </lib-dynamic-form>
      </ng-container>
    </div>
  </main>
  <div mat-dialog-actions class="dialog-form__actions">
    <button class="cancel-button" mat-stroked-button *ngIf="ndcId" (click)="cancel()">Cancel</button>
    <lib-button-loading
      color="primary"
      class="save-button"
      [loading]="saving"
      [invalid]="formGroup.invalid"
      (clickEvent)="save()"
    >
      Save
    </lib-button-loading>
  </div>
</div>
