import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { BaseWrapperService } from "./base-wrapper.service";
import { AddDatasetViewModel, DatasetDetailsViewModel, DatasetDetailsViewModelPagedModelResponse, PagedModelRequest } from "data";
import { DatasetService } from "data";

@Injectable({
  providedIn: 'root'
})
export class DatasetWrapperService extends BaseWrapperService {
  constructor(private service: DatasetService, private store: Store<any>) {
    super(store);
  }

  apiV1DatasetAddPost(dataset: AddDatasetViewModel): Observable<AddDatasetViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetAddPost(dataset))
    )
  }

  apiV1DatasetDuplicatePost(datasetId: string, dataset: AddDatasetViewModel): Observable<DatasetDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetDuplicatePost(datasetId, dataset))
    )
  }

  apiV1DatasetAllDetailPost(requestBody: PagedModelRequest): Observable<DatasetDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetAllDetailPost(requestBody))
    )
  }
  apiV1DatasetAllPost(requestBody: PagedModelRequest): Observable<DatasetDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetAllPost(requestBody))
    )
  }
  apiV1DatasetDetailsIdGet(id:string): Observable<DatasetDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetDetailsIdGet(id))
    )
  }
  apiV1DatasetPatchIdPatch(id: string, operation): Observable<DatasetDetailsViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetPatchIdPatch(id, operation))
    );
  }
  apiV1DatasetAllUserPost(requestBody: PagedModelRequest): Observable<DatasetDetailsViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.service.apiV1DatasetAllUserPost(requestBody))
    )
  }
}
