/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClaimProcedureViewModel } from './claimProcedureViewModel';
import { HoldingIssue } from './holdingIssue';

export interface BillingDetailHoldingViewModel {
  id?: string;
  datasetId?: string;
  billingHoldingId?: string;
  procedureTransactionId?: string;
  procedureDetails?: ClaimProcedureViewModel;
  issues?: Array<HoldingIssue> | null;
}
