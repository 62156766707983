<header class="sub-page-layout__header eobCodes__header">
  <div class="eobCodes__header-text h2 h2--semi-bold">EOB Codes</div>
  <button mat-flat-button color="primary" class="eobCodes__add-item" (click)="uploadCodes()">
    <mat-icon>file_upload</mat-icon> Upload EOB Codes
  </button>
  <button mat-flat-button color="primary" class="eobCodes__add-item" (click)="addEobCodes()">
    <mat-icon>add_circle_outline</mat-icon> Add EOB Code
  </button>
</header>
<div *ngIf="dataSource && gridConfig" class="lib-grid__grid-container">
  <lib-grid [dataSource]="dataSource" [gridConfiguration]="gridConfig">
    <div class="">
      <h4>No EOB Codes Found.</h4>
    </div>
  </lib-grid>
</div>
<footer *ngIf="isImplementation && itemCount > 0" class="eobCodes__footer">
  <lib-button-loading color="primary" class="eobCodes__button" [invalid]="itemCount === 0" (clickEvent)="next()"
    >Next
  </lib-button-loading>
</footer>
