import { FieldErrorsDefinition } from './field-errors-definition';
import { FieldSelectOption } from './field-select-option';
import { ApiAutoCompleteService } from '../field/api-autocomplete.service';

export interface FieldDefinition {
  showIf?: (values: any) => boolean;
  name?: string;
  maxText?: string;
  minText?: string;
  label?: string;
  initial?: any;
  validators?: any;
  class?: string;
  resizeable?: boolean;
  type?:
  | 'empty'
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'phone'
  | 'date'
  | 'dateRange'
  | 'select'
  | 'radio'
  | 'checkbox'
  | 'money'
  | 'decimal'
  | 'autocomplete'
  | 'label'
  | 'zipCode'
  | 'noInput'
  | 'birthDate'
  | 'allCaps'
  | 'time'
  | 'multiSelect'
  | 'textbox';

  customSelectedDisplay?: string;
  clearButton?: boolean;
  focusId?: string;
  hint?: string;
  errors?: FieldErrorsDefinition;
  options?: (string | FieldSelectOption)[];
  optionsCatalogue?: string;
  emptyOption?: string;
  placeholder?: string;
  multiple?: boolean;
  isReadOnly?: boolean;
  matLabel?: string;
  min?: number | Date;
  max?: number | Date;
  decimalCount?: number;
  apiService?: ApiAutoCompleteService;
  minimum?: number;
  checked?: boolean;
  initialMulti?: any[];
  excludeSelectAllOption?: boolean;
  selectOptions?: (value: any) => void;
  selectionChanged?: (value: any) => void;
  datepickerChanged?: (value: any) => void;
  checkedChanged?: (value: any) => void;
  daterangeStartChanged?: (value: any) => void;
  daterangeEndChanged?: (value: any) => void;
  customErrorMessage?: string;
}
