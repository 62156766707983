/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeeScheduleEntryViewModel } from './feeScheduleEntryViewModel';

export interface FeeScheduleDetailsViewModel {
  id?: string;
  datasetId?: string;
  name?: string | null;
  effectiveFrom?: string;
  effectiveTo?: string;
  inactive?: boolean;
  readonly active?: boolean;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
  isDeleted?: boolean;
  deletedDate?: string | null;
  feeScheduleEntries?: Array<FeeScheduleEntryViewModel> | null;
}
