/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface AddEOBCodeViewModel {
  datasetId?: string;
  code?: string | null;
  name?: string | null;
  description?: string | null;
  doWriteOff?: string | null;
  adjustmentCodeId?: string | null;
  statementMessage?: string | null;
  billNext?: string | null;
  patientResponsible?: boolean;
  effectiveFrom?: string | null;
  effectiveTo?: string | null;
  createMemo?: boolean;
  memoText?: string | null;
  isDenied?: string | null;
  isUnderPaid?: boolean;
  isFollowUp?: boolean;
  inactive?: boolean;
}
