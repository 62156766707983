/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HoldingIssue } from './holdingIssue';

export interface ClaimInsuranceViewModel {
  insuranceId?: string;
  insuredLastName?: string | null;
  insuredFirstName?: string | null;
  insuredMiddleName?: string | null;
  insuredSuffix?: string | null;
  policyNumber?: string | null;
  insuredAddress1?: string | null;
  insuredAddress2?: string | null;
  insuredCity?: string | null;
  insuredStateCode?: string | null;
  insuredZip?: string | null;
  insuredBirthDate?: string;
  insuredSex?: string | null;
  insuredRelation?: string | null;
  planName?: string | null;
  groupNumber?: string | null;
  acceptAssignmentInsurance?: string | null;
  insuranceType?: string | null;
  insuranceCarrierType?: string | null;
  insuranceCarrierId?: string | null;
  carrierName?: string | null;
  carrierPayerId?: string | null;
  carrierAddress1?: string | null;
  carrierAddress2?: string | null;
  carrierCity?: string | null;
  carrierStateCode?: string | null;
  carrierZip?: string | null;
  acceptAssignmentCarrier?: string | null;
  carrierSequence?: string | null;
  carrierPaymentAmount?: number;
  carrierAdjustmentAmount?: number;
  carrierAdjustment2Amount?: number;
  carrierDeductibleAmount?: number;
  carrierCopayAmount?: number;
  carrierCoinsuranceAmount?: number;
  lastPaymentDate?: string | null;
  adjustmentCode?: string | null;
  adjustmentCode2?: string | null;
  issues?: Array<HoldingIssue> | null;
}
