import { Component, Inject, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FrequencyInput } from 'components/shared/form/frequency-input';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { filter, first, map, startWith, take, takeUntil } from 'rxjs/operators';
import { ScheduledJobWrapperService } from '../../../../core/services/service-wrappers/scheduled-job-wrapper.service';
import { FormCataloguesService } from '../../../../core/services/form-catalogues.service';
import { JobLookupService } from '../../../../core/services/lookup/job-lookup.service';
import { UserLookupService } from '../../../../core/services/lookup/user-lookup.service';
import { NotificationService } from 'src/app/core/services/notification.service';

import { compare } from 'fast-json-patch';
import { ComparisonType, PagedModelRequest, PropertySearch } from 'data';
import { DialogService } from '@core/services/dialog.service';
import { DialogContent, FieldComponent } from 'components';
import { AuthState } from '@core/state/auth-state/auth.state';
import { Store } from '@ngrx/store';
import { AppState } from '@core/core.state';

@Component({
  selector: 'app-scheduled-jobs-dialog',
  templateUrl: './scheduled-jobs-dialog.component.html',
  styleUrls: ['./scheduled-jobs-dialog.component.scss']
})
export class ScheduledJobsDialogComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('jobName') jobName: FieldComponent;
  formGroup = new UntypedFormGroup({
    jobId: new UntypedFormControl(null, Validators.required),
    status: new UntypedFormControl(null, Validators.required),
    nextExecution: new UntypedFormControl(null, Validators.required),
    executionHour: new UntypedFormControl(null),
    executionMinute: new UntypedFormControl(null),
    executionMeridiem: new UntypedFormControl(null),
    executionTimeZone: new UntypedFormControl(null),
    frequencyType: new UntypedFormControl('Once'),
    frequencyInterval: new UntypedFormControl(null),
    weekDays: new UntypedFormControl(null),
    monthDays: new UntypedFormControl(null),

    scheduledForId: new UntypedFormControl(null, Validators.required),
    description: new UntypedFormControl(null)
  });
  scheduledJobStatuses;
  frequencyInputObj: FrequencyInput;
  minDate = new Date();
  destroyed = new Subject();
  scheduledJobId = '';
  saving = false;
  user;
  user$: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ScheduledJobsDialogComponent>,
    public jobLookupService: JobLookupService,
    public formCataloguesService: FormCataloguesService,
    public userLookupService: UserLookupService,
    public scheduledJobWrapperService: ScheduledJobWrapperService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private store: Store<AppState>
  ) {
    this.scheduledJobId = data?.scheduledJobId;
    this.scheduledJobStatuses = formCataloguesService.getCatalogue('scheduledJobStatus');
    const dataTypeForm = this.formGroup.get('nextExecution');

    dataTypeForm.valueChanges
      .pipe(startWith(dataTypeForm.value), takeUntil(this.destroyed))
      .subscribe((dataType) => this.handleDataTypeChange(dataType));

    if (this.scheduledJobId && this.scheduledJobId !== '') {
      this.scheduledJobWrapperService
        .apiV1ScheduledJobDetailsIdGet(this.scheduledJobId)
        .pipe(first())
        .subscribe((result) => {
          this.frequencyInputObj = result;
          this.formGroup.patchValue(result);
        });
    }
  }

  ngOnInit(): void {
    this.user$ = this.store.subscribe(s => this.formGroup.get('scheduledForId').setValue(s.authData?.id));
  }
  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }
  ngOnDestroy() {
    this.user$.unsubscribe();
    this.destroyed.next(true);
    this.destroyed.complete();
  }
  frequencyChanged(event) {
    this.frequencyInputObj = event;
    this.formGroup.patchValue(event);
  }
  save() {
    if (this.formGroup.valid) {
      this.saving = true;
      const formData = this.formGroup.value;
      let saveObservable: Observable<any>;
      let notification;

      // existing job update
      if (this.scheduledJobId) {
        saveObservable = this.scheduledJobWrapperService
          .apiV1ScheduledJobPatchIdPatch(this.scheduledJobId, compare({}, formData))
          .pipe(map((x: any) => x));
        notification = 'Scheduled Job Updated';
        saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Error Saving Scheduled Job')
        )
        .add(() => {
          this.saving = false;
        });
      } 
      // new job - check for existing scheduled jobs of same frequency
      else 
      {
        const frequencyType = this.formGroup.controls["frequencyType"].value;
        const status = this.formGroup.controls["status"].value;
        const getExistingObservable$ = this.getExistingJobs(status, frequencyType).pipe(map((x: any) => x));
        getExistingObservable$.pipe(filter(x => !!x)).subscribe((existingJobs) => {
          const repeatedJobs = existingJobs.data?.filter(j => j.frequencyType != 'Once');
          this.addJob(formData, repeatedJobs?.length ?? 0);
        });
      }
    }
  }

  // find existing scheduled jobs of same frequency
  getExistingJobs(status, frequencyType) {
    let existingJobs = [];
    if (status !== 'Scheduled' || frequencyType == 'Once') return of(existingJobs);
    if (this.scheduledJobId == null) {
      const jobId = this.formGroup.controls["jobId"].value;
      const status = this.formGroup.controls["status"].value;
      
      const propertySearch: PropertySearch[] = 
        [
          { propertyName: 'jobId', comparisonType: ComparisonType.NUMBER_0, value: jobId },
          { propertyName: 'status', comparisonType: ComparisonType.NUMBER_0, value: 'Scheduled' }
        ];
      
      const pagedModel: PagedModelRequest = { page: 1, pageSize: 9999, propertySearches: propertySearch };
      return this.scheduledJobWrapperService.apiV1ScheduledJobAllDetailPost(pagedModel);
    }
  }

  addJob(formData, existingJobCount) {
    let notification = 'Scheduled Job Added';
    const jobName = this.jobName.autocompleteControl.value.label;
    const saveObservable = this.scheduledJobWrapperService
      .apiV1ScheduledJobAddPost(formData)
      .pipe(map((x: any) => x));

    if (existingJobCount > 0) {
      const modalContent: DialogContent = {
        header: 'Add Scheduled Job',
        body: `Repeating scheduled jobs (${existingJobCount}) already exist for ${jobName}.  Do you still wish to create a new job?`,
        cancelButtonText: 'No',
        OKButtonText: 'Yes'
      };
      this.dialogService.showConfirm(modalContent).subscribe((result) => {
        if (result) {
          saveObservable
          .pipe(take(1))
          .subscribe(
            (response) => {
              this.dialogRef.close(true);
              this.notificationService.success(notification);
            },
            (err) => { this.notificationService.error('Error Adding Scheduled Job');  }
          )
          .add(() => {
            this.saving = false;
          });
        }
        else {
          this.saving = false;
        }
    });
    }
    else {
      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => { this.notificationService.error('Error Adding Scheduled Job'); }
        )
        .add(() => {
          this.saving = false;
        });
    }
    
  }


  private handleDataTypeChange(dataType: Date) {
    this.frequencyInputObj = {
      ...this.frequencyInputObj,
      nextExecution: dataType
    };
  }
}
