/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface GetFeeScheduleEntryViewModel {
  procedureId?: string | null;
  procedureTransactionId?: string | null;
  modifierId?: string | null;
  insuranceId?: string | null;
  insuranceCarrierId?: string | null;
  feeScheduleId?: string | null;
  feeDate?: string | null;
  feeAmount?: number;
  feeScheduleName?: string | null;
  procedureCode?: string | null;
  modifierName?: string | null;
}
