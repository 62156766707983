<lib-card>
  <ng-container name="title">Visit Information</ng-container>
  <dl class="data-list">
    <dt>Primary Insurance</dt>
    <dd>
      {{ visit?.primaryInsurance?.insuranceCarrier?.carrierName || '--' }}
      {{
        visit?.primaryInsurance?.insuranceCarrier?.carrierName && visit?.primaryInsuranceAuthNumber
          ? '(Auth #: ' + visit.primaryInsuranceAuthNumber + ')'
          : ''
      }}
    </dd>
    <dt>Secondary Insurance</dt>
    <dd>
      {{ visit?.secondaryInsurance?.insuranceCarrier?.carrierName || '--' }}
      {{
        visit?.secondaryInsurance?.insuranceCarrier?.carrierName && visit?.secondaryInsuranceAuthNumber
          ? '(Auth #: ' + visit.secondaryInsuranceAuthNumber + ')'
          : ''
      }}
    </dd>
    <dt>Tertiary Insurance</dt>
    <dd>
      {{ visit?.tertiaryInsurance?.insuranceCarrier?.carrierName || '--' }}
      {{
        visit?.tertiaryInsurance?.insuranceCarrier?.carrierName && visit?.tertiaryInsuranceAuthNumber
          ? '(Auth# ' + visit.tertiaryInsuranceAuthNumber + ')'
          : ''
      }}
    </dd>
    <dt>Delinquency Code</dt>
    <dd>{{ visit?.delinquencyCode || '--' }}</dd>

    <dt>Financial Class</dt>
    <dd>{{ visit?.financialClass?.name || '--' }}</dd>
    <dt>Accident Type</dt>
    <dd>
      {{ visit?.accidentType?.name }}
    </dd>
    <dt>Accident Location</dt>
    <dd>
      {{ visit?.accidentStateCode || '--' }}
    </dd>
    <dt>Responsible Party</dt>
    <dd>{{ visit?.holdStatus === 'Held' ? 'Insurance' : 'Patient' }}</dd>

    <dt>Admit Date</dt>
    <dd>
      {{ visit?.hospitalAdmitDate || '--' | dateDisplay }}
    </dd>
    <dt>Discharge Date</dt>
    <dd>
      {{ visit?.hospitalDischargeDate || '--' | dateDisplay }}
    </dd>
  </dl>
</lib-card>
