/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LocationDetailsViewModel } from './locationDetailsViewModel';
import { CodeViewModel } from './codeViewModel';
import { ProviderViewModel } from './providerViewModel';
import { SendingFacilityDataViewModel } from './sendingFacilityDataViewModel';
import { ProcedureDiagnosisCodeHoldingViewModel } from './procedureDiagnosisCodeHoldingViewModel';
import { ProcedureResultHoldingViewModel } from './procedureResultHoldingViewModel';

export interface ChargeCentralProcedureDetailsViewModel {
  accountNumber?: number;
  id?: string;
  datasetId?: string;
  batchId?: string;
  sendingFacilityId?: string;
  lastName?: string | null;
  firstName?: string | null;
  readonly fullName?: string | null;
  serviceFromDate?: string | null;
  serviceToDate?: string | null;
  accessionNumber?: string | null;
  procedureId?: string;
  procedureCode?: string | null;
  procedureAmount?: number | null;
  claimComment?: string | null;
  insuranceAuthNumber?: string | null;
  quantity?: number;
  isChargeCentralApproved?: boolean;
  nationalDrugCodeId?: string | null;
  drugCode?: string | null;
  prescriptionNumber?: string | null;
  drugUnit?: string | null;
  drugQuantity?: number | null;
  drugAmount?: number | null;
  nocProcedureDescription?: string | null;
  holdPatientBilling?: boolean | null;
  holdInsuranceBilling?: boolean | null;
  chargeGroup?: number | null;
  modifier1?: CodeViewModel;
  modifier2?: CodeViewModel;
  modifier3?: CodeViewModel;
  modifier4?: CodeViewModel;
  attendingProvider?: ProviderViewModel;
  referringProvider?: ProviderViewModel;
  primaryProvider?: ProviderViewModel;
  orderingProvider?: ProviderViewModel;
  location?: LocationDetailsViewModel;
  placeOfServiceCode?: CodeViewModel;
  diagnoses?: Array<ProcedureDiagnosisCodeHoldingViewModel> | null;
  procedureResult?: ProcedureResultHoldingViewModel;
  sendingFacilityData?: SendingFacilityDataViewModel;
}
