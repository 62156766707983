/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ReportRequest } from './reportRequest';

export interface ReportResponse {
  reportExecutionId?: string;
  name?: string | null;
  processedBy?: string | null;
  processedDate?: string;
  columns?: Array<string> | null;
  data?: Array<any> | null;
  multiData?: Array<Array<any>> | null;
  request?: ReportRequest;
}
