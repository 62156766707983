import { PagedModel } from './models';
import { ColumnDefinition, ComparisonType, FilterType, GridConfiguration } from './models';
import { inputEndSuffix, inputStartSuffix } from './constants';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntypedFormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class GridService {
  pageSize;
  pageIndex;
  constructor() {}

  /**
   * Builds pager model to send to api
   *
   * @param {MatPaginator} paginator
   * @param {MatSort} sort
   * @param {GridConfiguration} gridConfiguration
   * @param {FormGroup} formGroup
   * @return {*}  {PagedModel}
   * @memberof GridService
   */
  buildPagedModel(
    paginator: MatPaginator,
    sort: MatSort,
    gridConfiguration: GridConfiguration,
    formGroup: UntypedFormGroup
  ): PagedModel {
    if (sessionStorage.getItem('pageSize') !== null) {
      this.pageSize = parseInt(sessionStorage.getItem('pageSize'));
    } else {
      this.pageSize = 25;
    }
    if (sessionStorage.getItem('pageIndex') !== null) {
      this.pageIndex = parseInt(sessionStorage.getItem('pageIndex'));
    } else {
      this.pageIndex = 0;
    }
    const pageSize = this.pageSize ?? 25;
    const pageIndex = this.pageIndex ?? 0;

    // Only apply a column if a direction is specified
    const sortColumn = sort?.direction ? sort.active : '';
    // API will ignored
    const sortOrder = sort?.direction !== 'asc' ? 1 : 0;

    let pagedModel: PagedModel = {
      pageSize,
      sortColumn,
      sortOrder,
      page: pageIndex + 1, // API does not use 0 based index
      propertySearches: [],
      numericRanges: [],
      dateRanges: []
    };

    gridConfiguration?.columnDefinitions.forEach((col) => {
      if (!col.filterable) {
        return;
      }

      switch (col.filterConfig?.filterType) {
        case FilterType.boolean:
          pagedModel = this.getBooleanValue(col, pagedModel, formGroup);
          break;
        case FilterType.date:
          pagedModel = this.getDateRangeFilterValue(col, pagedModel, formGroup);
          break;
        case FilterType.number:
          pagedModel = this.getNumericFilterValue(col, pagedModel, formGroup);
          break;
        case FilterType.dropdown:
          pagedModel = this.getDropdownFilterValue(col, pagedModel, formGroup);
          break;
        case FilterType.textBox:
        case FilterType.autocomplete:

        default:
          pagedModel = this.getTextFilterValue(col, pagedModel, formGroup);
          break;
      }
    });

    return pagedModel;
  }

  /**
   * Adds date picker fields to paged model
   *
   * @param {ColumnDefinition} colDef
   * @param {PagedModel} pagedModel
   * @param {UntypedFormGroup} formGroup
   * @return {*}  {PagedModel}
   * @memberof GridService
   */
  getDateRangeFilterValue(colDef: ColumnDefinition, pagedModel: PagedModel, formGroup: UntypedFormGroup): PagedModel {
    var controlStartValue = formGroup.get(`${colDef.displayKey}${inputStartSuffix}`)?.value;
    var controlEndValue = formGroup.get(`${colDef.displayKey}${inputEndSuffix}`)?.value;

    if (controlStartValue || controlEndValue) {
      if (controlStartValue == 'undefined' || controlStartValue == null || controlStartValue == '') {
        controlStartValue = new Date(1900, 0, 0);
      }

      if (controlEndValue == 'undefined' || controlEndValue == null || controlEndValue == '') {
        controlEndValue = new Date(9999, 0, 0);
      }
      // include entirety of day for end date
      controlEndValue.setHours(23, 59, 59);

      pagedModel.dateRanges.push({
        propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
        rangeEnd: new Date(controlEndValue).toLocaleString('en-US'),
        rangeStart: new Date(controlStartValue).toLocaleString('en-US')
      });
    }

    return pagedModel;
  }

  /**
   * Adds numeric ranges to filter
   *
   * @param {ColumnDefinition} colDef
   * @param {PagedModel} pagedModel
   * @param {UntypedFormGroup} formGroup
   * @return {*}  {PagedModel}
   * @memberof GridService
   */
  getNumericFilterValue(colDef: ColumnDefinition, pagedModel: PagedModel, formGroup: UntypedFormGroup): PagedModel {
    var controlStartValue = formGroup.get(`${colDef.displayKey}${inputStartSuffix}`)?.value;
    var controlEndValue = formGroup.get(`${colDef.displayKey}${inputEndSuffix}`)?.value;

    // cleanup nulls to prevent error
    if (!controlStartValue && controlStartValue !== 0) {
      controlStartValue = -999999999;
    }
    if (!controlEndValue && controlEndValue !== 0) {
      controlEndValue = 999999999;
    }

    if (controlStartValue !== '' || controlEndValue !== '') {
      pagedModel.numericRanges.push({
        propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
        rangeEnd: controlEndValue !== '' ? controlEndValue : 999999999,
        rangeStart: controlStartValue !== '' ? controlStartValue : -999999999
      });
    }

    return pagedModel;
  }

  /**
   * Adds Text Filter to paged model
   *
   * @param {ColumnDefinition} colDef
   * @param {PagedModel} pagedModel
   * @param {UntypedFormGroup} formGroup
   * @return {*}  {PagedModel}
   * @memberof GridService
   */
  getTextFilterValue(colDef: ColumnDefinition, pagedModel: PagedModel, formGroup: UntypedFormGroup): PagedModel {
    let controlValue = null;
    if (colDef.dynamic) controlValue = formGroup.get(colDef.id)?.value;
    else controlValue = formGroup.get(colDef.displayKey)?.value;

    if (controlValue) {
      pagedModel.propertySearches.push({
        propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
        comparisonType: colDef.filterConfig?.comparisonType ?? ComparisonType.Contains,
        value: controlValue
      });
    }

    return pagedModel;
  }

  getBooleanValue(colDef: ColumnDefinition, pagedModel: PagedModel, formGroup: UntypedFormGroup): PagedModel {
    const controlValue = formGroup.get(colDef.displayKey)?.value.toLowerCase();
    const filterKey = colDef?.filterConfig?.filterKey;

    if (filterKey === 'inactive') {
      if (controlValue === 'active') {
        pagedModel.propertySearches.pop();
        pagedModel.propertySearches.push({
          propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
          comparisonType: colDef.filterConfig?.comparisonType ?? ComparisonType.Contains,
          value: 'false'
        });
      }
      if (controlValue === 'inactive') {
        pagedModel.propertySearches.pop();
        pagedModel.propertySearches.push({
          propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
          comparisonType: colDef.filterConfig?.comparisonType ?? ComparisonType.Contains,
          value: 'true'
        });
      }
    } else if (['yes', 'ye', 'y', 'on'].includes(controlValue)) {
      pagedModel.propertySearches.pop();
      pagedModel.propertySearches.push({
        propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
        comparisonType: colDef.filterConfig?.comparisonType ?? ComparisonType.Contains,
        value: 'true'
      });
    } else if (['no', 'n', 'of', 'off'].includes(controlValue)) {
      pagedModel.propertySearches.pop();
      pagedModel.propertySearches.push({
        propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
        comparisonType: colDef.filterConfig?.comparisonType ?? ComparisonType.Contains,
        value: 'false'
      });
    }
    return pagedModel;
  }

  /**
   * Adds Dropdown Filter to paged model
   *
   * @param {ColumnDefinition} colDef
   * @param {PagedModel} pagedModel
   * @param {UntypedFormGroup} formGroup
   * @return {*}  {PagedModel}
   * @memberof GridService
   */
  getDropdownFilterValue(colDef: ColumnDefinition, pagedModel: PagedModel, formGroup: UntypedFormGroup): PagedModel {
    const controlValue = formGroup.get(colDef.displayKey)?.value;

    if (controlValue) {
      pagedModel.propertySearches.push({
        propertyName: colDef.filterConfig?.filterKey ?? colDef.displayKey,
        comparisonType: colDef.filterConfig?.comparisonType ?? ComparisonType.Equals,
        value: controlValue
      });
    }

    return pagedModel;
  }
}
