import { Injectable } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { InsuranceCarrierWrapperService } from '../service-wrappers/insurance-carrier-wrapper.service';
import { PagedModelRequest, PropertySearch } from 'data';

@Injectable({
  providedIn: 'root'
})
export class InsuranceCarriersLookupService extends ApiAutoCompleteService {
  
  propSearch: PropertySearch = { propertyName: '', comparisonType: 0, value: '' };
  pagedModelRequest: PagedModelRequest = {
    generalSearch: '',
    propertySearches: [],
    pageSize: 99999
  }
  insuranceCarrier$ = this.service.apiV1InsuranceCarrierAllPost(this.pagedModelRequest).pipe(shareReplay(1));
  
  constructor(private service: InsuranceCarrierWrapperService) {
    super();
  }

  bIncludeInactive: boolean = true;
  carrierType = '';
  setCarrierTypeFilter(carrierType: string) {
    this.pagedModelRequest.propertySearches = [];
    this.carrierType = carrierType;
    if (carrierType != '') {
      this.propSearch.propertyName = 'CarrierType';
      this.propSearch.comparisonType = 0;
      this.propSearch.value = carrierType;
      this.pagedModelRequest.propertySearches.push(this.propSearch);
    }
    this.insuranceCarrier$ = this.service.apiV1InsuranceCarrierAllPost(this.pagedModelRequest).pipe(shareReplay(1));
  }

  mapRow(result) {
    return {
      value: result.id,
      label: result.carrierCode + ' - ' +
        result.carrierName +
        ' - ' + result.address1 + (result.address2 ? ', ' + result.address2 : '') +
        ', ' + result.city + ', ' + result.stateCode + ' ' + result.zip,
      policy: result.policyNumberRequired,
      group: result.groupNumberRequired,
      inactive: result.inactive,
      shortLabel: result.carrierName,
      defaultInsuranceTypeId: result.defaultInsuranceTypeId,
      carrierType: result.carrierType
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.insuranceCarrier$
      .pipe(
        map((results) => results.data.map(this.mapRow)
          .filter((x: any) =>(!x.inactive || this.bIncludeInactive))
          .sort((a,b) => a.shortLabel.toLowerCase() < b.shortLabel.toLowerCase() ? -1 : 1 )
        ),
        tap(() => { if (this.carrierType != '') this.setCarrierTypeFilter(''); })
      );
    }
    return this.service.apiV1InsuranceCarrierAllPost({ generalSearch: value, pageSize: 999 })
      .pipe(
        map((results) => 
          results.data.map(this.mapRow)
            .filter((x: any) => (!x.inactive || this.bIncludeInactive) )
            .sort((a, b) => (a.shortLabel.toLowerCase() < b.shortLabel.toLowerCase() ? -1 : 1))
        ),
        tap(() => { if (this.carrierType != '') this.setCarrierTypeFilter(''); })
      );
  }

  load(id: string): Observable<FieldSelectOption> {
    return this.service.apiV1InsuranceCarrierIdGet(id).pipe(map(this.mapRow));
  }

  loadObject(id: string): Observable<any> {
    return this.service.apiV1InsuranceCarrierIdGet(id).pipe(map(this.mapRow));
  }
}
