import { Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators, FormGroupDefinition } from 'components';
import { compare } from 'fast-json-patch';
import { Observable, of } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/services/notification.service';
import { FinancialClassWrapperService } from 'src/app/core/services/service-wrappers/financial-class-wrapper.service';
import { AdjustmentCodesLookupService } from 'src/app/core/services/lookup/adjustment-codes-lookup.service';
import { PaymentCodesLookupService } from 'src/app/core/services/lookup/payment-codes-lookup.service';
import { StatementMessageLookupService } from '../../../../core/services/lookup/statement-message-lookup.service';

@Component({
  selector: 'app-financial-class-dialog',
  templateUrl: './financial-class-dialog.component.html',
  styleUrls: ['./financial-class-dialog.component.scss']
})
export class FinancialClassDialogComponent implements OnInit {
  formGroup = new UntypedFormGroup({});
  formInitialized = false;
  saving = false;
  financialClassId;

  formDefinitions: FormGroupDefinition[];
  getFormDefinitions(isEdit: boolean): FormGroupDefinition[] {
    return [
      {
        hideTitle: true,
        controls: [
          {
            label: 'Financial Class Code',
            name: 'code',
            isReadOnly: isEdit,
            type: 'text',
            class: 'form-span-3',
            validators: Validators.required
          },
          {
            label: 'Financial Class Name',
            name: 'name',
            type: 'text',
            class: 'form-span-7',
            validators: Validators.required
          },
          {
            label: 'Active',
            name: 'active',
            type: 'checkbox',
            initial: true,
            class: 'form-span-2',
            validators: Validators.required
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Send Statement',
            type: 'label',
            class: 'form-span-12'
          },
          {
            name: 'sendStatement',
            type: 'radio',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            initial: true,
            class: 'form-span-3'
          },
          {
            label: 'Statement Cycle',
            name: 'statementCycle',
            type: 'text',
            class: 'form-span-3',
            validators: [CustomValidators.numberValidator, Validators.required]
          },
          {
            label: 'Statement Minimum',
            name: 'statementMinimum',
            type: 'text',
            class: 'form-span-3',
            validators: [CustomValidators.moneyFormatValidator]
          }
        ]
      },
      {
        hideTitle: true,
        controls: [
          {
            label: 'Workers Comp',
            name: 'workersComp',
            type: 'select',
            options: [
              {
                label: 'Yes',
                value: true
              },
              {
                label: 'No',
                value: false
              }
            ],
            class: 'form-span-6'
          },
          {
            label: 'No Response Visit Queue',
            name: 'sendToDelinquent',
            type: 'text',
            class: 'form-span-6',
            validators: [CustomValidators.numberValidator]
          },
          {
            label: 'Default Adjustment Code',
            name: 'defaultAdjustmentCode',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Default Payment Code',
            name: 'defaultPaymentCode',
            type: 'select',
            apiService: this.paymentCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Default Interest Code',
            name: 'defaultInterestCode',
            type: 'select',
            apiService: this.adjustmentCodesLookupService,
            class: 'form-span-3'
          },
          {
            label: 'Hold Billing',
            name: 'holdBilling',
            type: 'checkbox',
            initial: true,
            class: 'form-span-12'
          }
        ]
      }
      //{
      //  hideTitle: true,
      //  controls: [
      //    {
      //      label: 'Dunning Messages',
      //      type: 'label',
      //      class: 'form-span-12'
      //    },
      //    {
      //      label: 'Statement 1',
      //      type: 'label',
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Type',
      //      name: 'statementType1',
      //      type: 'select',
      //      options: ['Statement Type 1', 'Statement Type 2', 'Statement Type 3'],
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Message',
      //      name: 'statementMessage1',
      //      type: 'select',
      //      apiService: this.statementMessageLookup,
      //      class: 'form-span-6'
      //    },
      //    {
      //      label: 'Statement 2',
      //      type: 'label',
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Type',
      //      name: 'statementType2',
      //      type: 'select',
      //      options: ['Statement Type 1', 'Statement Type 2', 'Statement Type 3'],
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Message',
      //      name: 'statementMessage2',
      //      type: 'select',
      //      apiService: this.statementMessageLookup,
      //      class: 'form-span-6'
      //    },
      //    {
      //      label: 'Statement 3',
      //      type: 'label',
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Type',
      //      name: 'statementType3',
      //      type: 'select',
      //      options: ['Statement Type 1', 'Statement Type 2', 'Statement Type 3'],
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Message',
      //      name: 'statementMessage3',
      //      type: 'select',
      //      apiService: this.statementMessageLookup,
      //      class: 'form-span-6'
      //    },
      //    {
      //      label: 'Statement 4',
      //      type: 'label',
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Type',
      //      name: 'statementType4',
      //      type: 'select',
      //      options: ['Statement Type 1', 'Statement Type 2', 'Statement Type 3'],
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Message',
      //      name: 'statementMessage4',
      //      type: 'select',
      //      apiService: this.statementMessageLookup,
      //      class: 'form-span-6'
      //    },
      //    {
      //      label: 'Statement 5',
      //      type: 'label',
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Type',
      //      name: 'statementType5',
      //      type: 'select',
      //      options: ['Statement Type 1', 'Statement Type 2', 'Statement Type 3'],
      //      class: 'form-span-3'
      //    },
      //    {
      //      label: 'Statement Message',
      //      name: 'statementMessage5',
      //      type: 'select',
      //      apiService: this.statementMessageLookup,
      //      class: 'form-span-6'
      //    }
      //  ]
      //}
    ];
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private injector: Injector,
    private notificationService: NotificationService,
    public dialogRef: MatDialogRef<FinancialClassDialogComponent>,
    private service: FinancialClassWrapperService,
    private adjustmentCodesLookupService: AdjustmentCodesLookupService,
    private paymentCodesLookupService: PaymentCodesLookupService,
    private statementMessageLookup: StatementMessageLookupService
  ) {
    this.financialClassId = data?.financialClassId;
    const isEdit = this.financialClassId && this.financialClassId !== '';
    this.formDefinitions = this.getFormDefinitions(isEdit);
  }

  ngOnInit(): void {
    this.setupForm();

    if (this.financialClassId && this.financialClassId !== '') {
      this.service
        .apiV1FinancialClassIdGet(this.financialClassId)
        .pipe(first())
        .subscribe((result) => {
          this.formGroup.patchValue(result);
          this.formGroup.get('sendToDelinquent').patchValue(result.dlqDays);
          this.formGroup.get('defaultPaymentCode').patchValue(result.paymentCodeId);
          this.formGroup.get('defaultInterestCode').patchValue(result.interestCodeId);
          this.formGroup.get('defaultAdjustmentCode').patchValue(result.adjustmentCodeId);
          this.formGroup.get('active').patchValue(!result.inactive);
          this.formGroup.get('sendStatement').patchValue(result.sendStatement);
        });
    }
  }

  setupForm() {
    this.formDefinitions.forEach((sc) => {
      sc.controls.forEach((control) => {
        if (control.type !== 'empty' && control.type !== 'label') {
          this.formGroup.addControl(control.name, new UntypedFormControl(control.initial ?? '', control.validators));
        }
      });
    });
    this.formInitialized = true;
  }

  save() {
    if (this.formGroup.valid) {
      this.saving = true;

      const formData = {
        code: this.formGroup.get('code').value,
        name: this.formGroup.get('name').value,
        inactive: this.formGroup.get('active').value === true ? false : true,
        holdBilling: this.formGroup.get('holdBilling').value,
        workersComp: this.formGroup.get('workersComp').value ? this.formGroup.get('workersComp').value : "false",
        sendStatement: this.formGroup.get('sendStatement').value,
        statementMinimum: this.formGroup.get('statementMinimum').value ? this.formGroup.get('statementMinimum').value : "0",
        statementCycle: this.formGroup.get('statementCycle').value,
        paymentCodeId: this.formGroup.get('defaultPaymentCode').value,
        interestCodeId: this.formGroup.get('defaultInterestCode').value, 
        adjustmentCodeId: this.formGroup.get('defaultAdjustmentCode').value,
        dlqDays: this.formGroup.get('sendToDelinquent').value ? this.formGroup.get('sendToDelinquent').value : "0"
      };

      let saveObservable: Observable<any>;
      let notification;
      if (this.financialClassId) {
        notification = 'Financial Class Updated';
        saveObservable = this.service
          .apiV1FinancialClassPatchIdPatch(this.financialClassId, compare({}, formData))
          .pipe(
            map((x: any) => {
              x;
            })
          );
      } else {
        notification = 'Financial Class Added';
        saveObservable = this.service.apiV1FinancialClassAddPost(formData).pipe(map((x: any) => x));
      }

      saveObservable
        .pipe(take(1))
        .subscribe(
          (response) => {
            this.dialogRef.close(true);
            this.notificationService.success(notification);
          },
          (err) => this.notificationService.error('Saving Financial Class')
        )
        .add(() => {
          this.saving = false;
        });
    }
  }
}
