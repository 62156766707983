import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { ApiAutoCompleteService, FieldSelectOption } from 'components/shared';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PatientDetailsViewModel, VisitDetailsViewModel, VisitViewModel, VisitViewModelPagedModelResponse } from '../../../../../projects/data/src/public-api';
import { PatientWrapperService } from '../service-wrappers/patient-wrapper.service';
import { SearchWrapperService } from '../service-wrappers/search-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class PatientLookupService extends ApiAutoCompleteService {
  constructor(private searchService: SearchWrapperService, private patientService: PatientWrapperService) {
    super();
  }

  patientsEmpty$ = this.searchService
    .apiV1SearchPost({
      term: '',
      fields: ['first_last_name'],
      pageNum: 1,
      pageSize: 50,
      searchPatients: true,
      searchVisits: false,
      searchBatches: false,
      searchCodes: false,
      searchOther: false,
      startsWith: false
    })
    .pipe(shareReplay(1));

  mapRow(result) {
    return {
      value: result.patientId,
      label: `${result.accountNumber || ''} - ${result.patientFirstName || ''} ${result.patientMiddleName || ''} ${result.patientLastName || ''}`
    };
  }

  mapVisitRow(visit: VisitDetailsViewModel) {
    var visitDate = null
    if (visit.latestProcedureTransaction !== undefined) {
      visitDate = formatDate(visit.latestProcedureTransaction, 'MM/dd/yyyy', 'en-US')
    }
    return {
      value: visit.id,
      label: `Visit ${visit.referenceNumber} - ${visitDate} - ${visit.balance.toFixed(2)}`
    };
  }

  mapRowPatient(result) {
    return {
      value: result.patientId,
      label: `${result.accountNumber || ''} - ${result.firstName || ''} ${result.middleName || ''} ${result.lastName || ''}`
    };
  }

  search(value: string): Observable<FieldSelectOption[]> {
    if (value === '') {
      return this.patientsEmpty$.pipe(map((results) => {return results.patients.map(this.mapRow) } ))
    ;
    }

    return this.searchService
      .apiV1SearchPost({
        term: value,
        fields: ['first_last_name', 'altid'],
        pageNum: 1,
        pageSize: 30,
        searchPatients: true,
        searchVisits: false,
        searchBatches: false,
        searchCodes: false,
        searchOther: false,
        startsWith: false
      })
      .pipe(map((results) => results.patients.map(this.mapRow)));
  }

  patientVisits(patient: string): Observable<any> {
    return this.patientService.apiV1PatientIdVisitsPost(patient, { page: 1, pageSize: 999999 }).pipe(map((result) => {
      return result.data.filter((v: VisitDetailsViewModel) => { return v.balance !== 0 || v.procedureTransactions.length !== 0 }).map(this.mapVisitRow)
    }))
  }

  filteredSearch(value: string, filterProperty: string, filterObject: any): Observable<FieldSelectOption[]> {
    if (value) {
      return this.searchService.apiV1SearchPost({
        term: value,
        fields: ['first_last_name', 'altid'],
        pageNum: 1,
        pageSize: 9999,
        searchPatients: true,
        searchVisits: false,
        searchBatches: false,
        searchCodes: false,
        searchOther: false,
        startsWith: false
      })
        .pipe(map((results) => results.patients.filter((p) => { return p[filterProperty] !== filterObject[`${filterProperty}Display`] }).map(this.mapRow)
    ))}
    else {
      return this.patientsEmpty$.pipe(map((results) => results.patients.filter((p) => { return p[filterProperty] !== filterObject[`${filterProperty}Display`] }).map(this.mapRow))
    )}
  }

  load(id: string): Observable<FieldSelectOption> {
    if (id !== null && id !== undefined) {
      return this.patientService.apiV1PatientIdGet(id).pipe(map(this.mapRowPatient));
    }
   
  }
}
