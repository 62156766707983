/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProcedureTransactionDetailsViewModel } from './procedureTransactionDetailsViewModel';
import { LocationDetailsViewModel } from './locationDetailsViewModel';
import { EobHistoryViewModel } from './eobHistoryViewModel';
import { FinancialClassViewModel } from './financialClassViewModel';
import { CodeViewModel } from './codeViewModel';
import { InsuranceDetailsViewModel } from './insuranceDetailsViewModel';
import { PendingBatchTransactionViewModel } from './pendingBatchTransactionViewModel';
import { ProviderViewModel } from './providerViewModel';
import { ARTransactionDetailsViewModel } from './aRTransactionDetailsViewModel';

export interface VisitDetailsViewModel {
  id?: string;
  datasetId?: string;
  patientId?: string;
  financialClassId?: string;
  isMerged?: boolean;
  referenceNumber?: number | null;
  adjusted?: number;
  adjusted2?: number;
  balance?: number;
  patientBalance?: number;
  insuranceBalance?: number;
  paid?: number;
  holdStatus?: string | null;
  delinquencyCode?: number;
  inCollectionModule?: boolean;
  isScheduledWriteoff?: boolean;
  accidentTypeId?: string | null;
  accidentType?: CodeViewModel;
  daysAged?: number;
  hospitalAdmitDate?: string | null;
  hospitalDischargeDate?: string | null;
  illnessInjuryDate?: string | null;
  accidentStateCode?: string | null;
  latestProcedureTransaction?: string | null;
  procedureAmount?: number;
  procedureCount?: number;
  paymentCount?: number;
  adjustmentCount?: number;
  financialClass?: FinancialClassViewModel;
  primaryInsuranceId?: string | null;
  secondaryInsuranceId?: string | null;
  tertiaryInsuranceId?: string | null;
  procedureFromDate?: string | null;
  procedureToDate?: string | null;
  lastBilledDate?: string | null;
  lastBilledInsurance?: string | null;
  pwkReportTypeCode?: string | null;
  pwkTransmissionCode?: string | null;
  pwkAttachmentNumber?: string | null;
  pendingCommit?: boolean;
  hasActivePaymentPlan?: boolean;
  claimStatus?: string | null;
  primaryInsuranceAuthNumber?: string | null;
  secondaryInsuranceAuthNumber?: string | null;
  tertiaryInsuranceAuthNumber?: string | null;
  primaryProvider?: ProviderViewModel;
  attendingProvider?: ProviderViewModel;
  referringProvider?: ProviderViewModel;
  placeOfServiceCode?: CodeViewModel;
  location?: LocationDetailsViewModel;
  primaryInsurance?: InsuranceDetailsViewModel;
  secondaryInsurance?: InsuranceDetailsViewModel;
  tertiaryInsurance?: InsuranceDetailsViewModel;
  procedureTransactions?: Array<ProcedureTransactionDetailsViewModel> | null;
  arTransactions?: Array<ARTransactionDetailsViewModel> | null;
  eobCodes?: Array<EobHistoryViewModel> | null;
  pendingBatchTransactions?: Array<PendingBatchTransactionViewModel> | null;
}
