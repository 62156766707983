import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UserService, Operation, PagedModelRequest } from 'data';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BaseWrapperService } from './base-wrapper.service';

@Injectable({
  providedIn: 'root'
})
export class UserWrapperService extends BaseWrapperService {
  constructor(private service: UserService, private store: Store<any>) {
    super(store);
  }

  apiV1UserGet() {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1UserGet(dataSetId);
      })
    );
  }

  apiV1UserPost(pagedModelRequest: PagedModelRequest) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1UserPost(dataSetId, pagedModelRequest);
      })
    );
  }

  apiV1UserIdGet(userId: string) {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => {
        return this.service.apiV1UserIdGet(userId, dataSetId);
      })
    );
  }

  apiV1UserAuthIdGet(authId: string) {
    return this.service.apiV1UserAuthAuthidGet(authId, '');
  }
}
