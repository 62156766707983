/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface ScheduledReportExecutionViewModel {
  reportId?: string;
  reportName?: string | null;
  reportSlug?: string | null;
  scheduledDateTime?: string;
  reportFrequency?: number;
  scheduledJobId?: string;
  executedJobId?: string | null;
  executedDateTime?: string | null;
  parameterJson?: string | null;
  executedParameterJson?: string | null;
  textParameterJson?: string | null;
  executedTextParameterJson?: string | null;
  resultSetJson?: string | null;
  scheduledForId?: string;
  userName?: string | null;
  description?: string | null;
  enabled?: boolean;
  read?: string | null;
  readonly status?: string | null;
}
