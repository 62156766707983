import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HL7ConfigurationViewModel, HL7ListenerService, HL7UploadViewModel, HL7UploadViewModelPagedModelResponse, UploadService } from '../../../../../projects/data/src/public-api';
import { BaseWrapperService } from './base-wrapper.service';
import { PagedModelRequest } from 'data';

@Injectable({
  providedIn: 'root'
})
export class ImportResultWrapperService extends BaseWrapperService {
  constructor(private uploadService: UploadService, private hl7ListenerService: HL7ListenerService, private store: Store<any>) {
    super(store);
  }
  apiV1Hl7ListenerStatusPost(): Observable<Array<HL7ConfigurationViewModel>> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.hl7ListenerService.apiV1Hl7ListenerStatusPost(dataSetId, 'body', true)));
  }
  apiV1UploadHistoryPost(pagedModelRequest?: PagedModelRequest): Observable<HL7UploadViewModelPagedModelResponse> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.uploadService.apiV1UploadHistoryPost(dataSetId, pagedModelRequest)));
  }
  apiV1UploadDownloadIdPost(id: string): Observable<Blob> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.uploadService.apiV1UploadDownloadIdPost(id, dataSetId)));
  }
  apiV1Hl7ListenerPatchIdPatch(id: string, operation): Observable<HL7ConfigurationViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.hl7ListenerService.apiV1Hl7ListenerPatchIdPatch(id, dataSetId, operation))
    );
  }
  apiV1Hl7ListenerAddPost(requestBody): Observable<HL7ConfigurationViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.hl7ListenerService.apiV1Hl7ListenerAddPost(dataSetId, requestBody))
    );
  }
  apiV1Hl7ListenerStatusIdPost(id: string): Observable<HL7ConfigurationViewModel> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.hl7ListenerService.apiV1Hl7ListenerStatusIdPost(id, dataSetId))
    );
  }
  apiV1Hl7ListenerTurnOffIdPost(id: string): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.hl7ListenerService.apiV1Hl7ListenerTurnOffIdPost(id, dataSetId))
    );
  }
  apiV1Hl7ListenerTurnOnIdPost(id: string): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((dataSetId) => this.hl7ListenerService.apiV1Hl7ListenerTurnOnIdPost(id, dataSetId))
    );
  }
  apiV1UploadPost(file?: Blob): Observable<any> {
    return this.dataSetId$.pipe(
      switchMap((datasetId) => this.uploadService.apiV1UploadPost(datasetId, file, 'events', true))
    );
  }
  apiV1UploadCheckPost(filename?: string): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.uploadService.apiV1UploadCheckPost(dataSetId, filename)));
  }
  apiV1UploadSummaryIdPost(id?: string): Observable<any> {
    return this.dataSetId$.pipe(switchMap((dataSetId) => this.uploadService.apiV1UploadSummaryIdPost(id, dataSetId)));
  }
}
