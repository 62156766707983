/**
 * Vantage Core API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodeViewModel } from './codeViewModel';

export interface ProcedureViewModel {
  id?: string;
  datasetId?: string;
  code?: string | null;
  description?: string | null;
  procedureComponentType?: string | null;
  defaultModifier1Id?: string | null;
  modifier?: CodeViewModel;
  defaultModifier2Id?: string | null;
  fee?: number;
  typeOfService?: string | null;
  reportingTypeId?: string | null;
  effectiveFrom?: string;
  effectiveTo?: string;
  rvuType?: string | null;
  descriptionRequired?: boolean;
  insuranceCode1?: string | null;
  insuranceCode2?: string | null;
  insuranceCode3?: string | null;
  insuranceCode4?: string | null;
  insuranceCode5?: string | null;
  inactive?: boolean;
  sendClia?: boolean;
  sendMammo?: boolean;
  isDeleted?: boolean;
  deletedBy?: string | null;
  deletedDate?: string | null;
  createdBy?: string | null;
  createdDate?: string;
  updatedBy?: string | null;
  updatedDate?: string | null;
}
