import { AuthenticationService } from "data";
import { BaseWrapperService } from "./base-wrapper.service";
import { Store } from "@ngrx/store";
import { switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
  })
  export class AuthenticationWrapperService extends BaseWrapperService {
    constructor(private service: AuthenticationService, private store: Store<any>) {
      super(store);
    }
  
    apiV1AuthenticationAuthorizedClientsIdGet(id: string) {
      return this.service.apiV1AuthenticationAuthorizedClientsIdGet(id);
    }
  }

  
  